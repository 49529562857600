<template>
  <div>

    <header>
      <van-nav-bar left-arrow @click-left="JumpGo" class="bg-b-2 text-t-1">
        <template #left>
          <van-icon name="arrow-left" size="20" />
        </template>
        <template #title>
          <span>{{ $t("card.title") }}</span>
        </template>
      </van-nav-bar>
    </header>

    <div class="flex flex-col p-2">
      <div class="text-xl my-1">{{ $t("card.zhanghuxinxi") }}</div>
      <div class="text-sm text-t-2 my-1">
        {{ $t("card.tps") }}
      </div>

      <div class="flex flex-col divide-y divide-dashed space-y-2 shadow rounded-md py-5 px-2">
        <div class="flex flex-col  space-y-1 py-2">
          <div class="text-sm text-t-2">
            {{ $t("card.yhmc") }}
          </div>
          <input right-icon="arrow-down" :placeholder="$t('card.qyh')" type="text" readonly @click="show = true"
            v-model="crudName" class="text-sm" />
        </div>
        <div class="flex flex-col space-y-1 py-2">
          <div class="text-sm text-t-2">
            {{ $t("card.zhanghuming") }}
          </div>
          <input :placeholder="$t('card.qsryhm')" type="text" v-model="name" class="text-sm" />
        </div>
        <div class="flex flex-col  space-y-1 py-2">
          <div class="text-sm text-t-2">
            {{ $t("card.zhanghu") }}
          </div>
          <input :placeholder="$t('card.qzh')" type="text" v-model="account" class="text-sm" />
        </div>
        <div class="flex flex-col  space-y-1 py-2">
          <div class="text-sm text-t-2">
            {{ $t("card.shouji") }}
          </div>
          <input :placeholder="$t('card.qdh')" type="text" v-model="mobile" class="text-sm" />
        </div>
        <template v-for="(item, index) in cardList" :key="index">
          <div class="flex">
            <div class="">
              <div class="">
                {{ item.font }}
                <span style="color: red">{{
                  item.required == 1 ? " * " : ""
                }}</span>
              </div>
              <div class="">
                <input :placeholder="$t('card.plgjj') + item.font" type="text" v-if="item.type != 'select'"
                  v-model="customList[item.property]" />
                <input v-else right-icon="arrow-down" :placeholder="$t('card.qyh') + item.font" type="text" readonly
                  @click="showpixtype = true" v-model="typeName" />
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="bg-p-1 w-full text-center p-2 rounded-md text-p-3 my-5" @click="save">{{ $t("card.baocun") }}</div>
      <div class="mt-5 text-sm space-y-2">
        <div class="title">{{ $t("card.jianyi") }}：</div>
        <div class="tip">
          {{ $t("card.text") }}
        </div>
      </div>
    </div>
    <tabbar />
    <!-- 选择器 -->
    <van-popup v-model:show="show" @click-overlay="onConfirms" position="bottom">
      <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="show = false" @change="changeList"
        :confirm-button-text="$t('alert.queding')" :cancel-button-text="$t('alert.quxiao')" />
    </van-popup>
    <!-- 选择器-type -->
    <van-popup v-model:show="showpixtype" @click-overlay="onConfirmstype" position="bottom">
      <van-picker show-toolbar :columns="columnstype" @confirm="onConfirmtype" @cancel="showpixtype = false"
        @change="changeListtype" :confirm-button-text="$t('alert.queding')" :cancel-button-text="$t('alert.quxiao')" />
    </van-popup>
  </div>
</template>

<script>
import userApi from "@/api/user";
// @ is an alias to /src
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Card",
  components: { Tabbar },
  data() {
    return {
      columns: [],
      columnstype: [],
      columnsID: [],
      columnsIDtype: [1, 2, 3, 4, 5],
      typelist: [
        {
          id: 1,
          name: "CPF",
        },
        {
          id: 2,
          name: "CNPJ",
        },
        {
          id: 3,
          name: "Phone",
        },
        {
          id: 4,
          name: "Email",
        },
        {
          id: 5,
          name: "EVP",
        },
      ],
      show: false,
      showpixtype: false,
      crudName: "",
      bank_id: "",
      bank_name: "",
      mobile: "",
      name: "",
      account: "",
      email: "",
      cpf: "",
      ispb: "",
      evp: "",
      pix: "",
      pix_type: "",
      List: [],
      typeid: "",
      typeName: "",
      changName: "",
      changeID: "",
      changNametype: "",
      changeIDtype: "",
      customList: "",
      cardList: "",
      bgColor: ''
    };
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    bankList() {
      userApi
        .bankList()
        .then((data) => {
          console.log(data.data);
          this.List = data.data;
          for (let i = 0; i < data.data.length; i++) {
            this.columns.push(data.data[i].name);
            this.columnsID.push(data.data[i].id);
          }
          this.$toast.clear();
          this.bank();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    onConfirms() {
      this.bank_id = this.changeID;
      this.crudName = this.changName;
      this.show = false;
    },
    changeList(value, index) {
      console.log(value, index);
      this.changeID = this.columnsID[index];
      this.changName = value;
    },
    onConfirm(value, index) {
      this.bank_id = this.columnsID[index];
      this.crudName = value;
      this.show = false;
    },
    onConfirmstype() {
      this.customList.pix_type = this.changeIDtype;
      this.typeName = this.changNametype;
      this.showpixtype = false;
    },
    changeListtype(value, index) {
      console.log(value, index);
      this.changeIDtype = this.columnsIDtype[index];
      this.changNametype = value;
    },

    onConfirmtype(value, index) {
      this.customList.pix_type = this.columnsIDtype[index];
      this.typeName = value;
      this.showpixtype = false;
    },
    bank() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .bank()
        .then((data) => {
          let findName = this.List.find((item) => item.id == data.data.bank_id);
          this.crudName = findName ? findName.name : "";
          let custom;
          if (data.data.custom) {
            custom = data.data.custom;
            let selectfind = this.cardList.find(
              (item) => item.type == "select"
            );
            this.customList = custom;

            if (selectfind) {
              this.typeName = selectfind.selects[custom.pix_type];
            }
          } else {
            this.customList = [];
            let info = [];
            console.log(Object.values(this.cardList));
            for (let i = 0; i < this.cardList.length; i++) {
              info[this.cardList[i].property] = "";
            }
            console.log(info, "info");
            this.customList = Object.assign({}, info);
          }
          console.log(custom, "custom");

          this.bank_id = data.data.bank_id;
          this.bank_name = data.data.bank_name || '';
          this.name = data.data.name;
          this.mobile = data.data.mobile;
          this.account = data.data.account;
          this.email = data.data.email || '';
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    save() {
      for (let i = 0; i < this.cardList.length; i++) {
        if (this.cardList[i].required == 1) {
          console.log(this.cardList[i].property, "findValue");
          if (!this.customList[this.cardList[i].property]) {
            this.$dialog
              .alert({
                title: this.$t("alert.title"),
                confirmButtonText: this.$t("alert.queding"),
                confirmButtonColor: "#007aff",
                message: this.$t("card.qyh") + this.cardList[i].font,
              })
            return;
          }
        }
      }

      if (!this.bank_id || !this.crudName) {
        // this.$toast.fail(this.$t("card.qyh"));

        this.$dialog
          .alert({
            title: this.$t("alert.title"),
            confirmButtonText: this.$t("alert.queding"),
            confirmButtonColor: "#007aff",
            message: this.$t("card.qyh"),
          })
          .then(() => {
            // on close
          });
        return;
      }
      console.log(this.customList, "customList");
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      let customList = JSON.stringify(this.customList);
      userApi
        .updateBank(
          this.bank_id,
          this.bank_name,
          this.mobile,
          this.name,
          this.account,
          this.email,
          customList
        )
        .then((data) => {
          console.log(data);
          this.$toast.clear();
          let that = this;
          this.$dialog
            .alert({
              title: this.$t("alert.title"),
              confirmButtonText: this.$t("alert.queding"),
              confirmButtonColor: "#007aff",
              message: data.message,
            })
            .then(() => {
              // on close
              that.$router.go(-1);
            });
        })
        .catch((err) => {
          this.$toast.clear();
          this.$dialog
            .alert({
              title: this.$t("alert.title"),
              confirmButtonText: this.$t("alert.queding"),
              confirmButtonColor: "#007aff",
              message: err.message,
            })
            .then(() => {
              // on close
            });
        });
    },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    this.$toast.loading({
      message: this.$t("Conectando"),
      forbidClick: true,
      duration: 0,
    });
    this.cardList = JSON.parse(localStorage.getItem("nation_elect"));
    let selectfind = this.cardList.find((item) => item.type == "select");
    if (selectfind) {
      this.columnstype = Object.values(selectfind.selects);
      console.log(this.columnstype, "selectfind");
    }

    this.bankList();
  },
};
</script>
