export default {
  formatDate(value) {
    value = value.toString().replace(/-/g, "/");
    let date = new Date(value);
    console.log(date, "date");
    // let y = date.getFullYear();
    // let MM = date.getMonth() + 1;
    // MM = MM < 10 ? ('0' + MM) : MM;
    // let d = date.getDate();
    // d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    // return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    return h + ":" + m + ":" + s;
  },
  formatDatetow(value) {
    value = value.toString().replace(/-/g, "/");
    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    // let h = date.getHours();
    // h = h < 10 ? ('0' + h) : h;
    // let m = date.getMinutes();
    // m = m < 10 ? ('0' + m) : m;
    // let s = date.getSeconds();
    // s = s < 10 ? ('0' + s) : s;
    return y + "-" + MM + "-" + d;
    // return h + ':' + m + ':' + s;
  },
  formatDatethree(value) {
    value = value.toString().replace(/-/g, "/");
    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
  },
  dongjie(value) {
    value = value.toString().replace(/-/g, "/");
    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 4;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "/" + MM + "/" + d + " " + h + ":" + m + ":" + s;
  },
  dongjiedangqian(value) {
    let dangtime = new Date();
    let dy = dangtime.getFullYear();
    let dMM = dangtime.getMonth() + 1;
    dMM = dMM < 10 ? "0" + dMM : dMM;
    let dd = dangtime.getDate();
    dd = dd < 10 ? "0" + dd : dd;
    value = dy + "-" + dMM + "-" + dd + " " + value;
    console.log(value, "shijian");
    value = value.toString().replace(/-/g, "/");
    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "/" + MM + "/" + d + " " + h + ":" + m + ":" + s;
  },
};
