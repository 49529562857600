import store from "../index";
import userStore from "../user";
export function setToken(token) {
  store.commit("updateToken", token);
  userStore.setToken(token);
}
export function setsessionStorage(key, vlaue) {
  userStore.setsessionStorage(key, vlaue);
}
export function delsessionStorage(key) {
  userStore.delsessionStorage(key);
}

export function clearToken() {
  store.commit("updateToken", null);
  userStore.clearToken();
}
export function setCurrentUserId(userId) {
  store.commit("updateCurrentUserId", userId);
}
export function setCurrentNickname(Nickname) {
  store.commit("updateCurrentNickname", Nickname);
}
export function clearCurrentUserId() {
  store.commit("updateCurrentUserId", null);
}
export function setCurrentUser(user) {
  store.commit("updateCurrentUser", user);
}
export function setCurrentMonetary_unit(monetary_unit) {
  store.commit("updateCurrentMonetary_unit",monetary_unit);
}
export function setCurrentselectListActiveID(selectListActiveID) {
  store.commit("updateCurrentselectListActiveID",selectListActiveID);
}
export function setCurrentout_time_pay(out_time_pay) {
  store.commit("updateCurrentout_time_pay",out_time_pay);
}
export function updateCurrentaction_time(action_time) {
  store.commit("updateCurrentaction_time",action_time);
}
export function updateCurrentmobile_prefix(mobile_prefix) {
  store.commit("updateCurrentmobile_prefix",mobile_prefix);
}
export function updateMemberInfo(memberInfo) {
  store.commit("updatememberInfo",memberInfo);
}
export function updateMemberApishow(meberApishow) {
  store.commit("updatemeberApishow",meberApishow);
}
export function updateBannerImg(bannerImg) {
  store.commit("updateBannerImg",bannerImg);
}
export function updateAboutTxt(text) {
  store.commit("updateAboutTxt",text);
}
export function updateAbout_withdraw(text) {
  store.commit("updateAbout_withdraw",text);
}
export function updateTitle(title) {
  store.commit("updateTitle",title);
}
export function updatePartner(img) {
  store.commit("updatePartner",img);
}
export function updateShopTilte(title) {
  store.commit("updateShopTilte",title);
}
export function updateShop(textList) {
  store.commit("updateShop",textList);
}
export function updateShopListID(textList) {
  store.commit("updateShopListID",textList);
}
export function updateShowApiSns(boole) {
  store.commit("updateShowApiSns",boole);
}