<template>
  <div class="record">
    <header>
      <van-nav-bar :title="$t('record.title')" :style="bgColor">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" @click="JumpGo" />
        </template>
      </van-nav-bar>
      <div class="lineDiv"></div>
    </header>
    <div class="main">
      <van-tabs v-model="active" swipeable @change="TabChange">
        <van-tab :title="$t('record.quanbu')">
          <div class="Statistics">
            <div class="dateArray" @click="showRili = true">
              <div>{{$t('record.kaishisj')}}{{$t('record.jieshusj')}}</div>
              <div class="iconArrow"></div>
            </div>
            <div class="moneyArray">
              <div class="flexMoney">
                <div class="cloreee">{{$t('record.zongsu')}}:{{ total }}</div>
                <div class="cloreee">{{$t('record.dangqian')}}:{{ to ? to : "0" }}</div>
              </div>
            </div>
            <!-- 列表 -->
            <div>
              <div
                class="recordslist"
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="item-time">{{ item.created_at }}</div>
                <div class="item-amount">{{ item.quantity }}</div>
                <div class="item-status">{{ item.mark }}</div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('record.chongzhi')">
          <div class="Statistics">
            <div class="dateArray" @click="showRili = true">
              <div>{{$t('record.kaishisj')}}{{$t('record.jieshusj')}}</div>
              <div class="iconArrow"></div>
            </div>
            <div class="moneyArray">
              <div class="flexMoney">
                <div class="cloreee">{{$t('record.zongsu')}}:{{ total }}</div>
                <div class="cloreee">{{$t('record.dangqian')}}:{{ to ? to : "0" }}</div>
              </div>
            </div>
            <!-- 列表 -->
            <div>
              <div
                class="recordslist"
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="item-time">{{ item.created_at }}</div>
                <div class="item-amount">{{ item.quantity }}</div>
                <div class="item-status">{{ item.mark }}</div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('record.tixian')">
          <div class="Statistics">
            <div class="dateArray" @click="showRili = true">
              <div>{{$t('record.kaishisj')}}{{$t('record.jieshusj')}}</div>
              <div class="iconArrow"></div>
            </div>
            <div class="moneyArray">
              <div class="flexMoney">
                <div class="cloreee">{{$t('record.zongsu')}}:{{ total }}</div>
                <div class="cloreee">{{$t('record.dangqian')}}:{{ to ? to : "0" }}</div>
              </div>
            </div>
            <!-- 列表 -->
            <div>
              <div
                class="recordslist"
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="item-time">{{ item.created_at }}</div>
                <div class="item-amount">{{ item.quantity }}</div>
                <div class="item-status">{{ item.mark }}</div>
              </div>
            </div>
          </div></van-tab
        >
        <van-tab :title="$t('record.dingdan')">
          <div class="Statistics">
            <div class="dateArray" @click="showRili = true">
              <div>{{$t('record.kaishisj')}}{{$t('record.jieshusj')}}</div>
              <div class="iconArrow"></div>
            </div>
            <div class="moneyArray">
              <div class="flexMoney">
                <div class="cloreee">{{$t('record.zongsu')}}:{{ total }}</div>
                <div class="cloreee">{{$t('record.dangqian')}}:{{ to ? to : "0" }}</div>
              </div>
            </div>
            <!-- 列表 -->
            <div>
              <div
                class="recordslist"
                v-for="(item, index) in list"
                :key="index"
                @click="JumpOrder(item)"
              >
                <div class="item-time">{{ item.created_at }}</div>
                <div class="item-amount">{{ item.quantity }}</div>
                <div class="item-status">{{ item.mark }}</div>
              </div>
            </div>
          </div></van-tab
        >
        <van-tab :title="$t('record.tuandui')">
          <div class="Statistics">
            <div class="dateArray" @click="showRili = true">
              <div>{{$t('record.kaishisj')}}{{$t('record.jieshusj')}}</div>
              <div class="iconArrow"></div>
            </div>
            <div class="moneyArray">
              <div class="flexMoney">
                <div class="cloreee">{{$t('record.zongsu')}}:{{ total }}</div>
                <div class="cloreee">{{$t('record.dangqian')}}:{{ to ? to : "0" }}</div>
              </div>
            </div>
            <!-- 列表 -->
            <div>
              <div
                class="recordslist"
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="item-time">{{ item.created_at }}</div>
                <div class="item-amount">{{ item.quantity }}</div>
                <div class="item-status">{{ item.mark }}</div>
              </div>
            </div>
          </div></van-tab
        >
      </van-tabs>
    </div>
    <div class="pagination" v-if="to">
      <van-pagination
        v-model="page"
        :total-items="totai"
        mode="simple"
        :page-count="form"
        @change="handleCurrentChange"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
      </van-pagination>
    </div>
    <tabbar />
    <!-- 日历 -->
    <van-calendar
      v-model:show="showRili"
      type="range"
      @confirm="onConfirmRili"
      :min-date="minDate"
      :formatter="formatter"
       color="#61b7bc"
      title="Por favor elija"
      confirm-text="Confirmar"
      confirm-disabled-text="Confirmar"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Record",
  components: {
    Tabbar,
  },
  data() {
    return {
      showRili: false,
      minDate: new Date(2010, 0, 1),
      totai: "",
      page: 1,
      limit: "10",
      type: "5",
      begin_date: "",
      end_date: "",
      list: [],
      active: 0,
      to: "",
      form: "",
      bgColor:''
    };
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    TabChange(name) {
      console.log(name);
      if (name == 0) {
        this.type = "5";
      } else if (name == 1) {
        this.type = "0";
      } else if (name == 2) {
        this.type = "1";
      } else if (name == 3) {
        this.type = "2";
      } else if (name == 4) {
        this.type = "3";
      }
      this.begin_date = "";
      this.end_date = "";
      this.page = 0;
      this.List();
    },
    onConfirmRili(date) {
      const [start, end] = date;
      this.showRili = false;
      this.begin_date = this.formatDate(start);
      this.end_date = this.formatDate(end);
      this.page = 0;
      this.List();
      //   调用查询
    },
    formatDate(date) {
      let Month = date.getMonth() + 1;
      Month = Month.toString().padStart(2, "0");
      return `${date.getFullYear()}-${Month}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
    },
    formatter(day) {
      // console.log(day)
      if (day.type === "start") {
        day.bottomInfo = "";
      } else if (day.type === "end") {
        day.bottomInfo = "";
      }
      return day;
    },

    // 跳转前查询
    JumpOrder(item) {
      console.log(item);
      this.$toast.loading({
         message: this.$t('Conectando'),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .orderInfo(item.order_id, item.order_goods_id)
        .then((data) => {
          let goods = data.data.goods[0];
          goods["goodsNum"] = data.data.total_goods_count;
          console.log(goods, "123");
          this.$router.push({
            path: "/orderDetail",
            query: {
              goods: JSON.stringify(goods),
            },
          });
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    //列表查询
    List() {
      this.$toast.loading({
         message: this.$t('Conectando'),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .quantityLog(
          this.page,
          this.limit,
          this.type,
          this.begin_date,
          this.end_date
        )
        .then((data) => {
          this.total = data.data.total;
          this.to = data.data.to;
          this.form = data.data.last_page;
          this.list = data.data.data;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    handleCurrentChange(val) {
      console.log("555", val);
      this.page = val;
      this.List();
    },
  },
  created() {
         this.bgColor = localStorage.getItem("bgColor");
    this.List();
  },
};
</script>
<style lang="less" scoped>
.record {
  background: #f7f8fc;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.Statistics {
  background: #f7f8fc;
}
.dateArray {
  padding-top: 0.43rem;
  padding-bottom: 0.11rem;
  display: flex;
  align-items: center;
  padding-left: 0.53rem;
}
.iconArrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 0.11rem solid transparent;
  border-right: 0.11rem solid transparent;
  border-top: 6px solid #333;
  margin-left: 0.13rem;
}
.moneyArray {
  margin-left: 0.53rem;
}
.flexMoney {
  display: flex;
  justify-content: space-around;
  margin-top: 0.11rem;
}
.cloreee {
  color: #334360;
  opacity: 0.4;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.recordslist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.68rem;
  font-size: 0.32rem;
  padding: 0 0.53rem;
  border-bottom: 1px solid #eeeff2;
  box-shadow: 0 0 0 0 #eeeff2;
}
.item-time {
  font-size: 0.32rem;
  pointer-events: none;
}
.item-amount {
  color: #f80;
  flex: 1;
  margin-left: 0.8rem;
  pointer-events: none;
  font-size: 0.37rem;
}
.item-status {
  font-size: 0.32rem;
  text-align: right;
  pointer-events: none;
      width: 3.33rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>