<template>
  <div>
    <header>
      <van-nav-bar left-arrow @click-left="JumpGo" class="bg-b-2 text-t-1"
        :title="showType == '0' ? $t('password.title') : $t('password.titles')">
        <template #left>
          <van-icon name="arrow-left" size="20" />
        </template>
      </van-nav-bar>
    </header>


    <div class="p-3">
      <div class="flex flex-col p-2 divide-y divide-dashed">
        <div class="flex justify-between py-2">
          <div class="flex-1">{{ $t('password.shouji') }}</div>
          <div class="flex flex-1 justify-between">
            <div>{{ mobile }}</div>
            <!-- 发送验证码 -->
            <div class="">{{ $t('password.fasong') }}</div>
            <!-- 验证码倒计时 -->
            <div class="" v-if="false">60s</div>
          </div>
        </div>
        <div class="flex justify-between py-2">
          <div class="">{{ $t('password.yanzheng') }}</div>
          <div class="">
            <div class="form-input">
              <input type="text" v-model="pass" :placeholder="$t('address.qtx')" />
            </div>
          </div>
        </div>
        <div class="flex justify-between py-2">
          <div class="">{{ $t('password.xinmima') }}</div>
          <div class="">
            <div class="form-input">
              <input type="password" v-model="new_pass" :placeholder="$t('address.qtx')" />
            </div>
          </div>
        </div>
      </div>
      <div class="bg-p-1 w-full text-center p-2 rounded-md text-p-3" @click="savePass">{{ $t('address.baocun') }}</div>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Password",
  components: { Tabbar },
  data() {
    return {
      mobile: "",
      showType: null,
      new_pass: "",
      pass: "",
      bgColor: ''
    };
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    savePass() {
      if (this.showType == '0') {
        // 修改密码
        console.log(this.new_pass, this.pass);
        userApi
          .newPass(this.new_pass, this.pass)
          .then((data) => {
            this.$toast.clear();
            this.$toast.fail(data.message);
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.message);
          });
      } else {
        // 修改安全码
        userApi
          .newPassCode(this.new_pass, this.pass)
          .then((data) => {
            console.log(data);
            this.$toast.clear();
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.message);
          });
      }
    },
    userinfo() {
      userApi
        .userInfo()
        .then((data) => {
          this.mobile = data.data.mobile;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
  mounted() {
    console.log(this.$route.query.type);
    this.showType = this.$route.query.type;
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    this.$toast.loading({
      message: this.$t('Conectando'),
      forbidClick: true,
      duration: 0,
    });
    this.userinfo();
  },
};
</script>
<style lang="less" scoped>
.password {
  background: #f7f7f7;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.main {
  box-sizing: border-box;
  padding: 0 0.4rem 0.4rem;
}

.items {
  background: #fff;
  border-radius: 0.13rem;
  padding: 0.4rem 0.4rem 0.03rem 0.4rem;
  margin-top: 0.4rem;
}

.items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.07rem;
  line-height: 1.07rem;
  margin-bottom: 0.27rem;
  position: relative;
}

.evan-form-item-container--left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.items .item:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  position: absolute;
  bottom: 0;
  left: 0;
}

.evan-form-item-container__label {
  width: 2.13rem;
  font-size: 0.37rem;
  color: #666;
  line-height: 0.53rem;
  padding: 0.32rem 0;
  display: inline-block;
}

.evan-form-item-container__main {
  flex: 1;
  min-height: 1.2rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.form-input {
  width: 100%;

  input {
    caret-color: #017aff;
  }

  input::placeholder {
    color: grey;
  }
}

.getCode {
  position: absolute;
  right: 0.4rem;
  z-index: 10;
}

.save {
  color: #fff;
  background: linear-gradient(145deg, #6dcab8, #3a78cb);
  border-radius: 20px;
  text-align: center;
  padding: 10px 0;
  margin: 30px 0;
}
</style>