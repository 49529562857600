import "./style.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import "vant/lib/index.css";
// import './utils/style.less';
// import "lib-flexible/flexible";
import { Locale } from "vant";
import { Lazyload } from "vant";

// 引入其他语言语言包
// import esES from 'vant/es/locale/lang/es-ES';
//Locale.use('es-ES', esES);
//英文
import enUS from "vant/es/locale/lang/en-US";
Locale.use("en-US", enUS);
import VueI18n from "./i18n";
createApp(App)
  .use(store)
  .use(router)
  .use(vant)
  .use(Lazyload)
  .use(VueI18n)
  .mount("#app");
document.addEventListener("plusready", function () {
  var webview = window.plus.webview.currentWebview();
  window.plus.key.addEventListener("backbutton", function () {
    webview.canBack(function (e) {
      if (e.canBack) {
        webview.back();
      } else {
        // webview.close(); //hide,quit
        // plus.runtime.quit();
        // 首页返回键处理
        // 处理逻辑：1秒内，连续两次按返回键，则退出应用；
        var first = null;
        window.plus.key.addEventListener(
          "backbutton",
          function () {
            // 首次按键，提示‘再按一次退出应用’
            if (!first) {
              first = new Date().getTime();
              setTimeout(function () {
                first = null;
              }, 1000);
            } else {
              if (new Date().getTime() - first < 1500) {
                window.plus.runtime.quit();
              }
            }
          },
          false
        );
      }
    });
  });
});
var log = console.log; // 不屏蔽
var log1 = function () {}; // 屏蔽
console.log = function () {
  if (arguments[0] instanceof Error) {
    log(...arguments);
  } else {
    log1(...arguments);
  }
};
