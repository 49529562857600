const teq = {
  home: {
    title: "Alıcı sipariş merkezi",
    chongzhi: "şarj etmek",
    tixian: "Çekil",
    yaoqing: "Davet et",
    Aviso: "Uyarmak",
    Balance: "Para kaynağı",
    Hoy: "Bugün",
    Financiero: "Finans",
    Congelar: "Donmak",
    MiembroNoticias: "Üye bilgisi",
    Socio: "Ortak",
    About: "Hakkımızda",
    aboutwithdrawal: "Para Çekme Hakkında",
  },
  about: {
    title: "Hakkımızda",
    guanyutixian: "Para Çekme Hakkında",
  },
  Recargar: {
    title: "şarj etmek",
    Introducir: "Lütfen şarj miktarını girin",
    tps: "Minimum şarj miktarı",
    Selección: "Hızlı miktar seçimi",
    text: "Hatırlatma: Banka platformu hesabı herhangi bir zamanda değiştirilebilir. Her para yatırma işlemi için lütfen bilgileri doldurduğunuz sayfaya gidin ve en son hesap numarasını belirtin. Süresi dolmuş bir hesaba para yatırırsanız sorumlu değiliz!",
  },
  RecargarAction: {
    title: "Kontör gönder",
    Cantidad: "şarj miktarı",
    Información: "Alacaklı bilgileri",
    tps: "Aktarım tamamlandıktan sonra, önce aktarımın ekran görüntüsünü yüklediğinizden emin olun ve ardından gönder düğmesine tıklayın!",
    jietu: "Aktarım ekran görüntüsünü yükle",
    querenchongzhi: "Şarjı onaylayın",
  },
  Recolectar: {
    title: "Toplama emri",
    Pedidos: "Sipariş komisyonu",
    yongjin: "Komisyon",
    jinrishouru: "Bugün gelir",
    gerenshouru: "Kişisel gelir",
    yiwancheng: "Sipariş tamamlandı",
    dongjie: "Siparişi dondur",
    weiwancheng: "Tamamlanmamış siparişler",
    dongjiejine: "Donmuş miktar",
    dangqianyue: "Cari bakiye",
    kaishi: "Toplamaya başla",
    tingzhi: "Toplamayı bırak",
    tishi: "Ipucu",
    Segundo: "Ikinci",
    text: "Kullanıcı siparişi tamamladıktan sonra platform aynı anda sipariş bilgilerini satıcıya gönderecek, kullanıcı sipariş bedelini belirli bir süre içinde ödemezse gizlilik doğrulamasından kaçınmak için sipariş dondurulacaktır. Bir sipariş dondurulduğunda siparişteki para da dondurulacağı için tüm siparişleri eksiksiz tamamlamanız gerekecek, bunu aklınızda bulundurun.",
  },
  Registro: {
    title: "Kayıt",
    daizhifu: "Ödenecek",
    yijiesuan: "Yerleşik",
    chulizhong: "İşleme",
    yidongjie: "Dondurulmuş",
    meiyoujilu: "Kayıt yok",
    xianzaishouji: "şimdi topla",
    jiage: "Fiyat",
    yongjin: "Komisyon",
    shouyi: "Gelir",
    shengyutime: "Kalan zaman",
    chakan: "Sipariş kontrolü",
    weizhifu: "ödenmemiş",
    fukuan: "Ödeme",
  },
  Mex: "",
  Confirmar: "Onaylamak",
  Conectando: "Yükleniyor",
  fuzhiOK: "çalışma alanına kopyalandı",
  Detalles: {
    title: "sipariş detayları",
    yigong: "Toplamda ",
    dingdan: " emirler",
    dangqian: "Şu anda ilk",
    yiwancheng: "sipariş tamamlandı",
    dongjie: "sipariş donduruldu",
    dengdai: "Alıcının siparişi onaylamasını beklemek",
    dongjies: "Donmak",
    zhuyao: "Ana mağaza",
    guanfang: "Resmi garanti",
    zongshuliang: "Toplam miktar",
    daigou: "satın alma ücreti",
    shouyi: "Gelir",
    fukuanzt: "ödeme durumu",
    weizhifu: "ödenmemiş",
    yizhifu: "Paralı",
    jiedong: "Çözülme süresi",
    zhifu: "ödemek",
    shuruzhifu: "Lütfen ödeme şifresini girin",
    yanzheng: "Ödeme şifresini doğrulayın",
  },
  Pofile: {
    title: "hesap detayları",
    yaoqingma: "Davet Kodu",
    fuzhi: "Bağlantıyı kopyala",
    Balance: "Para kaynağı",
    tixian: "Çekil",
    chongzhi: "şarj etmek",
    zongji: "Toplam",
    jinrong: "Finans",
    xiangqing: "Detaylar",
    dongjie: "Donmak",
    geren: "Kişisel",
    tuandui: "Takım",
    yaoqing: "Davet et",
    xiaoxi: "müşteri servisi",
    dizhi: "Adres",
    guanyu: "Hakkımızda",
    renwu: "Davet görevleri",
    anquan: "Emniyet",
    fuwu: "Servis Merkezi",
  },
  alert: {
    title: "Ipucu",
    quxiao: "İptal etmek",
    queding: "Elbette",
    acuerdo: "Katılıyorum",
    fuzhi: "Kopyalamak",
    qianwang: "Gitmek",
  },
  withdrawal: {
    title: "Çekil",
    yue: "Cari bakiye",
    shouxufei: "Para çekme ücreti",
    dancitixian: "Tek çekim için minimum işlem ücreti",
    shuru: "Miktarı girin",
    dangqianjine: "Cari hesap çekim tutarı",
    suoyou: "Hepsini çıkar",
    zhanghuming: "Hesap adı",
    zhanghu: "Hesap",
    dianhua: "Telefon numarası",
    tps: "Ipucu",
    text: "1. Lütfen banka bilgilerini doğru doldurunuz.Bilgileri girerken yapabileceğiniz hatalardan kaynaklanan para kaybından sorumlu değiliz, ertesi gün 2:24'ten önce belirli varış zamanı bankaya tabidir.",
    queren: "Para çekme işlemini onaylayın",
    guanlian: "Lütfen para çekmeden önce banka kartınızı bağlayın",
  },
  prsonal: {
    title: "Kişisel",
    name: "İsim",
    shouji: "Telefon numarası",
    yhk: "Banka kartı",
    tuichu: "Oturumu Kapat",
    tianxie: "Doldurun",
  },
  card: {
    title: "Banka kartı ayarları",
    zhanghuxinxi: "Hesap Bilgileri",
    tps: "Lütfen hesabınızın doğru olduğundan emin olun, herhangi bir hatadan sorumlu değiliz.",
    yhmc: "Banka adı",
    zhanghuming: "Kullanıcı adı",
    zhanghu: "Hesap",
    shouji: "Telefon numarası",
    fenhang: "Banka şubesi adı",
    baocun: "Kaydetmek",
    jianyi: "Önermek",
    text: "Değiştirmeniz gerekmesi durumunda, banka kartı bağlama yöntemi için lütfen çevrimiçi müşteri hizmetleri ile iletişime geçin!",
    qyh: "lütfen seç",
    qsryhm: "Lütfen banka kullanıcı adını girin",
    qzh: "Lütfen hesap girin",
    qdh: "Lütfen telefon girin",
    qfhmc: "Lütfen şube adını girin",
    qsrifcs: "Lütfen IFSC yi girin",
    youxiang: "Posta",
    plyx: "lütfen e-postanızı girin",
    jgbh: "kurum numarası",
    pljgbh: "Lütfen kurum numarasını giriniz",
    gjj: "İhtiyat fonu",
    plgjj: "Girin lütfen",
    isbp: "ISBP",
    plisbp: "Lütfen ISBP'yi girin",
    pixtype: "PIXType",
    plpixtype: "Lütfen PIXType ı girin",
    pix: "PIX",
    plpix: "Lütfen PIX girin",
    evp: "EVP",
    plevp: "Lütfen EVP yi girin",
  },
  team: {
    title: "Takım",
    Directo: "Doğrudan",
    Secundario: "Orta okul",
    Terciario: "üçüncü",
    Comisión: "Bugünün satın alma ücreti",
    zongyongjin: "Toplam komisyon",
    shuzi: "sayı",
    sudu: "hız",
    jinricz: "Bugün şarj edin",
    tixian: "tam çekilme",
  },
  invitation: {
    title: "Arkadaşları davet etmek",
    notitle: "Telefonunuzdan yarı zamanlı işler yapabilirsiniz",
    notitles: "Daha fazla para kazanmak için arkadaşlarınızı davet edin",
    yqm: "Davet Kodu",
    fuzhi: "Kopyalamak",
    xzyq: "şimdi davet et",
  },
  message: {
    title: "Müşteri servisi",
    go: "Bağlamak",
  },
  address: {
    title: "Adres yönetimi",
    xm: "İsim",
    dianhua: "Telefon",
    qtx: "Lütfen doldur",
    dizhixq: "Teslimat adresi bilgileri",
    baocun: "Kaydetmek",
  },
  invitationTask: {
    title: "Davet görevleri",
    zongren: "Toplam insan",
    gengxin:
      "Güncelleme görevi: yeniden yüklenen kullanıcı geçerli bir kullanıcıdır",
    yq: "Davet et",
    jige: "aktif üyeler",
    qianwang: "gitmek",
    renwujiangli: "görev ödülleri",
  },
  safe: {
    aqgl: "Güvenlik Yönetimi",
    denglu: "Giriş şifresi",
    zfmm: "ödeme şifresi",
    bh: "Korumak",
  },
  password: {
    title: "Giriş şifresi",
    titles: "ödeme şifresi",
    shouji: "Telefon numarası",
    yanzheng: "Doğrulama kodu",
    fasong: "Göndermek",
    xinmima: "Yeni Şifre",
    pxinmima: "yeni bir şifre gir",
  },
  record: {
    title: "Detaylar",
    quanbu: "Tümü",
    chongzhi: "şarj etmek",
    tixian: "Çekil",
    dingdan: "Sipariş",
    tuandui: "Takım",
    zongsu: "Toplam",
    dangqian: "Güncel veri",
    kaishisj: "Başlangıç ​​zamanı",
    jieshusj: "Bitiş zamanı",
  },
  login: {
    zhanghu: "Lütfen hesap girin",
    mima: "Lütfen şifre giriniz",
    denglu: "Giriş yapmak",
    zhuce: "Şimdi üye Ol",
    wjmm: "Şifremi Unuttum",
  },
  regist: {
    touxiang: "Avatar",
    yhm: "Kullanıcı adı",
    mima: "Parola",
    zhifu: "ödeme şifresi",
    yq: "Davet et",
    pyhm: "Lütfen bir kullanıcı adı giriniz",
    ptel: "Lütfen telefon numarasını girin",
    shuru: "Lütfen şifre giriniz",
    shurupay: "6 haneli güvenlik kodunu girin",
    pyqm: "Davet Kodu",
    plyqm: "Lütfen davetiye kodunu girin",
    zhuce: "Kayıt ol",
    yijing: "Zaten hesabınız var mı?",
  },
  tabbars: {
    shouye: "ön Sayfa",
    chongzhi: "şarj etmek",
    shouji: "Toplamak",
    dingdan: "Sipariş",
    wode: "Benim",
  },
  zhaohuimima: "Lütfen işleme için müşteri hizmetlerine başvurun",
  yanzhengzhifu: "Ödeme şifresini doğrulayın",
  huanying: "Hoşgeldiniz",
  yinhang: "Banka",
  yinhangbh: "Banka numarası",
  beizhu: "Açıklama",
  zhifufs: "Ödeme şekli",
  qxzzffs: "Lütfen ödeme şeklini seçin",
  usdt: {
    title: "USDTile yükleme yapın",
    tips1: "Lütfen yüklemek istediğiniz fiat para miktarını seçin",
    rates: "Döviz kurları",
    cashTips: "Nakit para yüklemeyi seçersiniz",
    usdtTips: "USDT(TRC20)  ödemeniz gerekiyor",
    customAmount: "Yüklemek istediğiniz tutarı girin",
    importantTips: {
      one: "1. Lütfen yüklemeyi seçtiğiniz USDT miktarı ile yükleme yapın, aksi takdirde kredilendirilmeyecektir",
      two: "2. Lütfen USDT'ye yükleme işleminizi beş dakika içinde tamamlayın.",
      three:
        "3. Herhangi bir sorunla karşılaşırsanız, yardım için lütfen müşteri hizmetleri ile iletişime geçin",
    },
  },
  selectChannel: {
    title: "Lütfen bir kanal seçin",
    cashChannel: "Fransız para birimi yükleme kanalı",
    usdtChannel: "USDT(TRC20)  yükleme kanalı",
    rewards: "Ek %5 bonus",
  },
};
export default teq;
