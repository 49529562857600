const ru = {
  home: {
    title: "Заказ покупателя в магазине",
    chongzhi: "Пополнение",
    tixian: "Вывод средств",
    yaoqing: "Приглашение",
    Aviso: "Советы",
    Balance: "Баланс",
    Hoy: "Сегодня",
    Financiero: "Финансы",
    Congelar: "Замороженный",
    MiembroNoticias: "Информация для участника",
    Socio: "Сотрудничество",
    About: "О нас",
    aboutwithdrawal: "О выводе средств",
  },
  about: {
    title: "О нас",
    guanyutixian: "О выводе средств",
  },
  Recargar: {
    title: "Пополнение",
    Introducir: "Пожалуйста, введите сумму пополнения",
    tps: "Минимальная сумма пополнения ",
    Selection: "Быстрый выбор суммы",
    text: "Подсказка: счет на платформе банка может изменяться в любое время. Для каждого депозита пожалуйста перейдите на страницу, где заполняете информацию и укажите последний номер счета. Если вы внесете депозит в истекший счет, мы не несем ответственность!",
  },
  RecargarAction: {
    title: "Отправить пополнение",
    Cantidad: "Сумма пополнения",
    Information: "Информация о получателе",
    tps: "После завершения перевода, обязательно сначала загрузите снимок экрана перевода, а затем нажмите кнопку Отправить!",
    jietu: "Загрузить снимок экрана перевода",
    querenchongzhi: "Подтвердить пополнение",
  },
  Recolectar: {
    title: "Собрать заказы",
    Pedidos: "Комиссия заказа",
    yongjin: "Комиссия",
    jinrishouru: "Доход сегодня",
    gerenshouru: "Личный доход",
    yiwancheng: "Завершенный заказ",
    dongjie: "Замороженный заказ",
    weiwancheng: "Незавершенные заказы",
    dongjiejine: "Замороженная сумма",
    dangqianyue: "Текущий баланс",
    kaishi: "Начать сбор",
    tingzhi: "Остановить сбор",
    tishi: "Советы",
    Segundo: "Второй",
    text: "После того, как пользователь завершит заказ, платформа одновременно отправит информацию о заказе продавцу, если пользователь не оплатит стоимость заказа в течение определенного периода времени, заказ будет заморожен, чтобы избежать проверки конфиденциальности. Когда заказ заморожен, деньги в заказе также будут заморожены, поэтому вам нужно будет выполнить все заказы в полном объеме, имейте это ввиду.",
  },
  Registro: {
    title: "Запись",
    daizhifu: "Ожидает оплаты",
    yijiesuan: "Рассчитано",
    chulizhong: "В процессе",
    yidongjie: "Заморожено",
    meiyoujilu: "Нет записи",
    xianzaishouji: "Собирается сейчас",
    jiage: "Цена",
    yongjin: "Комиссия",
    shouyi: "Прибыль",
    shengyutime: "Оставшееся время",
    chakan: "Посмотреть заказ",
    weizhifu: "Неоплаченный",
    fukuan: "Оплата",
  },
  Mex: "",
  Confirmar: "Подтвердить",
  Conectando: "Загружается",
  fuzhiOK: "Скопировано в буфер обмена",
  Detalles: {
    title: "Детали заказа",
    yigong: "Всего у Вас ",
    dingdan: "-ый заказ",
    dangqian: " заказа, это Ваш ",
    yiwancheng: "Заказ выполнен",
    dongjie: "Заказ заморожен",
    dengdai: "Ожидание подтверждения заказа покупателем",
    dongjies: " до заморожения",
    zhuyao: "Основной магазин",
    guanfang: "Официальная гарантия",
    zongshuliang: "Общая сумма",
    daigou: "Стоимость закупки",
    shouyi: "Прибыль",
    fukuanzt: "Статус оплаты",
    weizhifu: "Неоплаченный",
    jiedong: "Время размораживания",
    zhifu: "Оплата",
    yizhifu: "Оплачено",
    shuruzhifu: "Пожалуйста, введите пароль для оплаты",
    yanzheng: "Проверьте пароль для оплаты",
  },
  Pofile: {
    title: "Детали учетной записи",
    yaoqingma: "Код приглашения",
    fuzhi: "Скопировать ссылку",
    Balance: "Баланс",
    tixian: "Снятие",
    chongzhi: "Пополнение",
    zongji: "Всего",
    jinrong: "Финансы",
    xiangqing: "Подробности",
    dongjie: "замороженные",
    geren: "Личный",
    tuandui: "Команда",
    yaoqing: "Приглашение",
    xiaoxi: "Сообщение",
    dizhi: "Адрес",
    guanyu: "О нас",
    renwu: "Задача приглашения",
    anquan: "Безопасность",
    fuwu: "Служба поддержки",
  },
  alert: {
    title: "Подсказка",
    quxiao: "Отменить",
    queding: "ОК",
    acuerdo: "Согласен",
    fuzhi: "Копировать",
    qianwang: "Переход",
  },
  withdrawal: {
    title: "Вывод средств",
    yue: "Текущий баланс",
    shouxufei: "Комиссия за вывод наличных",
    dancitixian: "Минимальная комиссия за одиночный вывод",
    shuru: "Пожалуйста, введите сумму",
    dangqianjine: "Сумма вывода из текущего счета",
    suoyou: "Изымать все",
    zhanghuming: "Имя счета",
    zhanghu: "Счет",
    dianhua: "Номер телефона",
    tps: "Подсказка",
    text: "1. Пожалуйста, точно заполните информацию о банке. Мы не несем ответственности за потерю капитала, вызванной возможной ошибкой при вводе информации; До 2:24 следующего дня, конкретное время прибытия должно быть подлежащим банку",
    queren: "Подтвердить вывод",
    guanlian: "Пожалуйста, свяжите свою банковскую карту перед выводом",
  },
  personal: {
    title: "Личный",
    name: "Полное имя",
    shouji: "Номер телефона",
    yhk: "Банковская карта",
    tuichu: "Выйти",
    tianxie: "Заполнить",
  },
  card: {
    title: "Настройки банковской карты",
    zhanghuxinxi: "Информация об учетной записи",
    tps: "Убедитесь, что номер вашей учетной записи верен. Мы не несем ответственности за любые ошибки.",
    yhmc: "Название банка",
    zhanghuming: "Имя пользователя",
    zhanghu: "Учетная запись",
    shouji: "Номер телефона",
    fenhang: "Название филиала банка",
    baocun: "Сохранить",
    jianyi: "Предложение",
    text: "Если необходимо изменить, пожалуйста, обратитесь в онлайн-клиентскую службу по поводу способа привязки банковской карты!",
    qyh: "Пожалуйста, выберите ",
    qsryhm: "Пожалуйста, введите имя пользователя банка",
    qzh: "Пожалуйста, введите свой счет",
    qdh: "Пожалуйста, введите номер телефона",
    qfhmc: "Пожалуйста, введите название филиала",
    qsrifcs: "Пожалуйста, введите IFSC",
    youxiang: "Электронная почта",
    plyx: "Пожалуйста, введите электронную почту",
    jgbh: "Номер организации",
    pljgbh: "Пожалуйста, введите номер организации",
    gjj: "CPF",
    plgjj: "Пожалуйста, введите",
    isbp: "ISBP",
    plisbp: "Пожалуйста, введите ISBP",
    pixtype: "PIXType",
    plpixtype: "Пожалуйста, введите PIXType",
    pix: "PIX",
    plpix: "Пожалуйста, введите PIX",
    evp: "EVP",
    plevp: "Пожалуйста, введите EVP",
  },
  team: {
    title: "Команда",
    Directo: "Напрямую",
    Secundario: "Вторичный",
    Terciario: "Третий",
    Comisión: "Комиссия на сегодня",
    zongyongjin: "Общая комиссия",
    shuzi: "Число",
    sudu: "Скорость",
    jinricz: "Пополнение сегодня",
    tixian: "Полный вывод",
  },
  invitation: {
    title: "Пригласить друзей",
    notitle: "Вы можете заниматься частичной работой через свой мобильный телефон",
    notitles: "Приглашайте друзей, чтобы зарабатывать вместе больше денег",
    yqm: "Код приглашения",
    fuzhi: "Копировать",
    xzyq: "Теперь приглашать",
  },
  message: {
    title: "Клиентская служба",
    go: "Связаться",
  },
  address: {
    title: "Управление адресами",
    xm: "Полное имя",
    dianhua: "Телефон",
    qtx: "Пожалуйста, заполните",
    dizhixq: "Информация о адресе для получения",
    baocun: "Сохранение",
  },
  invitationTask: {
    title: "Задача по приглашению",
    zongren: "Общее количество",
    gengxin: "Обновление задачи: перегруженный пользователь является действительным пользователем",
    yq: "приглашение",
    jige: "Действительные участники",
    qianwang: "ПЕРЕЙТИ",
    renwujiangli: "Награда за задание",
  },
  safe: {
    aqgl: "управление безопасностью",
    denglu: "пароль для входа",
    zfmm: "пароль для оплаты",
    bh: "защита",
  },
  password: {
    title: "пароль для входа",
    titles: "пароль для оплаты",
    shouji: "номер телефона",
    yanzheng: "Код подтверждения",
    fasong: "Отправить",
    xinmima: "новый пароль",
    pxinmima: "введите новый пароль",
  },
  record: {
    title: "Детали",
    quanbu: "все",
    chongzhi: "перезарядка",
    tixian: "вывод средств",
    dingdan: "заказ",
    tuandui: "команда",
    zongsu: "общее количество",
    dangqian: "текущие данные",
    kaishisj: "время начала",
    jieshusj: "время окончания",
  },
  login: {
    zhanghu: "Пожалуйста, введите ваш аккаунт",
    mima: "Пожалуйста, введите пароль",
    denglu: "Войти",
    zhuce: "Зарегистрироваться сейчас",
    wjmm: "Я забыл свой пароль",
  },
  regist: {
    touxiang: "Аватар",
    yhm: "Имя пользователя",
    mima: "Пароль",
    zhifu: "Пароль оплаты",
    yq: "Приглашение",
    pyhm: "Пожалуйста, введите имя пользователя",
    ptel: "Пожалуйста, введите номер телефона",
    shuru: "Пожалуйста, введите пароль",
    shurupay: "Введите 6 цифр",
    pyqm: "Код приглашения",
    plyqm: "Пожалуйста, введите код приглашения",
    zhuce: "зарегистрироваться",
    yijing: "У меня уже есть аккаунт？",
  },
  tabbars: {
    shouye: "Домой",
    chongzhi: "Пополнить",
    shouji: "Собрать",
    dingdan: "Заказы",
    wode: "Я",
  },
  zhaohuimima: "Пожалуйста, обратитесь в службу поддержки для обработки",
  yanzhengzhifu: "Проверить пароль для оплаты",
  huanying: "Добро пожаловать",
  yinhang: "Банк",
  yinhangbh: "Номер банка",
  beizhu: "Примечание",
  zhifufs: 'Способ оплаты',
  qxzzffs: 'Пожалуйста, выберите способ оплаты',
  usdt: {
    title: "Канал пополнения USDT (TRC20)",
    tips1: "Выберите сумму пополнения",
    rates: "Обменный курс",
    cashTips: "Вы выбрали сумму пополнения",
    usdtTips: "Вы должны использовать USDT (TRC20) для оплаты",
    customAmount: "Вы можете ввести сумму непосредственно",
    importantTips: {
      one: "1. Пожалуйста, переведите ту же сумму, что и выше, для USDT",
      two: "2. Пожалуйста, завершите перевод в течение 5 минут, в противном случае время истечет и он не удастся.",
      three: "3. Если у вас есть какие-либо проблемы, пожалуйста, свяжитесь с нашими представителями клиентской службы, чтобы они могли вам помочь."
    },
  },
  selectChannel: {
    title: "Выберите способ оплаты",
    cashChannel: "Канал оплаты наличными",
    usdtChannel: "Канал оплаты USDT (TRC20)",
    rewards: "Дополнительный бонус 5% наличных",
  },
};
export default ru;
