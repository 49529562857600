<template>
  <div class="order">
    <header>
      <van-nav-bar title="Detalles de pedido">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" />
        </template>
      </van-nav-bar>
    </header>
    <div class="main">
      <div
        class="card"
        style="padding: 0.05rem 0.4rem; background: rgb(247, 247, 247)"
      >
        <div class="txt">Robo total 3 Pedido， Ahora es 1 Pedido</div>
      </div>
      <div class="status card">
        <div class="txt">
          Espere a que el comprador confirme que va a tener lugar el pedido. 49:
          32: Congelar.
        </div>
      </div>
      <div class="card goods">
        <div class="top flex-row">
          <div class="icon-shangdian"></div>
          <div>Mercado Libre  tienda principal.</div>
        </div>
        <div class="bottom flex-row">
          <div class="shopingImg">
            <img src="@/assets/image/shoping.jpg" alt="" />
          </div>
          <div class="right">
            <div class="title">22KT Yellow Gold Stud Earrings for Women</div>
            <div class="number flex-row">
              <div>Mex$4395.20</div>
              <div>x1</div>
            </div>
            <div class="tag">Garantías oficiales</div>
          </div>
        </div>
      </div>
      <div class="count card">
        <div class="flex-row">
          <div>Cantidad total</div>
          <div>Mex$4395.20</div>
        </div>
        <div class="flex-row">
          <div>Comisión.</div>
          <div>10%</div>
        </div>
        <div class="flex-row">
          <div>Ganancias</div>
          <div>439.52</div>
        </div>
        <div class="flex-row">
          <div>Estado de pago</div>
          <div class="color3a78cb">Sin pagar</div>
        </div>
      </div>
      <div class="fix flex-row">
        <div class="saveMoney">Mex$4395.20</div>
        <van-button type="primary" size="mini" class="save"> Pagar</van-button>
      </div>
    </div>
        <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Order",
  components: {Tabbar},
  data() {
    return {
      active: 0,
    };
  },
};
</script>
<style lang="less" scoped>
.order {
  background: #f7f8fc;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
.card {
  padding: 0.4rem;
  background: #fff;
  margin-top: 0.27rem;
  border-radius: 0.27rem;
}
.txt {
  font-weight: 700;
  margin-right: 0.27rem;
  color: #666;
}
.status {
  font-size: 0.35rem;
  background: #fbf8d9;
  padding: 0.4rem;
}
.goods .top {
  margin-bottom: 0.13rem;
  justify-content: flex-start;
  font-size: 0.4rem;
}
.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-shangdian {
  width: 0.4rem;
  height: 0.4rem;
  background: red;
  margin: 0 0.27rem;
}
.bottom {
}
.shopingImg {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 6px;
  img {
    width: 100%;
    height: 100%;
  }
}
.bottom .right {
  width: calc(100% - 2.67rem);
}
.right .title {
  font-size: 0.37rem;
  margin-bottom: 0.13rem;
  height: 1.07rem;
  overflow: hidden;
}
.number div:first-child {
  font-size: 0.43rem;
}
.number div:last-child {
  color: #666;
}
.tag {
  color: #fff;
  font-size: 0.32rem;
  padding: 0.05rem 0.13rem;
  border-radius: 0.13rem;
  background: #4caf50;
  display: inline-block;
  margin-top: 0.08rem;
}
.count {
  color: #999;
  font-size: 0.37rem;
}
.count div:first-child {
  margin-bottom: 0.13rem;
}
.color3a78cb {
  color: #3a78cb;
  font-weight: 700;
}
.fix {
  width: 100%;
  height: 1.33rem;
  padding: 0 0.4rem;
  background: #fff;
  border-top: 0.05rem solid #f2f2f2;
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 999;
}
.saveMoney {
  color: #000;
  font-size: 14px;
  width: calc(100% - 3.87rem);
}
.save {
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  color: #fff;
      margin-left: auto;
    margin-right: auto;
    width: 1.87rem;
    height: .8rem;
    font-size: .35rem;
     border-radius: 5px;
}
.save::before {
    content: " ";
    width: 200%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid rgba(0,0,0,.2);
    -webkit-transform: scale(.5);
    transform: scale(.5);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    box-sizing: border-box;
    border-radius: 10px;
}
</style>
