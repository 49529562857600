export default {
  updateToken(state, token) {
    state.token = token;
  },
  updateCurrentUserId(state, userId) {
    state.currentUserId = userId;
  },
  updateCurrentUser(state, user) {
    state.currentUser = user;
  },
  updateCurrentNickname(state, Nickname) {
    state.nickname = Nickname;
  },
  updateCurrentMonetary_unit(state, monetary_unit) {
    state.monetary_unit = monetary_unit;
  },
  updateCurrentselectListActiveID(state, selectListActiveID) {
    state.selectListActiveID = selectListActiveID;
  },
  updateCurrentout_time_pay(state, out_time_pay) {
    state.out_time_pay = out_time_pay;
  },
  updateCurrentaction_time(state, action_time) {
    state.action_time = action_time;
  },
  updateCurrentmobile_prefix(state, mobile_prefix) {
    state.mobile_prefix = mobile_prefix;
  },
  updatememberInfo(state, memberInfo) {
    state.memberInfo = memberInfo;
  },
  updatemeberApishow(state, meberApishow) {
    state.meberApishow = meberApishow;
  },
  updateBannerImg(state, bannerImg) {
    state.bannerImg = bannerImg;
  },
  updateAboutTxt(state, text) {
    state.aboutTxt = text;
  },
  updateAbout_withdraw(state, text) {
    state.about_withdraw = text;
  },
  updateTitle(state, title) {
    state.homeTitle = title;
  },
  updatePartner(state, img) {
    state.partner = img;
  },
  updateShopTilte(state, title) {
    state.ShopTilte = title;
  },
  updateShop(state, textList) {
    state.ShopList = textList;
  },
  updateShopListID(state, ShowIDlist) {
    state.ShopListID = ShowIDlist;
  },
  updateShowApiSns(state, boole) {
    state.ShowApiSns = boole;
  }
};
