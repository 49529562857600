const zh = {
  home: {
    title: "买家订单商城",
    chongzhi: "充值",
    tixian: "提现",
    yaoqing: "邀请",
    Aviso: "警告",
    Balance: "资金",
    Hoy: "今天",
    Financiero: "金融",
    Congelar: "冻结",
    MiembroNoticias: "会员资讯",
    Socio: "合作伙伴",
    About: "关于我们",
    aboutwithdrawal: "关于提现",
  },
  about: {
    title: "关于我们",
    guanyutixian: "关于提现",
  },
  Recargar: {
    title: "充值",
    Introducir: "请输入充值金额",
    tps: "最低充值金额",
    Selección: "快速选择金额",
    text: "提示：银行平台账户随时变更。对于每笔存款，请前往您填写信息的页面，注明最新的帐号。如果您存入已过期的账户，我们概不负责！",
  },
  RecargarAction: {
    title: "提交充值",
    Cantidad: "充值金额",
    Información: "收款人信息",
    tps: "转账完成后，请务必先上传转账截图，然后点击提交按钮！",
    jietu: "上传转账截图",
    querenchongzhi: "确认充值",
  },
  Recolectar: {
    title: "收集订单",
    Pedidos: "订单佣金",
    yongjin: "佣金",
    jinrishouru: "今日收入",
    gerenshouru: "个人收入",
    yiwancheng: "已完成订单",
    dongjie: "冻结订单",
    weiwancheng: "未完成订单",
    dongjiejine: "冻结金额",
    dangqianyue: "当前余额",
    kaishi: "开始收集",
    tingzhi: "停止收集",
    tishi: "提示",
    Segundo: "秒",
    text: "用户完成订单后，平台会同时将订单信息发送给卖家。如果用户在一定时间内不支付订单，订单将被冻结，以规避网购平台的监管。一旦订单被冻结，订单中的钱也被冻结。您必须等待系统在数小时后解冻它们。请记住这一点。",
  },
  Registro: {
    title: "记录",
    daizhifu: "待支付",
    yijiesuan: "已结算",
    chulizhong: "处理中",
    yidongjie: "已冻结",
    meiyoujilu: "没有记录",
    xianzaishouji: "现在收集",
    jiage: "价格",
    yongjin: "佣金",
    shouyi: "收益",
    shengyutime: "剩余时间",
    chakan: "查看订单",
    weizhifu: "未支付",
    fukuan: "付款",
  },
  Mex: "",
  Confirmar: "确认",
  Conectando: "加载中",
  fuzhiOK: "已复制到粘贴板",
  Detalles: {
    title: "订单详情",
    yigong: "一共有 ",
    dingdan: " 个订单",
    dangqian: "当前为第",
    yiwancheng: "订单已完成",
    dongjie: "订单已冻结",
    dengdai: "等待买方确认订单",
    dongjies: "冻结",
    zhuyao: "主要商店",
    guanfang: "官方担保",
    zongshuliang: "总数量",
    daigou: "代购费",
    shouyi: "收益",
    fukuanzt: "付款状态",
    weizhifu: "未支付",
    yizhifu: "已支付",
    jiedong: "解冻时间",
    zhifu: "支付",
    shuruzhifu: "请输入支付密码",
    yanzheng: "验证支付密码",
  },
  Pofile: {
    title: "账户详情",
    yaoqingma: "邀请码",
    fuzhi: "复制链接",
    Balance: "资金",
    tixian: "提现",
    chongzhi: "充值",
    zongji: "总计",
    jinrong: "金融",
    xiangqing: "详情",
    dongjie: "冻结",
    geren: "个人",
    tuandui: "团队",
    yaoqing: "邀请",
    xiaoxi: "客服",
    dizhi: "地址",
    guanyu: "关于我们",
    renwu: "邀请任务",
    anquan: "安全",
    fuwu: "服务中心",
  },
  alert: {
    title: "提示",
    quxiao: "取消",
    queding: "确定",
    acuerdo: "同意",
    fuzhi: "复制",
    qianwang: "前往",
  },
  withdrawal: {
    title: "提现",
    yue: "当前余额",
    shouxufei: "提现手续费",
    dancitixian: "单次提现最低手续费",
    shuru: "输入金额",
    dangqianjine: "当前账户提现金额",
    suoyou: "提取所有",
    zhanghuming: "账户姓名",
    zhanghu: "账户",
    dianhua: "电话号码",
    tps: "提示",
    text: "1. 请准确填写银行信息，我们对您在输入信息时可能出现的错误导致资金损失不承担任何责任；次日2点24分前，具体到账时间以银行为准",
    queren: "确认提现",
    guanlian: "请在提款前关联您的银行卡",
  },
  prsonal: {
    title: "个人",
    name: "姓名",
    shouji: "电话号码",
    yhk: "银行卡",
    tuichu: "退出登录",
    tianxie: "填写",
  },
  card: {
    title: "银行卡设置",
    zhanghuxinxi: "账户信息",
    tps: "请确保您的帐号正确无误，我们对任何错误概不负责。",
    yhmc: "银行名称",
    zhanghuming: "用户名",
    zhanghu: "账户",
    shouji: "电话号码",
    fenhang: "银行分行名称",
    baocun: "保存",
    jianyi: "建议",
    text: "银行卡绑定方法请联系在线客服，以防需要修改！",
    qyh: "请选择",
    qsryhm: "请输入银行用户名",
    qzh: "请输入账户",
    qdh: "请输入电话",
    qfhmc: "请输入分行名称",
    qsrifcs:'请输入IFSC',
    youxiang:'邮箱',
    plyx:'请输入邮箱',
    jgbh:'机构编号',
    pljgbh:'请输入机构编号',
    gjj:'公积金',
    plgjj:'请输入',
    isbp:"ISBP",
    plisbp:"请输入ISBP",
    pixtype:'PIXType',
    plpixtype:'请输入PIXType',
    pix:'PIX',
    plpix:'请输入PIX',
    evp:'EVP',
    plevp:'请输入EVP',
  },
  team: {
    title: "团队",
    Directo: "直接的",
    Secundario: "中学",
    Terciario: "第三",
    Comisión: "今天的代购费",
    zongyongjin: "总佣金",
    shuzi: "数字",
    sudu: "速度",
    jinricz: "今日充值",
    tixian: "完全提现",
  },
  invitation: {
    title: "邀请朋友",
    notitle: "您可以通过手机做兼职工作",
    notitles: "邀请朋友一起赚更多的钱",
    yqm: "邀请码",
    fuzhi: "复制",
    xzyq: "现在邀请",
  },
  message: {
    title: "客服",
    go: "联系",
  },
  address: {
    title: "地址管理",
    xm: "姓名",
    dianhua: "电话",
    qtx: "请填写",
    dizhixq: "收货地址信息",
    baocun: "保存",
  },
  invitationTask: {
    title: "邀请任务",
    zongren: "总人数",
    gengxin: "更新任务：已重载的用户为有效用户",
    yq: "邀请",
    jige: "个有效会员",
    qianwang: "前往",
    renwujiangli: "任务奖励",
  },
  safe: {
    aqgl: "安全管理",
    denglu: "登陆密码",
    zfmm: "支付密码",
    bh: "保护",
  },
  password: {
    title: "登录密码",
    titles: "支付密码",
    shouji: "电话号码",
    yanzheng: "验证码",
    fasong: "发送",
    xinmima: "新密码",
    pxinmima: "输入新密码",
  },
  record: {
    title: "详情",
    quanbu: "全部",
    chongzhi: "充值",
    tixian: "提现",
    dingdan: "订单",
    tuandui: "团队",
    zongsu: "总数",
    dangqian: "当前数据",
    kaishisj: "开始时间",
    jieshusj: "结束时间",
  },
  login: {
    zhanghu: "请输入账户",
    mima: "请输入密码",
    denglu: "登录",
    zhuce: "现在注册",
    wjmm: "我忘记密码了",
  },
  regist: {
    touxiang: "头像",
    yhm: "用户名",
    mima: "密码",
    zhifu: "支付密码",
    yq: "邀请",
    pyhm: "请填入一个用户名",
    ptel: "请填入电话号码",
    shuru: "请输入密码",
    shurupay: "输入 6 位安全码",
    pyqm: "邀请码",
    plyqm:'请输入邀请码',
    zhuce: "注册",
    yijing: "已经有一个账户了？",
  },
  tabbars: {
    shouye: "首页",
    chongzhi: "充值",
    shouji: "收集",
    dingdan: "订单",
    wode: "我的",
  },
  zhaohuimima: "请联系客服进行处理",
  yanzhengzhifu:'验证支付密码',
  huanying:'欢迎',
  yinhang:'银行',
  yinhangbh:'银行编号',
  beizhu:'备注',
  zhifufs:'支付方式',
  qxzzffs:'请选择支付方式',
};
export default zh;
