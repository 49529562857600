const pt = {
    home: {
      title: "Mercado de compradores",
      chongzhi: "Recarregar",
      tixian: "Saque",
      yaoqing: "Convite",
      Aviso: "Dicas",
      Balance: "Capital",
      Hoy: "Hoje",
      Financiero: "Financeiro", 
      Congelar: "Congelado",
      MiembroNoticias: "Informações do membro",
      Socio: "Cooperação",
      About: "Sobre Nós",
      aboutwithdrawal: "Sobre saque",
    },
    about: {
      title: "Sobre Nós",
      guanyutixian: "Sobre saque",
    },
    Recargar: {
      title: "Recarregar",
      Introducir: "Por favor, insira o valor da recarga",
      tps: "Valor mínimo de recarga",
      Selección: "Selecione rapidamente o valor",
      text: "Dica: A conta bancária da plataforma muda a qualquer momento. Para cada depósito, acesse a página onde preenche as informações e indique o número da conta mais recente. Se depositar em uma conta expirada, não nos responsabilizamos!",
    },
    RecargarAction: {
      title: "Enviar recarga",
      Cantidad: "Valor da recarga",
      Información: "Informações do beneficiário",
      tps: "Não é necessário modificar nenhuma informação nesta interface, serve apenas para exibir as informações do beneficiário.",
      jietu: "Enviar comprovante de pagamento",
      querenchongzhi: "Confirmar recarga",
    },
    Recolectar: {
      title: "Coletar pedidos",
      Pedidos: "Comissão do Pedido",
      yongjin: "comissão", 
      jinrishouru: "Receita hoje",
      gerenshouru: "Receita pessoal",
      yiwancheng: "Pedido concluído",
      dongjie: "Pedido congelado",
      weiwancheng: "Pedidos pendentes",
      dongjiejine: "Valor congelado",
      dangqianyue: "Saldo atual",
      kaishi: "Começar coleta",
      tingzhi: "Parar coleta", 
      tishi: "Dicas",
      Segundo: "Segundo",
      text: "Após o usuário concluir o pedido, a plataforma enviará as informações do pedido ao vendedor ao mesmo tempo. Se o usuário não pagar o pedido dentro de um determinado período, o pedido será congelado para evitar a supervisão da plataforma de compras online. Quando o pedido é congelado, o dinheiro do pedido também é congelado.",
    },
    Registro: {
      title: "Registro",  
      daizhifu: "A ser pago",
      yijiesuan: "Liquidado",
      chulizhong: "Processando",
      yidongjie: "Congelado",
      meiyoujilu: "Nenhum registro", 
      xianzaishouji: "Coletar agora",
      jiage: "Preço",
      yongjin: "Comissão",
      shouyi: "Lucro", 
      shengyutime: "Tempo Restante",
      chakan: "Ver pedido",
      weizhifu: "Não pago",
      fukuan: "Pagamento",
    },
    Mex: "",
    Confirmar: "Confirmar",
    Conectando: "Carregando",
    fuzhiOK: "Copiado para a área de transferência",
    Detalles: {  
      title: "Detalhes do pedido",
      yigong: " Total",
      dingdan: " Pedidos",
      dangqian: "Atualmente segundo",
      yiwancheng: "Pedido concluído",
      dongjie: "Pedido congelado",
      dengdai: "Por favor, complete o pedido dentro de uma hora, tempo restante: ",
      dongjies: "congelado",
      zhuyao: "Loja Principal",
      guanfang: "Garantia oficial",
      zongshuliang: "Quantidade total", 
      daigou: "Taxa de compra",
      shouyi: "Lucro",
      fukuanzt: "Status do pagamento",
      weizhifu: "Não pago",
      jiedong: "Tempo de descongelamento",
      zhifu: "Pagamento",
      yizhifu: "Pago",
      shuruzhifu: "Por favor, digite a senha de pagamento",
      yanzheng: "Verifique a senha de pagamento",
    },  
    Pofile: {
      title: "detalhes da conta",
      yaoqingma: "Código de convite",
      fuzhi: "Copiar link", 
      Balance: "Capital",
      tixian: "Saque",
      chongzhi: "Recarregar", 
      zongji: "Total",
      jinrong: "Financeiro",
      xiangqing: "Detalhes",
      dongjie: "Congelado",
      geren: "Pessoal",
      tuandui: "Equipe",
      yaoqing: "Convite",
      xiaoxi: "Mensagem",
      dizhi: "Endereço",
      guanyu: "Sobre Nós",
      renwu: "Tarefas de convite",
      anquan: "Segurança",
      fuwu: "Central de Atendimento",
    },
    alert: {
      title: "Dicas",
      quxiao: "Cancelar",
      queding: "OK",
      acuerdo: "Concordo",
      fuzhi: "Copiar",
      qianwang: "IR",
    },
    withdrawal: {
      title: "Saque",
      yue: "Saldo atual",
      shouxufei: "Taxa de saque",
      dancitixian: "Mínimo para um único saque",
      shuru: "Por favor, insira o valor", 
      dangqianjine: "Valor do saque da conta atual",
      suoyou: "Extrair tudo",
      zhanghuming: "Nome da conta",
      zhanghu: "Conta",
      dianhua: "Número de telefone",
      tps: "Dicas",
      text: "1.Por favor, preencha as informações bancárias com precisão. Não nos responsabilizamos por qualquer perda de capital causada por possíveis erros ao inserir as informações; Antes das 2:24h do dia seguinte, o horário específico de chegada estará sujeito ao banco", 
      queren: "Confirmar saque",
      guanlian: "Por favor, associe sua Conta Bancária antes do saque",
    },
    prsonal: {
      title: "Pessoal",
      name: "Nome",
      shouji: "Número de telefone", 
      yhk: "Conta Bancária",
      tuichu: "Sair",
      tianxie: "Preencher",
    },
    card: {  
      title: "Configurações da Conta Bancária",
      zhanghuxinxi: "Informações da conta",
      tps: "Por favor, certifique-se de que o número da sua conta está correto. Não nos responsabilizamos por qualquer erro.",
      yhmc: "Banco",
      zhanghuming: "Nome",
      zhanghu: "IBAN",
      shouji: "Número de telefone",
      fenhang: "Nome da Agência",
      baocun: "Salvar",
      jianyi: "Proposta",
      text: "Entre em contato com o atendimento ao cliente online para obter informações sobre como vincular a Conta Bancária caso precise modificar!",
      qyh: "Por favor, selecione um",
      qsryhm: "Por favor, insira seu nome", 
      qzh: "Por favor, insira o número da sua conta bancária",
      qdh: "Por favor, insira o número de telefone",
      qfhmc: "Por favor, insira o nome da agência",
      qsrifcs: "Por favor, insira o IFSC",
      youxiang: "E-mail",
      plyx: "Por favor, insira o e-mail",
      jgbh: "Número da organização",
      pljgbh: "Por favor, insira o número da organização",
      gjj: "CPF",
      plgjj: "Por favor, insira",
      isbp: "ISBP",
      plisbp: "Por favor, insira o ISBP",
      pixtype: "PIXType",
      plpixtype: "Por favor, insira o PIXType",
      pix: "PIX",
      plpix: "Por favor, insira o PIX",
      evp: "EVP",
      plevp: "Por favor, insira o EVP",
    },
    team: {
      title: "Equipe",
      Directo: "Direto",
      Secundario: "Secundário",
      Terciario: "Terciário",
      Comisión: "Comissão de hoje",
      zongyongjin: "Comissão total",
      shuzi: "Número",
      sudu: "Velocidade",
      jinricz: "Recarga hoje",
      tixian: "Saque completo",
    },
    invitation: {
      title: "Convidar amigos",
      notitle: "Você pode trabalhar em tempo parcial pelo seu celular",
      notitles: "Convide amigos para ganhar mais dinheiro juntos",
      yqm: "Código de convite",
      fuzhi: "Copiar",
      xzyq: "Convide agora", 
    },
    message: {
      title: "Serviço ao Cliente",
      go: "Contato",
    },
    address: {  
      title: "Gerenciamento de Endereço",
      xm: "Nome completo",
      dianhua: "Telefone",
      qtx: "Por favor, preencha", 
      dizhixq: "Informações do endereço de recebimento",
      baocun: "Salvar", 
    },
    invitationTask: {
      title: "Tarefas de convite",
      zongren: "Número total",
      gengxin: "Atualizar tarefa: O usuário sobrecarregado é um usuário válido",
      yq: "convite",
      jige: "Membros válidos",
      qianwang: "IR",
      renwujiangli: "Recompensa da tarefa",
    },
    safe: {
      aqgl: "Gestão de Segurança",
      denglu: "Senha de login",
      zfmm: "Senha de pagamento", 
      bh: "Proteger",
    },
    password: {
      title: "Senha de login",
      titles: "Senha de pagamento",
      shouji: "Número de telefone",
      yanzheng: "Código de Verificação",
      fasong: "Enviar",
      xinmima: "Nova senha",
      pxinmima: "Digite a nova senha",
    },
    record: {
      title: "Detalhes",
      quanbu: "Tudo",
      chongzhi: "Recarregar",
      tixian: "Saque",
      dingdan: "Pedido",
      tuandui: "Equipe",
      zongsu: "Total",
      dangqian: "Dados atuais",
      kaishisj: "Horário de início",
      jieshusj: "Horário de término",
    },
    login: {
      zhanghu: "Por favor, insira seu nome de usuário",
      mima: "Por favor, digite a senha",
      denglu: "Entrar",
      zhuce: "Registre-se agora",
      wjmm: "Esqueci minha senha",
    },
    regist: { 
      touxiang: "Avatar",
      yhm: "Nome de usuário",
      mima: "Senha",
      zhifu: "Senha de Pagamento", 
      yq: "convite",
      pyhm: "Por favor, preencha um nome de usuário",
      ptel: "Por favor, preencha o número de telefone", 
      shuru: "Por favor, digite a senha",
      shurupay: "Digite 6 dígitos",
      pyqm: "Código de convite",
      zhuce: "Registrar",
      yijing: "Já tenho uma conta?", 
      plyqm: "Por favor, insira o código de convite",
    },
    tabbars: {
      shouye: "Início",
      chongzhi: "Recarregar",
      shouji: "Coletar",
      dingdan: "Pedido",
      wode: "Meu",
    },
  
    zhaohuimima: "Entre em contato com o Atendimento ao Cliente para processamento",
    yanzhengzhifu: "Verificar senha de pagamento",
    huanying: "Bem-vindo",
    yinhang: "Banco",
    yinhangbh: "Referência",
    beizhu: "Observação", 
    zhifufs: "Método de pagamento",
    qxzzffs: "Selecione o método de pagamento",
    usdt: {
      title: "Canal de recarga USDT (TRC20)",
      tips1: "Selecione o valor da recarga",
      rates: "Taxas de câmbio",
      cashTips: "Você selecionou um valor de recarga de",
      usdtTips: "Você deve usar USDT (TRC20) para pagar", 
      customAmount: "Você pode inserir o valor diretamente",
      importantTips: {
        one: "1. Faça a transferência do mesmo valor mencionado acima para USDT",
        two: "2. Complete a transferência em até 5 minutos, caso contrário, o tempo expirará e falhará.",
        three:
          "3. Se tiver algum problema, entre em contato com nossos representantes de atendimento ao cliente para ajudá-lo a resolver.",
      },
    },
    selectChannel: {
      title: "Selecione um método de pagamento", 
      cashChannel: "Canal de pagamento em dinheiro",
      usdtChannel: "Canal de pagamento USDT (TRC20)", 
      rewards: "Bônus adicional de 5% em dinheiro",
    },
  };
  export default pt;