import request from "@/utils/request";
export default {
  siteInfo() {
    return request.post("/user/home/siteInfo", {});
  },
  fileStream(fileStream) {
    return request.post("/user/upload/smallFileUpload", fileStream, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getUploadUrl() {
    return request.post("/user/upload/getUploadUrl", {});
  },
};
