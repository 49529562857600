<template>
  <div class="flex flex-col p-3 ">
    <div class="mb-5 flex justify-between items-center">
      <div class="flex space-x-5">
        <div class="rounded-full bg-b-2 w-11 h-11 flex justify-center items-center drop-shadow-md">
          <span class="material-symbols-outlined text-p-1"> face_6 </span>
        </div>
        <div class="text-sm flex flex-col items-left justify-center">
          <div class="font-bold">{{ nickname }}</div>
          <div class="text-xs text-t-2">ID: {{ currentUserId }}</div>
        </div>
      </div>
      <div>
        <div class="w-10 h-10 rounded-full bg-b-2 flex justify-center items-center shadow">
          <span class="material-symbols-outlined text-t-2">
            support_agent
          </span>
        </div>
      </div>
    </div>
    <!-- account -->
    <div class="mb-5 flex justify-between items-center">
      <div>
        <div class="text-sm">{{ $t('home.Balance') }}</div>
        <div class="text-3xl space-x-1">
          <span class="text-base font-light">{{ $t('Mex') }}{{ monetary_unit }}</span>
          <span class="">{{ UserInfo ? UserInfo.money : 0 }}</span>
        </div>
        <!-- <div class="text-t-2">account</div> -->
      </div>
      <div>
        <button class="text-sm px-5 py-1 text-p-3 bg-p-1 rounded-md" @click="handleJumpTo('/withdrawal')">
          {{ $t("home.tixian") }}
        </button>
      </div>
    </div>
    <!-- 快捷导航 -->
    <div class="grid grid-cols-4 items-center justify-items-center text-center mb-5 shadow py-2 rounded-md">
      <div class="space-y-1" @click="handleJumpTo('/selectChannel')">
        <div class="w-11 h-11 flex justify-center items-center rounded-full bg-p-1 drop-shadow-md">
          <span class="material-symbols-outlined text-p-3"> credit_score </span>
        </div>
        <p class="text-t-2 text-sm">{{ $t("home.chongzhi") }}</p>
      </div>
      <div class="space-y-1" @click="handleJumpTo('/recolectar')">
        <div class="w-11 h-11 flex justify-center items-center rounded-full bg-p-1 drop-shadow-md">
          <span class="material-symbols-outlined text-p-3"> insights </span>
        </div>
        <p class="text-t-2 text-sm">{{ $t("tabbars.shouji") }}</p>
      </div>
      <div class="space-y-1" @click="handleJumpTo('/registro')">
        <div class="w-11 h-11 flex justify-center items-center rounded-full bg-p-1 drop-shadow-md">
          <span class="material-symbols-outlined text-p-3"> list_alt </span>
        </div>
        <p class="text-t-2 text-sm">{{ $t("tabbars.dingdan") }}</p>
      </div>
      <div class="space-y-1" @click="handleJumpTo('/pofile')">
        <div class="w-11 h-11 flex justify-center items-center rounded-full bg-p-1 drop-shadow-md">
          <span class="material-symbols-outlined text-p-3"> steppers </span>
        </div>
        <p class="text-t-2 text-sm">{{ $t("prsonal.title") }}</p>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="px-1 py-2 mb-5 rounded-md bg-b-2 shadow">
      <van-swipe class="rounded-md" :autoplay="3000" indicator-color="white" lazy-load>
        <van-swipe-item class="flex items-center justify-between" v-for="(item, index) in bannerImg" :key="index">
          <van-image lazy-load :src="item.img" class="h-20 w-full rounded-md" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 快捷任务 -->
    <!-- <div class="flex items-center justify-between p-3 mb-5 rounded-md bg-b-2 shadow">
        <div>
          <div class="font-bold">快速抢单</div>
          <div class="text-xs text-t-2">快速抓取高佣订单</div>
        </div>
        <div class="  ">
          <div class="flex justify-center items-center">
            <span class="material-symbols-outlined text-p-1 text-3xl drop-shadow-md">
              savings
            </span>
          </div>
        </div>
      </div> -->
    <!-- 订单展示列表 -->
    <div class="flex h-96 overflow-hidden shadow rounded-md p-2">
      <member />
    </div>

    <div class="p-2 mt-5 mb-5 rounded-md bg-b-2 ">
      <p class="text-center py-2 text-sm">{{ $t("home.Socio") }}</p>
      <van-grid :border="false" :column-num="4" gutter="5">
        <van-grid-item v-for="(item, index) in partner" :key="index">
          <van-image class="h-14" :src="item.img" />
        </van-grid-item>
      </van-grid>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
// import Earnings from "@/components/home/earnings.vue";
// import Swipe from "@/components/home/swipe.vue";
import Member from "@/components/home/member.vue";
import Tabbar from "@/components/tabbar/tabbar.vue";
import userApi from "@/api/user";
import storeAction from "@/store/typed-actions";
import { jumpTo } from "@/utils/routerUtils";
export default {
  name: "Home",
  components: {
    // Earnings,
    // Swipe,
    Member,
    Tabbar,
  },
  data() {
    return {
      UserInfo: null,
      bgColor: "",
    };
  },
  computed: {
    bannerImg() {
      return this.$store.state.bannerImg;
    },
    nickname() {
      console.log(this.$store.state.nickname);
      return this.$store.state.nickname;
    },
    currentUserId() {
      console.log(this.$store.state.currentUserId);
      return this.$store.state.currentUserId;
    },
    currentUser() {
      console.log(this.$store.state.currentUser);
      return JSON.parse(this.$store.state.currentUser);
    },
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
    aboutTxt() {
      return this.$store.state.aboutTxt;
    },
    about_withdraw() {
      return this.$store.state.about_withdraw;
    },
    title() {
      return this.$store.state.homeTitle;
    },
    partner() {
      return this.$store.state.partner;
    },
  },
  methods: {
    handleJumpTo(path) {
      jumpTo(path);
    },

    JumpAbout(e) {
      console.log(e);
      this.$router.push({
        path: "/about",
        query: {
          type: e,
          contant:
            e == 0
              ? JSON.stringify(this.aboutTxt)
              : JSON.stringify(this.about_withdraw),
        },
      });
    },
    JumpRecarga() {
      this.$router.push({
        path: "/selectChannel",
      });
    },
    JumpWithdrawal() {
      this.$router.push({
        path: "/withdrawal",
      });
    },
    JumpInvitationTask() {
      this.$router.push({
        path: "/invitationTask",
      });
    },
    userinfo() {
      userApi
        .userInfo()
        .then((data) => {
          this.UserInfo = data.data;
          storeAction.setCurrentUser(JSON.stringify(data.data));
          storeAction.setsessionStorage("userinfo", JSON.stringify(data.data));
          // let navbar = document.getElementsByClassName("navbarBg")[0];
          // if (!data.data.level_font) {
          //   let bg =
          //     "background:linear-gradient(to right," +
          //     data.data.level_font +
          //     ")!important";
          //   if (!this.bgColor) {
          //     navbar.setAttribute("style", bg);
          //   }
          //   // navbar.setAttribute("style", bg);
          //   localStorage.setItem("bgColor", bg);
          // } else {
          //   let bg =
          //     "background:linear-gradient(to right, rgb(16, 185, 129), rgb(52, 211, 153))!important";
          //   // navbar.setAttribute("style", bg);
          //   if (!this.bgColor) {
          //     navbar.setAttribute("style", bg);
          //   }
          //   localStorage.setItem("bgColor", bg);
          // }
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
  created() {
    this.$toast.loading({
      message: this.$t("Conectando"),
      forbidClick: true,
      duration: 0,
    });
    this.bgColor = localStorage.getItem("bgColor");
    this.userinfo();
  },
};
</script>
<style lang="less">
.navbarBg {}
</style>
<style lang="less" scoped>
.home {
  background: #f7f8fc;
  font-size: 13.88px;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.columnlist {
  opacity: 0.96;
  height: 79.88px;
  margin: 0.13rem 0.27rem 0;
  border-radius: 0.27rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 13.88px;
}

.columnlist-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 33%;
  align-items: center;

  img {
    width: 40.13px;
    height: 40.13px;
  }
}

.notice {
  display: flex;
  background: #fff;
  margin: 4.88px auto 4.88px;
  padding: 0 10.13px;
  align-items: center;
  border-bottom: 0.03rem solid #eeeff2;
}

.notice-image {
  padding: 0 4.88px;
  height: 30px;
  text-align: center;
  font-weight: 500;
  font-size: 16.13px;
  line-height: 30px;
  background-repeat: no-repeat;
  position: relative;
}

.notice-text {
  flex: 1;
}

.contentValidity {
  padding: 0.21rem 0.21rem 0.08rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contentValidity-text {
  width: calc(50% - 0.27rem);
  text-align: center;
  background-color: #fff;
  border-radius: 0.11rem;
  margin-bottom: 0.16rem;
}

.content-img {
  margin: 0.32rem auto;
  background: url(../assets/image/about1.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  width: 2.4rem;
  height: 2.4rem;
}

.content-text {
  font-size: 0.37rem;
  color: #333;
  font-weight: 700;
  padding-bottom: 0.32rem;
}

.contentValidity-text:nth-child(2) .content-img {
  background-image: url(../assets/image/about2.png);
}

.partner {
  background: #fff;
  padding: 0.13rem 0.27rem 0;
}

.title {
  text-align: right;
  font-size: 0.43rem;
  font-weight: 700;

  border-bottom: 1px solid #eeeff2;
}

.partnerlist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0.27rem 0;

  img {
    box-sizing: border-box;
    width: 33.33%;
    padding: 0 0.13rem;
    height: 1.81rem;
    border-radius: 5px;
    margin-bottom: 0.27rem;
  }
}
</style>