<template>
  <div class="invitationTask">
    <header>
      <van-nav-bar :title="$t('invitationTask.title')" :style="bgColor">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>
    <div class="main">
      <div class="miss">
        <div class="no1">{{ listINfo.count ? listINfo.count : "0" }}</div>
        <div class="no2">{{$t('invitationTask.zongren')}}</div>
        <div class="no3"></div>
        <div class="no4">
      {{$t('invitationTask.gengxin')}}
        </div>
      </div>
      <van-pull-refresh
        v-model="isLoading"
        :head-height="80"
        @refresh="onRefresh"
        class="mescroll-wrap"
         v-if="listINfo"
      >
        <div class="missList">
          <div class="missList-item">
            <div class="data1">
              <div class="data_1 w1">
                <p class="text">
                  <span>1.</span>
                  <span class="reward"> {{$t('invitationTask.renwujiangli')}}</span>
                  <span class="moneySymbol"> {{$t('Mex')}}{{monetary_unit}}{{ listINfo.profit }}</span>
                </p>
                <p class="text2">
                   {{$t('invitationTask.yq')}} {{ listINfo.target }} {{$t('invitationTask.jige')}}
                  {{ listINfo.count ? listINfo.count : "0" }}/{{
                    listINfo.target
                  }}
                </p>
              </div>
              <div class="data_1 w3" @click="JumpInvitation">{{$t('invitationTask.qianwang')}}</div>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import orderApi from "@/api/order";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "InvitationTask",
  components: { Tabbar },
  data() {
    return {
      isLoading: false,
      listINfo: "",
      bgColor:''
    };
  },
    computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.count++;
      }, 1000);
    },
    JumpInvitation() {
      this.$router.push({
        path: "/invitation",
      });
    },
    list() {
      this.$toast.loading({
      message: this.$t('Conectando'),
        forbidClick: true,
        duration: 0,
      });
      orderApi
        .Info()
        .then((data) => {
          console.log(data.data.userInviteTask, "数据");
          if (data.data.userInviteTask != null) {
            this.listINfo = data.data.userInviteTask;
          } else {
            if( data.data.InviteList.length!=[]){
            this.listINfo = data.data.InviteList[0];
            this.getTask(data.data.InviteList[0].id);
            }

          }
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    getTask(id) {
      this.$toast.loading({
      message: this.$t('Conectando'),
        forbidClick: true,
        duration: 0,
      });
      orderApi
        .getTask(id)
        .then((data) => {
          console.log(data.data, "自动领取");
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
  created() {
         this.bgColor = localStorage.getItem("bgColor");
    this.list();
  },
};
</script>
<style lang="less" scoped>
.invitationTask {
  background: #fff;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
.miss {
  background: linear-gradient(270deg, #747e95, #444d68);
  border-radius: 0.43rem 0.43rem 0 0;
  margin: 0.32rem 0.53rem 0.51rem;
  text-align: center;
  font-weight: 400;
  font-family: Microsoft YaHei;
  overflow: hidden;
}
.no1 {
  text-align: center;
  margin: 0.64rem auto 0.37rem;
  font-size: 0.96rem;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #fff;
}
.no2 {
  font-size: 0.37rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  opacity: 0.8;
  margin-bottom: 0.43rem;
}
.no3 {
  height: 1px;
  background: #fff;
  opacity: 0.4;
  margin: 0 0.53rem;
}
.no4 {
  margin: 0.27rem;
  color: #fff;
  opacity: 0.8;
  text-align: center;
}
.mescroll-wrap {
  height: calc(100vh - 209px);
  box-sizing: border-box;
}
.scroll-view {
  width: 100%;
  height: 100%;
}
.missList {
  margin-top: 0.27rem;
  padding: 0 0.53rem;
}
.missList-item {
  position: relative;
  height: 2rem;
  background-color: #fff;
  border-bottom: 0.5px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.missList-item .data1 .data_1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.text {
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23292f;
}
.moneySymbol {
  margin-left: 0.8rem;
  display: inline-lock;
  color: #6dcab8;
}
.text2 {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a7aab6;
  line-height: 0.53rem;
}
.data1 .w3 {
  font-size: 0.35rem;
  width: 1.71rem;
  height: 0.69rem;
  line-height: 0.69rem;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  border-radius: 13px;
  align-items: center !important;
  color: #fff;
}
</style>