<template>
  <div class="p-3">
    <div class="flex bg-b-2  rounded-md justify-between px-3  text-t-2 text-sm mb-3">
      <div v-for="(tab, index) in tabs" :key="index" :class="['py-3', { 'text-p-1': activeTab === index }]"
        @click="changeTab(index)">
        {{ $t(tab) }}
      </div>
    </div>

    <div class=" text-t-2">
      <ul class="space-y-3">
        <template v-for="(item, index) in ListOne" :key="index">
          <li v-for="(itemGoods, indexs) in item.goods" :key="indexs" @click="JumpOrderDeatil(item, itemGoods)"
            class="rounded-md bg-b-2 p-3">
            <div class="flex flex-col space-y-2 ">
              <div class="flex justify-between text-xs">
                <div>{{ itemGoods.created_at }}</div>
                <div>NO.{{ item.orderNo }}</div>
              </div>
              <div class="flex text-sm space-x-2">
                <div class="flex-1 flex p-2 bg-white rounded-md items-center justify-center">
                  <img class="w-full h-14" :src="itemGoods.goods_pic" alt="">
                </div>
                <div class="flex-auto flex flex-col  text-xs font-normal items-end space-y-1">
                  <div>{{ $t("Registro.jiage") }}: {{ $t("Mex") }}{{ monetary_unit }}{{ itemGoods.quantity }}</div>
                  <div>{{ $t("Registro.yongjin") }}: {{ itemGoods.profit_percent }}%</div>
                  <div>{{ $t("Registro.shouyi") }}: <span class="text-a-1 font-bold">+{{ itemGoods.profit }}</span></div>
                  <div class="flex flex-nowrap space-x-1"><span>{{ $t("Registro.shengyutime") }}:
                    </span><span><van-count-down :time="time(itemGoods.created_at)" /></span>
                  </div>
                </div>
                <!-- <div class="flex-1 flex flex-col justify-end">
                  <span class="text-a-1 bg-white rounded-md  px-2 text-center items-center text-xs">
                    +{{ itemGoods.profit }}
                  </span>
                </div> -->
              </div>
            </div>
          </li>
        </template>

      </ul>
    </div>
    <tabbar />
  </div>
</template>
<script>
// @ is an alias to /src
import orderApi from "@/api/order";
import Tabbar from "@/components/tabbar/tabbar.vue";
import times from "@/utils/time";
export default {
  name: "Registro",
  components: {
    Tabbar,
  },
  data() {
    return {
      page: 1,
      limit: 10,
      active: 0,
      ListOne: [],
      status: 0,
      loading: true,
      finished: false,
      ispage: false,
      url: require("@/assets/image/empty.png"),
      timeNull: 8553600 * 1000,
      tabAlert: false,
      megTime: "",
      bgColor: "",
      tabs: ['Registro.daizhifu', 'Registro.yijiesuan', 'Registro.chulizhong', 'Registro.yidongjie'],
      activeTab: 0,
      lists: [
        ['Item 1', 'Item 2', 'Item 3'],
        ['Item A', 'Item B', 'Item C'],
        ['Item X', 'Item Y', 'Item Z'],
        ['Item Apple', 'Item Banana', 'Item Orange'],
      ],
    };
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
    out_time_pay() {
      console.log(this.$store.state.out_time_pay);
      return this.$store.state.out_time_pay;
    },
    action_time() {
      console.log(this.$store.state.action_time);
      return this.$store.state.action_time;
    },
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
      this.Tabswipe(index);
    },

    Tabswipe(e) {
      console.log(this.tabAlert, "change");
      if (this.tabAlert) {
        return;
      }
      if (e == 0) {
        this.status = 0;
      } else if (e == 1) {
        this.status = 2;
      } else if (e == 2) {
        this.status = 1;
      } else if (e == 3) {
        this.status = 3;
      }
      this.loading = true;
      this.page = 1;
      this.ListOne = [];
      // this.finished = false;
      this.ispage = false;
      this.list();
    },
    JumpRecolectar() {
      this.$router.push({
        path: "/recolectar",
      });
    },
    onLoad() {
      this.page++;
      this.loading = true;
      this.list();
    },
    JumpOrderDeatil(item, goods) {
      goods["goodsNum"] = item.total_goods_count;
      console.log(item, "1122");
      if (item.address_name) {
        goods["address"] = {
          address_mark: item.address_mark,
          address_name: item.address_name,
          address_mobile: item.address_mobile,
        };
      } else {
        goods["address"] = "";
      }
      this.$router.push({
        path: "/orderDetail",
        query: {
          goods: JSON.stringify(goods),
        },
      });
    },
    JumpGo() {
      this.$router.push({
        path: "/",
      });
    },
    time_to_sec(time) {
      if (time !== null) {
        let s = "";
        let hour = time.split(":")[0];
        let min = time.split(":")[1];
        let sec = time.split(":")[2];
        s = Number(hour * 3600) + Number(min * 60) + Number(sec);
        return s;
      }
    },
    // 未支付
    list() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      orderApi
        .list(this.page, this.limit, this.status)
        .then((data) => {
          console.log(data.data, "订单数据");
          if (this.ispage) {
            this.ListOne = data.data;
          } else {
            // this.ListOne.push(...data.data);
            for (let i = 0; i < data.data.length; i++) {
              this.ListOne.push(data.data[i]);
            }
          }

          if (data.data.length < 10 || data.data.length == []) {
            this.finished = true;
          }
          this.loading = false;
          this.ispage = false;
          this.tabAlert = false;
          this.megTime = data.message;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    time(strTime) {
      if (this.status == 1) {
        let timeData = times.formatDate(strTime);
        // 最终时间
        timeData = this.time_to_sec(timeData) + this.action_time * 60;
        // 当前时间
        // let timeData_dang = times.formatDate(new Date());
        let timeData_dang = this.megTime;
        timeData_dang = this.time_to_sec(timeData_dang);
        //剩余时间
        let shengyu = timeData - timeData_dang;
        // let dangqianTime=new Date()
        // 后台单位是已分钟给的-自己是用秒计算
        //  time: 60 * 1000,
        return shengyu * 1000;
      } else if (this.status == 0) {
        let timeData = times.formatDate(strTime);
        // 最终时间
        timeData = this.time_to_sec(timeData) + this.out_time_pay * 60;
        // 当前时间
        // let timeData_dang = times.formatDate(new Date());
        let timeData_dang = this.megTime;
        timeData_dang = this.time_to_sec(timeData_dang);
        //剩余时间
        let shengyu = timeData - timeData_dang;
        // let dangqianTime=new Date()
        // 后台单位是已分钟给的-自己是用秒计算
        //  time: 60 * 1000,
        return shengyu * 1000;
      } else if (this.status == 3) {
        let timeData = times.dongjie(strTime);
        timeData = new Date(timeData).getTime() / 1000
        let dangqians = times.dongjiedangqian(this.megTime)
        let dangtime = new Date(dangqians).getTime() / 1000;
        let timeData_dang = dangtime;
        console.log(timeData, "冻结时间", timeData_dang, "当前时间");
        //剩余时间
        let shengyu = timeData - timeData_dang;
        // let dangqianTime=new Date()
        // 后台单位是已分钟给的-自己是用秒计算
        //  time: 60 * 1000,
        return shengyu * 1000;
      }
    },
    TabChange(e) {
      this.tabAlert = true;
      if (e.name == 0) {
        this.status = 0;
      } else if (e.name == 1) {
        this.status = 2;
      } else if (e.name == 2) {
        this.status = 1;
      } else if (e.name == 3) {
        this.status = 3;
      }
      this.loading = true;
      this.page = 1;
      this.ListOne = [];
      this.ispage = false;
      this.list();
    },
  },
  created() {
    if (this.$route.query.type == 2) {
      let type = this.$route.query.type;
      this.active = type * 1;
      this.status = 1;
      console.log(this.active);
    }
    this.bgColor = localStorage.getItem("bgColor");
    this.list();
  },
};
</script>
<style lang="less" scoped>

:deep(.van-count-down) {
  line-height: normal;
  font-size: small;
}

.registro {
  background: #f7f8fc;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.item {
  padding: 0.32rem;
  margin-top: 0.27rem;
  background-color: #fff;
}

.time {
  color: #777;
  font-size: 0.37rem;
  margin-bottom: 0.13rem;
  text-align: left;
}

.item .flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pic {
  width: 1.33rem;
  height: 1.33rem;

  img {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 5px;
  }
}

.info {
  display: block;
  margin-right: 0.53rem;
  text-align: center;
}

.orderno {
  font-weight: 700;
  margin: -0.05rem 0 0.08rem;
}

.money {
  color: #999;
}

.btn_box {
  align-self: center;
  width: 2.67rem;
  padding: 0.13rem 0.27rem;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f762a2;
  border-radius: 20px;
  color: #fff;
  font-size: 0.32rem;
}

.unPaid {
  display: flex;
  flex-direction: column;
  font-size: 0.32rem;
  align-items: center;
}

.item .flex-row .unPaid>div:nth-of-type(2) {
  color: #dd524d;
}

.btn {
  width: 2.67rem;
  padding: 0.13rem 0.27rem;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f762a2;
  border-radius: 0.53rem;
  color: #fff;
  font-size: 0.32rem;
}

.btnJump {
  color: #fff;
  width: 3.2rem;
  height: 1.07rem;
  line-height: 1.07rem;
  border-radius: 1.07rem;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  margin-top: 0.27rem;
  text-align: center;
}

.van-empty {
  box-sizing: border-box;
  padding: 1.33rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.67rem;
}
</style>