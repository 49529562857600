<template>
    <div>
        <header>
            <van-nav-bar :title="$t('usdt.title')" class="bg-b-2 text-t-1">
                <template #left>
                    <van-icon name="arrow-left" size="20" @click="JumpGo" />
                </template>
            </van-nav-bar>
        </header>


        <div class="p-2">
            <div class="text-base  text-t-2 my-2">{{ $t("usdt.tips1") }}</div>
            <div class="grid grid-cols-3  gap-1 my-2">
                <div v-for="(item, index) in payBlocks" :key="index"
                    class="py-1 text-base  rounded-md text-t-1 border-solid border-2 border-p-2 text-center"
                    :class="activityIndex == index ? 'bg-p-2 text-a-2 border-p-2' : ''"
                    @click="selectMoney(item), activityIndex = index">
                    {{ item }}
                </div>
            </div>

            <div class="flex flex-col items-center  text-base bg-a-2 p-2  space-y-2 rounded-md my-2">
                <div class="">{{ $t("usdt.customAmount") }}</div>
                <input class="border border-solid bg-white p-1 text-sm rounded-md" type="text" @input="inputFun"
                    :value="inputMoney">
            </div>

            <div class="my-1  text-t-2 text-sm ">
                <div class="space-x-1"><span>{{ $t("usdt.rates") }}</span>:1USDT={{ payUsdtRates }}{{ monetary_unit }}
                </div>
                <div class="space-x-1">
                    <span>{{ $t("usdt.cashTips") }}</span>
                    <span>{{ amountMoney }}</span>
                    <span>{{ monetary_unit }}</span>
                </div>
                <div class="space-x-1">
                    <span>{{ $t("usdt.usdtTips") }}</span>
                    <span>{{ amountUsdt }}</span>
                </div>
            </div>

            <div class="p-2 bg-a-2 rounded-md text-xs text-left space-y-1 my-3">
                <div class=" ">{{ $t("usdt.importantTips.one") }}</div>
                <div class=" ">{{ $t("usdt.importantTips.two") }}</div>
                <div class=" ">{{ $t("usdt.importantTips.three") }}</div>
            </div>

            <div class="my-2 text-center bg-p-1 p-2 rounded-md text-a-2" @click="copyAddr">
                {{ usdtAddr }}
            </div>

            <!-- 上传转账截图 -->
            <div class="p-2">
                <div class="my-2 text-t-2">
                    {{ $t("RecargarAction.tps") }}
                </div>
                <div class="flex flex-col text-sm text-t-2">
                    <div class="text-lg text-center">
                        {{ $t("RecargarAction.jietu") }}
                    </div>
                    <div class=" flex flex-col items-center justify-center my-1">
                        <van-uploader v-if="!imageUrl" :after-read="afterRead" preview-size="4rem" />
                        <img :src="imageUrl" v-else alt="" @click="getImg(imageUrl)" class="w-16 h-20" />
                    </div>
                </div>

            </div>

            <!-- 确认充值提交表单 -->
            <div class=" bg-p-1 text-center text-a-2 text-base py-2 m-2 rounded-md" @click="save">
                {{ $t("RecargarAction.querenchongzhi") }}
            </div>
            <div class="flex flex-col text-left my-2 text-red-500 space-y-1" :v-html="importantTips">

            </div>
        </div>
    </div>
</template>

<script>
import userApi from "@/api/user";
import homeApi from "@/api/home";
import { ImagePreview } from "vant";
export default {
    data() {
        return {
            bgColor: '',
            usdtAddr: '',
            payUsdtRates: '',
            payBlocks: [],
            activity: false,
            activityIndex: 0,
            inputMoney: 0,
            amountMoney: '',
            amountUsdt: '',
            imageUrl: "",
            log_id: "",
            UpUrl: "",
            monetary_unit: "",
            importantTips: this.$t("usdt.importantTips")
        }
    },
    created() {
        this.bgColor = localStorage.getItem("bgColor");
        this.usdtAddr = localStorage.getItem('pay_usdt') != '' ? localStorage.getItem('pay_usdt') : '您还没有设置USDT收款地址';
        this.payUsdtRates = localStorage.getItem('pay_usdt_rates') != '' ? localStorage.getItem('pay_usdt_rates') : '0';
        const pay_blocks_str = localStorage.getItem("pay_blocks");
        this.payBlocks = pay_blocks_str.split(',');
        this.log_id = this.$route.query.log_id;
        this.monetary_unit = this.$store.state.monetary_unit;
        this.UplodaUrl();
        this.selectMoney(this.payBlocks[0]);
    },
    methods: {
        JumpGo() {
            this.$router.go(-1);
        },
        inputFun(e) {
            const usdt = e.target.value / this.payUsdtRates
            this.inputMoney = e.target.value;
            this.amountMoney = e.target.value;
            this.amountUsdt = parseFloat(usdt).toFixed(2)
        },
        to(path) {
            this.$router.push({
                path: path,
            });
        },
        selectMoney(money) {
            this.amountMoney = money
            this.inputMoney = money
            const usdt = money / this.payUsdtRates
            this.amountUsdt = parseFloat(usdt).toFixed(2)
        },
        copyUsdtAdd() {
            console.log('copy');
        },
        getImg(img) {
            ImagePreview({
                images: [img],
                closeable: true,
            });
        },
        // 图片上传
        async afterRead(file) {
            this.$toast.loading({
                message: this.$t("Conectando"),
                forbidClick: true,
                duration: 0,
            });
            let formData = new FormData();
            console.log(file.file);
            formData.append("file", file.file);
            const uploadResult = await fetch(this.UpUrl, {
                method: "post",
                body: formData,
            });
            const result = await uploadResult.json();
            console.log(result, "result");
            if (result.result.variants[0]) {
                this.imageUrl = result.result.variants[0];
                this.$toast.clear();
            } else {
                this.$toast.clear();
                this.$toast.fail(result.errors);
            }
        },
        save() {
            let that = this;
            that.$toast.loading({
                message: this.$t("Conectando"),
                forbidClick: true,
                duration: 0,
            });
            console.log(that.log_id, "that.log_id");
            userApi
                .pay(this.amountMoney, that.imageUrl, 0)
                .then((data) => {
                    that.$toast.clear();
                    console.log(data, "充值返回");
                    that.$toast.success({
                        message: data.message,
                        forbidClick: true,
                        duration: 500,
                        onClose() {
                            that.$router.go(-1);
                        },
                    });
                })
                .catch((err) => {
                    that.$toast.clear();
                    that.$toast.fail(err.message);
                });
        },
        UplodaUrl() {
            homeApi
                .getUploadUrl()
                .then((data) => {
                    console.log(data.data, "上传地址");
                    this.UpUrl = data.data.uploadUrl;
                })
                .catch((err) => {
                    this.$toast.clear();
                    this.$toast.fail(err.message);
                });
        },
        copyAddr() {
            let transfer = document.createElement("input");
            document.body.appendChild(transfer);
            transfer.value = this.usdtAddr; // 这里表示想要复制的内容
            transfer.focus();
            transfer.select();
            if (document.execCommand("copy")) {
                document.execCommand("copy");
            }
            transfer.blur();
            this.$toast(this.$t("fuzhiOK"));
            document.body.removeChild(transfer);
        },
    },
}
</script>

<style lang="scss" scoped></style>