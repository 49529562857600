<template>
  <div class="message">
    <header>
      <van-nav-bar :title="$t('message.title')" :style="bgColor">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>
    <div class="main">
      <div class="recordslist">
        <div class="item" v-for="(item, indexs) in list" :key="indexs">
          <div class="flex-row">
            <div class="pic">
              <img :src="item.img" alt="" />
            </div>
            <div class="info flex-column">
              <div class="orderno">{{ item.nickname }}</div>
              <div class="money">{{ item.mobile }}</div>
              <!-- <div class="money">点击跳转:{{item.url}}</div>  -->
              <div class="money">{{ item.mobile_name }}</div>
            </div>
            <div class="btn" @click="ChangeWWW(item.url)">
              {{ $t("message.go") }}
            </div>
          </div>
        </div>

        <!-- <div v-else>
              <van-empty
                  :description="$t('Registro.meiyoujilu')"
                  :image="url"
                  image-size="1.71rem"
                >
                  <div class="btnJump" @click="JumpRecolectar">
                    {{$t('Registro.xianzaishouji')}}
                  </div>
                </van-empty>
              </div>
            </div> -->
      </div>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Message",
  components: {
    Tabbar,
  },
  data() {
    return {
      list: [],
      bgColor:'',
    };
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    messageList() {
      userApi
        .chatList()
        .then((data) => {
          this.$toast.clear();
          this.list = data.data;
          console.log(data.data, "messageList");
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    ChangeWWW(url) {
      // window.location.href=url;
      // app以下跳转
      if (!window.location.host) {
        window.plus.runtime.openURL(`${url}`);
      } else {
        window.open(url)
      }
      // window.open(url)
    },
  },
  created() {
         this.bgColor = localStorage.getItem("bgColor");
    this.$toast.loading({
      message: this.$t("Conectando"),
      forbidClick: true,
      duration: 0,
    });
    this.messageList();
  },
};
</script>
<style lang="less" scoped>
.message {
  background: #f7f7f7;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
.main {
  // padding: 0 0.4rem 0.4rem;
  box-sizing: border-box;
}
.item {
  padding: 0.32rem;
  // margin-top: 0.27rem;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}
.time {
  color: #777;
  font-size: 0.37rem;
  margin-bottom: 0.13rem;
  text-align: left;
}
.item .flex-row {
  display: flex;
  align-items: center;
  // justify-content: space-between;
}
.pic {
  width: 2.13rem;
  height: 2.13rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
.info {
  display: block;
  flex: 1;
  text-align: left;
  margin-left: 0.27rem;
}
.orderno {
  font-weight: 700;
  margin: -0.05rem 0 0.15rem;
}
.money {
  color: #999;
  margin: -0.05rem 0 0.15rem;
}
.btn_box {
  align-self: center;
  width: 2.67rem;
  padding: 0.13rem 0.27rem;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f762a2;
  border-radius: 20px;
  color: #fff;
  font-size: 0.32rem;
}
.unPaid {
  display: flex;
  flex-direction: column;
  font-size: 0.32rem;
  align-items: center;
}
.item .flex-row .unPaid > div:nth-of-type(2) {
  color: #dd524d;
}
.btn {
  width: 1.87rem;
  padding: 0.13rem 0.27rem;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  border-radius: 0.53rem;
  color: #fff;
  font-size: 0.32rem;
  margin-left: auto;
}
.btnJump {
  color: #fff;
  width: 3.2rem;
  height: 1.07rem;
  line-height: 1.07rem;
  border-radius: 1.07rem;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  margin-top: 0.27rem;
  text-align: center;
}
.van-empty {
  box-sizing: border-box;
  padding: 1.33rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.67rem;
}
</style>