<template>
  <div>
    <header>
      <van-nav-bar left-arrow @click-left="JumpGo" class="bg-b-2 text-t-1">
        <template #left>
          <van-icon name="arrow-left" size="20" />
        </template>
        <template #title>
          <span>{{ $t("prsonal.title") }}</span>
        </template>
      </van-nav-bar>
    </header>

    <div class="" v-if="UserInfo">
      <div class="flex flex-col p-2 divide-y divide-dashed">
        <div class="flex justify-between py-2">
          <div class="">{{ $t('prsonal.name') }}</div>
          <div class="">{{ UserInfo.nickname }}</div>
        </div>
        <div class="flex justify-between py-2">
          <div class="">{{ $t('prsonal.shouji') }}</div>
          <div class="">{{ UserInfo.mobile }}</div>
        </div>
        <div class="flex justify-between py-2">
          <div class="">{{ $t('prsonal.yhk') }}</div>
          <div class="" style="flex: 2" @click="JumpCard">{{ $t('prsonal.tianxie') }}</div>
          <div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import Tabbar from "@/components/tabbar/tabbar.vue";
import storeAction from "@/store/typed-actions";
export default {
  name: "Personal",
  components: { Tabbar },
  data() {
    return {
      UserInfo: null,
      bgColor: ''
    };
  },
  methods: {
    JumpCard() {
      this.$router.push({
        path: "/card",
      });
    },
    outLogin() {
      let that = this;

      storeAction.delsessionStorage("token");
      storeAction.delsessionStorage("userid");
      storeAction.delsessionStorage("nickname");
      storeAction.delsessionStorage("userinfo");
      that.$toast.loading({
        message: this.$t('Conectando'),
        forbidClick: true,
        duration: 500,
        onClose() {
          that.$router.push({
            path: "/login",
          });
        },
      });
    },
    userinfo() {
      userApi
        .userInfo()
        .then((data) => {
          this.UserInfo = data.data;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    JumpGo() {
      this.$router.go(-1);
    },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    this.$toast.loading({
      message: this.$t('Conectando'),
      forbidClick: true,
      duration: 0,
    });
    this.userinfo();
  },
};
</script>
<style lang="less" scoped>
.prsonal {
  background: #fff;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.main {
  padding: 0 0.4rem;
  box-sizing: border-box;
}

.items {
  background: #fff;
  border-radius: 10px;
  padding: 0.4rem 0.13rem 0.03rem 0.4rem;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.6rem;
  line-height: 1.6rem;
  margin-bottom: 0.27rem;
  position: relative;
  border-bottom: 2px solid #eeeff2;
  box-shadow: 0 0 0 0 #eeeff2;
  position: relative;
}

.label {
  display: block;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #334360;
}

.input {
  text-align: right;
  padding-right: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.43rem;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #939bab;
  margin-right: 0.13rem;
}

.btn {
  width: calc(100% - 50px);
  padding: 0.27rem 0;
  border: 1px solid #dedede;
  color: #666;
  text-align: center;
  border-radius: 20px;
  position: fixed;
  bottom: 1.73rem;
  left: 0.6rem;
}
</style>