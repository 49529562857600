<template>
  <div>
    <header>
      <van-nav-bar :title="$t('Recargar.title')" class="bg-b-2 text-t-1">
        <template #left>
          <van-icon name="arrow-left" size="20" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>
    <div class="p-3 flex flex-col">
      <div class="mb-5 p-3 flex justify-between rounded-md bg-b-2 shadow">
        <div class="flex flex-col justify-between">
          <div class="text-sm">{{ $t("home.Balance") }}</div>
          <div class="text-3xl">
            <span class="text-xl">{{ $t("Mex") }}{{ monetary_unit }}</span
            ><span class="">{{ UserInfo.money || 0 }}</span>
          </div>
          <!-- <div class="text-t-2">account</div> -->
        </div>
      </div>
      <div class="container mx-auto mb-10">
        <div class="my-3 font-bold flex space-x-3">
          <div for="amount">{{ $t("Recargar.Selección") }}</div>
          <div class="flex-1">
            <input
              id="amount"
              type="tel"
              v-model="selectedAmount"
              ref="amountInput"
              @focus="handleFocus"
            />
          </div>
        </div>
        <div
          class="grid grid-cols-3 gap-2 justify-between items-center text-center text-sm"
        >
          <div
            v-for="(amount, index) in presetAmounts"
            :key="index"
            class="rounded-md py-2"
            :class="{
              'bg-p-1 text-p-3':
                amount === selectedAmount ||
                (amount === Number(selectedAmount) && selectedAmount !== ''),
              'bg-b-2 ': amount !== selectedAmount,
            }"
            @click="selectAmount(amount)"
          >
            {{ amount }}
          </div>
          <div
            class="rounded-md py-2"
            :class="{
              'bg-p-1 text-p-3': selectedAmount === customAmount,
              'bg-b-2': selectedAmount !== customAmount,
            }"
            @click="focusCustomInput"
          >
            <span class="material-symbols-outlined text-sm">
              border_color
            </span>
          </div>
        </div>
      </div>

      <div class="flex mb-3">
        <div
          class="w-full py-3 text-center bg-p-1 rounded-md text-p-3"
          @click="JumpRechargeAction"
        >
          {{ $t("Confirmar") }}
        </div>
      </div>

      <div class="text-sm font-light text-t-2">
        <p>{{ $t("Recargar.text") }}</p>
      </div>
    </div>

    <!-- 选择器-type -->
    <van-popup
      v-model:show="showSelect"
      @click-overlay="onConfirmstype"
      position="bottom"
    >
      <van-picker
        show-toolbar
        :columns="columnsList"
        @confirm="onConfirmtype"
        @cancel="showSelect = false"
        @change="changeListtype"
        :confirm-button-text="$t('alert.queding')"
        :cancel-button-text="$t('alert.quxiao')"
      />
    </van-popup>
  </div>
</template>
<script>
// @ is an alias to /src
import userApi from "@/api/user";
// import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Recharge",
  components: {
    // Tabbar,
  },
  data() {
    return {
      presetAmounts: [100, 200, 500, 1000, 2000, 5000],
      customAmount: "",
      selectedAmount: "",

      money: "",
      activeCur: 0,
      moneyDefaut: "",
      moneylist: [],
      PaymodeList: [],
      activeCurMode: null,
      mode_id: "0",
      custom: [],
      customKey: [],
      showSelect: false,
      columnsList: [],
      columnsListKey: [],
      selectfindName: "",
      typeName: "",
      ChangeKey: "",
      changName: "",
      bgColor: "",
    };
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
    UserInfo() {
      console.log(this.$store.state.currentUser);
      return JSON.parse(this.$store.state.currentUser);
    },
  },

  onmounted() {
    const input = document.getElementById("amount");
    input.focus();
  },

  methods: {
    selectAmount(amount) {
      console.log(amount);
      this.selectedAmount = amount;
    },

    focusCustomInput() {
      this.customAmount = "";
      this.selectedAmount = this.customAmount;
      const input = document.getElementById("amount");
      input.focus();
    },

    handleFocus() {
      this.selectedAmount = this.customAmount;
    },

    JumpGo() {
      this.$router.push({
        path: "/selectChannel",
      });
    },

    JumpRechargeAction() {
      //判断充值金额大于20000则提示用户分批充值
      // if (this.selectedAmount > 20000) {
      //   this.$dialog.alert({
      //     title: this.$t("alert.title"),
      //     confirmButtonText: this.$t("alert.queding"),
      //     message: this.$t("Recargar.UnsupportedAmount"),
      //   });
      //   return;
      // }

      if (this.activeCurMode == null && this.PaymodeList.length != []) {
        this.$dialog.alert({
          title: this.$t("alert.title"),
          confirmButtonText: this.$t("alert.queding"),
          message: this.$t("qxzzffs"),
        });
        return;
      }
      console.log(this.mode_id, this.custom, "钱");
      for (let k = 0; k < this.customKey.length; k++) {
        if (this.customKey[k].required == 1) {
          if (!this.custom[this.customKey[k].property]) {
            this.$dialog.alert({
              title: this.$t("alert.title"),
              confirmButtonText: this.$t("alert.queding"),
              confirmButtonColor: "#007aff",
              message: this.$t("card.qyh") + this.customKey[k].font,
            });
            return;
          }
        }
      }
      let custom = JSON.stringify(this.custom);
      if (this.selectedAmount == 0) {
        return;
      }
      console.log(custom);
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .payMode(this.selectedAmount.replace(/\s/g, ""), this.mode_id, custom)
        .then((data) => {
          this.$toast.clear();
          // this.UserInfo = data.data;
          if (data.data.payInfo != null) {
            // 调
            if (data.data.payInfo.status == 1) {
              //app
              if (!window.location.host) {
                window.plus.runtime.openURL(`${data.data.payInfo.url}`);
              } else {
                // window.open(data.data.payInfo.url);
                window.location.href = data.data.payInfo.url;
              }
              // window.open(data.data.payInfo.url)
            }
          } else {
            this.$router.push({
              path: "/rechargeAction",
              query: {
                money: this.selectedAmount,
                log_id: data.data.log_id,
                bankInfo: JSON.stringify(data.data.bankInfo),
              },
            });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },

    PayList() {
      userApi
        .modelSmilInfo()
        .then((data) => {
          console.log(data.data, "支付方式");
          if (data.data.payModeType != 0) {
            this.PaymodeList = data.data.models;
          }
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    PayModeIdSelect(id) {
      this.typeName = "";
      this.mode_id = id;
      console.log(id);
      userApi
        .payModeCustom(id)
        .then((data) => {
          console.log(data.data, "支付方式自定义表单");
          if (data.data != null) {
            this.custom = data.data.columbia;
            this.customKey = data.data.font_from;
            let selectfind = data.data.font_from.find(
              (item) => item.type == "select"
            );
            if (selectfind) {
              this.selectfindName = selectfind.property;
              this.columnsList = Object.values(selectfind.selects);
              this.columnsListKey = Object.keys(selectfind.selects);
            }
          } else {
            this.custom = [];
            this.customKey = [];
          }
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    onConfirmtype(value, index) {
      if (this.selectfindName) {
        this.custom[this.selectfindName] = this.columnsListKey[index];
        this.typeName = value;
      }
      this.showSelect = false;
    },
    changeListtype(value, index) {
      console.log(value, index);
      this.ChangeKey = this.columnsListKey[index];
      this.changName = value;
    },
    onConfirmstype() {
      if (this.selectfindName) {
        this.custom[this.selectfindName] = this.ChangeKey;
        this.typeName = this.changName;
      }
      this.showSelect = false;
    },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    let pay_blocks = localStorage.getItem("pay_blocks");
    pay_blocks = pay_blocks.split(",");
    console.log((this.presetAmounts = pay_blocks), "pay_blocks");
    this.money = pay_blocks[0];
    this.$toast.loading({
      message: this.$t("Conectando"),
      forbidClick: true,
      duration: 0,
    });
    if (
      !localStorage.getItem("pay_limit") ||
      localStorage.getItem("pay_limit") == undefined
    ) {
      this.moneyDefaut = 50;
    } else {
      this.moneyDefaut = localStorage.getItem("pay_limit");
    }

    // this.userinfo();
    this.PayList();
  },
};
</script>
<style lang="less" scoped>
button {
  margin-right: 10px;
  padding: 5px 10px;
}

.selected {
  background-color: yellow;
}

.recharge {
  background: #f1f1f1;
  font-size: 13.88px;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.balanceTotal {
  background: #fff;
}

.current-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.61rem;
  // background: linear-gradient(270deg, #747e95, #444d68);
  border-radius: 0.43rem 0.43rem 0px 0px;
  margin: 0.21rem 0.53rem 0px;
  padding: 0 0.53rem;
}

.current-balance {
  color: #fff;
  opacity: 0.8;
  font-size: 0.43rem;
}

.money {
  font-size: 0.64rem;
}

.line {
  height: 0.48rem;
  margin: 0 0.53rem;
  // background: linear-gradient(270deg, #747e95, #444d68);
}

.title {
  width: 100%;
  height: 1.17rem;
  line-height: 1.17rem;
  font-weight: 400;
  color: #334360;
  background: #fff;
  margin-top: 0.11rem;
  text-align: left;
  box-sizing: border-box;

  span {
    padding-left: 0.53rem;
  }
}

.input .leftTXT {
  display: flex;
  align-items: center;
  height: 1.49rem;
  padding-left: 0.53rem;
  background: #fff;
}

.payMoney {
  font-size: 0.64rem;
  text-align: left;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #334360;
}

.payinput {
  font-size: 0.85rem;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #334360;
  flex: 1;
  height: 100%;
  display: flex;

  input {
    caret-color: #017aff;
    width: 100%;
  }
}

.tip {
  padding-left: 0.53rem;
  height: 1.07rem;
  line-height: 1.07rem;
  background: #fff;
  text-align: left;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 0.32rem;
  font-weight: 400;
  color: #334360;
  opacity: 0.4;
}

.selectMain {
  padding: 0 0.53rem;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  // padding-bottom: 1.33rem;
}

.titleS {
  font-weight: 400;
  color: #334360;
  margin: 0.43rem 0;
  text-align: left;
}

.money-list {
  overflow: hidden;
  padding-bottom: 0.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.block {
  width: 30%;
  height: 1.39rem;
  line-height: 1.33rem;
  text-align: center;
  color: #34d399;
  font-weight: 500;
  background: #f6fafe;
  border-radius: 0.21rem;
  border: 1px solid #ddd;
  float: left;
  margin-top: 0.4rem;
  font-size: 0.43rem;
}

.blockMode {
  width: 30%;
  height: 1.39rem;
  line-height: 1.33rem;
  text-align: center;
  color: #34d399;
  font-weight: 500;
  background: #f6fafe;
  border-radius: 0.21rem;
  border: 1px solid #ddd;
  float: left;
  margin-top: 0.4rem;
  font-size: 0.28rem;
}

.active {
  border: 1px solid #34d399;
}

.topup {
  height: 1.07rem;
  line-height: 1.07rem;
  text-align: center;
  color: #fff;
  margin-top: 0.85rem;
  font-size: 0.37rem;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  border-radius: 4px;
}

.notice {
  font-weight: 400;
  color: #636f85;
  margin: 0.4rem 0;
  font-size: 0.35rem;
  text-align: left;
}

.items {
  background: #fff;
  border-radius: 5px;
  padding: 0.4rem 0.4rem 0.03rem 0.4rem;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
  margin-bottom: 0.27rem;
  position: relative;
}

.evan-form-item-container {
  border: none;
  width: 100%;
  flex-direction: column;
}

.evan-form-item-container__label {
  color: #000;
  width: 100%;
  font-size: 0.37rem;
}

.evan-form-item-container__label {
  font-size: 0.37rem;
  color: #666;
  line-height: 0.53rem;
  padding: 0.32rem 0;
  display: inline-block;
  text-align: left;
}

.evan-form-item-container__main {
  flex: 1;
  min-height: 1.2rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.van-cell {
  width: 100%;
  height: 100%;
  padding: 0;
  background: none;
}

.money-list::after {
  content: "";
  width: 30%;
}
</style>