const yn = {
    home: {
      title: "Trung tâm đặt hàng của người mua",
      chongzhi: "Nạp tiền",
      tixian: "Rút tiền",
      yaoqing: "Giới thiệu",
      Aviso: "Thông báo",
      Balance: "quỹ",
      Hoy: "Hôm nay",
      Financiero: "tài chính",
      Congelar: "Đóng băng",
      MiembroNoticias: "Thông tin thành viên",
      Socio: "Cộng sự",
      About: "Về chúng tôi",
      aboutwithdrawal: "Hướng dẫn Nạp Rút",
    },
    about: {
      title: "Về chúng tôi",
      guanyutixian: "Hướng dẫn Nạp Rút",
    },
    Recargar: {
      title: "Nạp tiền",
      Introducir: "Vui lòng nhập số tiền nạp",
      tps: "Số tiền nạp tối thiểu",
      Selección: "Lựa chọn nhanh số lượng",
      text: "Nhắc nhở: Tài khoản nền tảng ngân hàng có thể được thay đổi bất kỳ lúc nào. Đối với mỗi lần gửi tiền, vui lòng chuyển đến trang mà bạn đã điền thông tin và cho biết số tài khoản mới nhất. Chúng tôi không chịu trách nhiệm nếu bạn gửi tiền vào tài khoản đã hết hạn!",
    },
    RecargarAction: {
      title: "Nạp tiền",
      Cantidad: "Số tiền nạp",
      Información: "Thông tin người nhận thanh toán",
      tps: "Sau khi quá trình chuyển hoàn tất, trước tiên hãy nhớ tải lên ảnh chụp màn hình của quá trình chuyển, sau đó nhấp vào nút gửi!",
      jietu: "Tải lên ảnh chụp màn hình chuyển tiền thành công",
      querenchongzhi: "Xác nhận nạp tiền",
    },
    Recolectar: {
      title: "Hoa hồng",
      Pedidos: "Hoa hồng đặt hàng",
      yongjin: "Hoa hồng",
      jinrishouru: "Thu nhập ngày hôm nay",
      gerenshouru: "Thu nhập cá nhân",
      yiwancheng: "Nhiệm vụ đã hoàn thành",
      dongjie: "lệnh đóng băng",
      weiwancheng: "Đơn đặt hàng chưa hoàn thành",
      dongjiejine: "Số lượng đóng băng",
      dangqianyue: "Số dư hiện tại",
      kaishi: "Bắt đầu thu thập",
      tingzhi: "Ngừng thu thập",
      tishi: "gợi ý",
      Segundo: "Thứ hai",
      text: "Sau khi người dùng hoàn tất đơn hàng, nền tảng sẽ đồng thời gửi thông tin đơn hàng cho người bán. Nếu người dùng không thanh toán đơn hàng trong một khoảng thời gian nhất định, đơn hàng sẽ bị đóng băng để tránh sự giám sát của nền tảng mua sắm trực tuyến. Khi một đơn đặt hàng bị đóng băng, tiền trong đơn đặt hàng cũng bị đóng băng. Bạn phải đợi một vài giờ để hệ thống giải phóng chúng. Hãy ghi nhớ điều này.",
    },
    Registro: {
      title: "Ghi lại",
      daizhifu: "Thanh toán",
      yijiesuan: "Đặt đơn",
      chulizhong: "Xử lý",
      yidongjie: "Đóng băng",
      meiyoujilu: "Không có hồ sơ",
      xianzaishouji: "Thu thập ngay bây giờ",
      jiage: "Giá",
      yongjin: "Uỷ ban",
      shouyi: "The nhập",
      shengyutime: "Thời gian còn lại",
      chakan: "Kiểm tra đơn hàng",
      weizhifu: "Chưa thanh toán",
      fukuan: "Sự chi trả",
    },
    Mex: "",
    Confirmar: "Xác nhận",
    Conectando: "Đang tải",
    fuzhiOK: "được sao chép vào pasteboard",
    Detalles: {
      title: "Chi tiết đặt hàng",
      yigong: "Tổng cộng",
      dingdan: " đơn đặt hàng",
      dangqian: "Hiện là người đầu tiên",
      yiwancheng: "Nhiệm vụ đã hoàn thành",
      dongjie: "Đơn hàng bị đóng băng",
      dengdai: "Chờ người mua xác nhận đơn hàng",
      dongjies: "Đóng băng",
      zhuyao: "cửa hàng chính",
      guanfang: "đảm bảo chính thức",
      zongshuliang: "Tổng số lượng",
      daigou: "Phí mua hàng",
      shouyi: "The nhập",
      fukuanzt: "tình trạng thanh toán",
      weizhifu: "Chưa thanh toán",
      yizhifu: "Trả",
      jiedong: "Thời gian rã đông",
      zhifu: "Chi trả",
      shuruzhifu: "Vui lòng nhập mật khẩu thanh toán",
      yanzheng: "Xác minh mật khẩu thanh toán",
    },
    Pofile: {
      title: "Chi tiết tài khoản",
      yaoqingma: "Mã giới thiệu",
      fuzhi: "Sao chép link giới thiệu",
      Balance: "quỹ",
      tixian: "Rút tiền",
      chongzhi: "Nạp tiền",
      zongji: "Ví chính",
      jinrong: "tài chính",
      xiangqing: "Thông tin chi tiết",
      dongjie: "Đóng băng",
      geren: "Cá nhân",
      tuandui: "đội",
      yaoqing: "Giới thiệu",
      xiaoxi: "CSKH",
      dizhi: "Địa chỉ",
      guanyu: "về chúng tôi",
      renwu: "Mời nhiệm vụ",
      anquan: "Bảo mật",
      fuwu: "Trung tâm dịch vụ",
    },
    alert: {
      title: "gợi ý",
      quxiao: "Hủy bỏ",
      queding: "Đảm bảo",
      acuerdo: "đồng ý",
      fuzhi: "sao chép",
      qianwang: "đi đến",
    },
    withdrawal: {
      title: "Rút tiền",
      yue: "số dư hiện tại",
      shouxufei: "Phí rút tiền",
      dancitixian: "Phí xử lý tối thiểu cho một lần rút tiền",
      shuru: "Nhập số tiền",
      dangqianjine: "Số tiền rút từ tài khoản hiện tại",
      suoyou: "giải nén tất cả",
      zhanghuming: "tên tài khoản",
      zhanghu: "tài khoản",
      dianhua: "Số điện thoại",
      tps: "gợi ý",
      text: "1.Quý khách vui lòng điền chính xác thông tin ngân hàng, chúng tôi không chịu trách nhiệm về việc thất thoát tiền vốn do sai sót quý khách khi nhập thông tin; trước 2h24 ngày hôm sau, thời gian đến cụ thể tùy ngân hàng.",
      queren: "Xác nhận rút tiền",
      guanlian: "Vui lòng liên kết thẻ ngân hàng của bạn trước khi rút tiền",
    },
    prsonal: {
      title: "Cá nhân",
      name: "Chủ tài khoản",
      shouji: "số điện thoại",
      yhk: "Thẻ ngân hàng",
      tuichu: "Đăng xuất",
      tianxie: "điền vào",
    },
    card: {
      title: "Cài đặt thẻ ngân hàng",
      zhanghuxinxi: "Thông tin tài khoản",
      tps: "Hãy đảm bảo rằng tài khoản của bạn là chính xác, chúng tôi không chịu trách nhiệm nếu có bất kỳ sai sót nào.",
      yhmc: "Tên ngân hàng",
      zhanghuming: "Tên tài khoản",
      zhanghu: "tài khoản",
      shouji: "Số điện thoại",
      fenhang: "số chi nhánh",
      baocun: "Xác định",
      jianyi: "Đề xuất",
      text: "Vui lòng liên hệ với dịch vụ khách trực tuyến để biết phương thức ràng buộc thẻ ngân hàng, trong trường hợp bạn cần sửa đổi nó!",
      qyh: "xin vui lòng chọn",
      qsryhm: "Vui lòng nhập tên người dùng ngân hàng",
      qzh: "Vui lòng nhập tài khoản",
      qdh: "Vui lòng nhập số điện thoại",
      qfhmc: "Vui lòng nhập tên chi nhánh",
      qsrifcs:'Vui lòng nhập IFSC',
      youxiang:'Thư',
      plyx:'vui lòng nhập email của bạn',
      jgbh:'số chi nhánh',
      pljgbh:'Vui lòng nhập số chi nhánh',
      gjj:'Quỹ tiết kiệm',
      plgjj:'Mời vàovui lòng nhập',
      isbp:"ISBP",
      plisbp:"Vui lòng nhập ISBP",
      pixtype:'PIXType',
      plpixtype:'Vui lòng nhập PIXType',
      pix:'PIX',
      plpix:'Vui lòng nhập PIX',
      evp:'EVP',
      plevp:'Vui lòng nhập EVP',
    },
    team: {
      title: "Đội nhóm",
      Directo: "trực tiếp",
      Secundario: "Trung học phổ thông",
      Terciario: "ngày thứ ba",
      Comisión: "Phí mua hôm nay",
      zongyongjin: "tổng hoa hồng",
      shuzi: "con số",
      sudu: "tốc độ",
      jinricz: "Nạp tiền ngay hôm nay",
      tixian: "rút tiền đầy đủ",
    },
    invitation: {
      title: "Mời bạn bè",
      notitle: "Bạn có thể làm công việc bán thời gian từ điện thoại của mình",
      notitles: "Mời bạn bè để kiếm nhiều tiền hơn",
      yqm: "Mã giới thiệu",
      fuzhi: "Sao chép",
      xzyq: "Mời ngay bây giờ",
    },
    message: {
      title: "CSKH",
      go: "kết nối",
    },
    address: {
      title: "Quản lý địa chỉ",
      xm: "Chủ tài khoản",
      dianhua: "Điện thoại",
      qtx: "Vui lòng điền",
      dizhixq: "Thông tin địa chỉ giao hàng",
      baocun: "Xác định",
    },
    invitationTask: {
      title: "Mời nhiệm vụ",
      zongren: "tổng số người",
      gengxin: "Tác vụ cập nhật: người dùng được tải lại là người dùng hợp lệ",
      yq: "Giới thiệu",
      jige: "thành viên tích cực",
      qianwang: "đi đến",
      renwujiangli: "phần thưởng nhiệm vụ",
    },
    safe: {
      aqgl: "Quản lý an toàn",
      denglu: "mật khẩu đăng nhập",
      zfmm: "mật khẩu thanh toán",
      bh: "Bảo vệ",
    },
    password: {
      title: "mật khẩu đăng nhập",
      titles: "mật khẩu thanh toán",
      shouji: "số điện thoại",
      yanzheng: "mã xác nhận",
      fasong: "gửi",
      xinmima: "Mật khẩu mới",
      pxinmima: "Nhập mật khẩu mới",
    },
    record: {
      title: "Thông tin chi tiết",
      quanbu: "tất cả các",
      chongzhi: "Nạp tiền",
      tixian: "Rút tiền",
      dingdan: "Gọi món",
      tuandui: "Đội nhóm",
      zongsu: "Ví chính",
      dangqian: "dữ liệu hiện tại",
      kaishisj: "Thời gian bắt đầu",
      jieshusj: "Thời gian kết thúc",
    },
    login: {
      zhanghu: "Vui lòng nhập tài khoản",
      mima: "Xin vui lòng nhập mật khẩu",
      denglu: "Đăng nhập",
      zhuce: "Đăng ký ngay",
      wjmm: "Tôi quên mật khẩu của tôi",
    },
    regist: {
      touxiang: "hình đại diện",
      yhm: "tên tài khoản",
      mima: "mật khẩu mở khóa",
      zhifu: "mật khẩu thanh toán",
      yq: "Giới thiệu",
      pyhm: "Vui lòng nhập tên người dùng",
      ptel: "Vui lòng nhập số điện thoại",
      shuru: "Xin vui lòng nhập mật khẩu",
      shurupay: "Nhập mã bảo mật gồm 6 chữ số",
      pyqm: "Mã giới thiệu",
      plyqm:'Vui lòng nhập mã mời',
      zhuce: "Đăng ký",
      yijing: "Bạn co săn san để tạo một tai khoản?",
    },
    tabbars: {
      shouye: "Trang chủ",
      chongzhi: "Nạp tiền",
      shouji: "Nhận đơn",
      dingdan: "Đặt hàng",
      wode: "Cá nhân",
    },
    zhaohuimima: "Vui lòng liên hệ với bộ phận chăm sóc khách hàng để xử lý",
    yanzhengzhifu:'Xác minh mật khẩu thanh toán',
    huanying:'hoan nghênh',
    yinhang:'ngân hàng',
    yinhangbh:'Mã chuyển tiền',
    beizhu:'Nội dung chuyển tiền',
    zhifufs:'Phương pháp thanh toán',
    qxzzffs:'Hãy chọn phương pháp thanh toán',
  };
  export default yn;
  