import storeAction from "../typed-actions";
import router from "@/router";
import { Toast } from "vant";
// 登录成功 - 初始化信息
export function loginDone(this_,res) {
  const userid = res.id;
  storeAction.setToken(res.token);
  storeAction.setCurrentUserId(userid);
  storeAction.setCurrentNickname(res.nickname)
  storeAction.setsessionStorage("userid", userid);
  storeAction.setsessionStorage("nickname", res.nickname);
  storeAction.setCurrentUser(res);
  storeAction.setsessionStorage("userinfo",JSON.stringify(res) );
  Toast.loading({
    message:this_.$t('Conectando'),
    forbidClick: true,
    duration: 500,
    onClose() {
      router.replace(`/`);
    },
  });
}
