<template>
  <div class="frozen">
    <header>
      <van-nav-bar title="Congelar los fondos" :style="bgColor">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>
    <div class="main">
      <template v-if="list.length != [] || list || list.length != 0">
        <template v-for="(item, index) in list" :key="index">
          <div
            class="items"
            v-for="(itemGoods, indexs) in item.goods"
            :key="indexs"
          >
            <div class="item" @click="JumpOrderdetail(itemGoods, item)">
              <div class="title">
                Pay the order and freeze the order amount 【{{
                  itemGoods.quantity
                }}】.
              </div>
              <div class="time">{{ itemGoods.created_at }}</div>
              <div class="right-arrow"></div>
            </div>
          </div>
        </template>
      </template>
      <van-empty
        description="No hay noticias"
        :image="url"
        image-size="1.71rem"
        v-else
      >
      </van-empty>
    </div>
    <tabbar />
  </div>
</template>

<script>
import orderApi from "@/api/order";
// @ is an alias to /src
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "About",
  components: { Tabbar },
  data() {
    return {
      url: require("@/assets/image/empty.png"),
      list: "",
      bgColor:''
    };
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    info() {
      this.$toast.loading({
        message: " Conectando...",
        forbidClick: true,
        duration: 0,
      });
      orderApi
        .freezeList()
        .then((data) => {
          console.log(data.data);
          this.list = data.data;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    JumpOrderdetail(goods, item) {
      console.log(goods, item);
      if (
        item.total_goods_count != null &&
        item.total_goods_count != undefined
      ) {
        goods["goodsNum"] = item.total_goods_count;
      }
      if (
        item.address_name != null &&
        item.address_name != undefined
      ) {
        goods["address"] = {
          address_mark: item.address_mark,
          address_name: item.address_name,
          address_mobile: item.address_mobile,
        };
      } else {
        goods["address"] = "";
      }
        this.$router.push({
            path: "/orderDetail",
            query: {
              goods: JSON.stringify(goods),
            },
          });
      console.log(goods)
    },
  },
  created() {
         this.bgColor = localStorage.getItem("bgColor");
    this.info();
  },
};
</script>
<style lang="less" scoped>
.frozen {
  background: #f7f7f7;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
.main {
  padding: 0 0.4rem 0.4rem;
  background: #f7f7f7;
  box-sizing: border-box;
}
.van-empty {
  box-sizing: border-box;
  padding: 1.33rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.67rem;
}
.items {
  margin-top: 0.4rem;
}
.item {
  padding: 0.27rem;
  background: #fff;
  border-radius: 0.13rem;
  margin-bottom: 0.27rem;
  position: relative;
}
.title {
  color: #333;
  font-size: 0.37rem;
}
.time {
  color: #999;
  font-size: 0.35rem;
  margin-top: 0.08rem;
}
.right-arrow {
  width: 0.21rem;
  height: 0.21rem;
  border: 0.08rem solid #aaa;
  border-bottom-color: transparent;
  border-left-color: transparent;
  position: absolute;
  right: 0.27rem;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
</style>