const ar = {
  home: {
    title: "مركز طلب المشتري",
    chongzhi: "تعبئة رصيد",
    tixian: "انسحاب",
    yaoqing: "دعوة",
    Aviso: "نصائح",
    Balance: "رأس المال",
    Hoy: "اليوم",
    Financiero: "تمويل",
    Congelar: "مجمدة",
    MiembroNoticias: "معلومات الاعضاء",
    Socio: "تعاون",
    About: "معلومات عنا",
    aboutwithdrawal: "عن الانسحاب",
  },
  about: {
    title: "معلومات عنا",
    guanyutixian: "عن الانسحاب",
  },
  Recargar: {
    title: "تعبئة رصيد",
    Introducir: "الرجاء إدخال مبلغ إعادة الشحن",
    tps: "الحد الأدنى لمبلغ إعادة الشحن ",
    Selección: "كمية التحديد السريع",
    text: "نصيحة: يتغير حساب المنصة المصرفية في أي وقت. لكل إيداع ، يرجى الانتقال إلى الصفحة حيث تملأ المعلومات وتوضح رقم الحساب الأخير. إذا قمت بالإيداع في حساب منتهي الصلاحية ، فإننا لسنا مسؤولين!",
  },
  RecargarAction: {
    title: "إرسال إعادة الشحن",
    Cantidad: "مبلغ الشحن",
    Información: "معلومات المدفوع لأمره",
    tps: "بعد اكتمال النقل ، يرجى التأكد من تحميل لقطة شاشة النقل أولاً ، ثم النقر فوق زر الإرسال!",
    jietu: "تحميل نقل لقطة الشاشة",
    querenchongzhi: "قم بتأكيد إعادة الشحن",
  },
  Recolectar: {
    title: "اجمع الطلبات",
    Pedidos: "أمر العمولة",
    yongjin: "لجنة",
    jinrishouru: "دخل اليوم",
    gerenshouru: "الدخل الشخصي",
    yiwancheng: "الطلب جاهز",
    dongjie: "طلب التجميد",
    weiwancheng: "تنظيمات متميزة",
    dongjiejine: "كمية مجمدة",
    dangqianyue: "الرصيد الحالي",
    kaishi: "بدء الجمع",
    tingzhi: "وقف الجمع",
    tishi: "نصائح",
    Segundo: "ثانيا",
    text: "بعد أن يكمل المستخدم الطلب ، سترسل المنصة معلومات الطلب إلى البائع في نفس الوقت. إذا لم يدفع المستخدم الطلب خلال فترة زمنية معينة ، فسيتم تجميد الطلب لتجنب إشراف منصة التسوق عبر الإنترنت. بمجرد تجميد الطلب ، يتم أيضًا تجميد الأموال الموجودة في الأمر. يجب أن تنتظر النظام حتى يذوبها في غضون ساعات قليلة. تذكر هذا.",
  },
  Registro: {
    title: "سجل",
    daizhifu: "لكي تدفع",
    yijiesuan: "تسوية",
    chulizhong: "يعالج",
    yidongjie: "مجمدة",
    meiyoujilu: "لا سجلات",
    xianzaishouji: "الآن اجمع",
    jiage: "سعر",
    yongjin: "عمولة",
    shouyi: "ربح",
    shengyutime: "الوقت المتبقي",
    chakan: "مشاهدة الطلب",
    weizhifu: "غير مدفوعة",
    fukuan: "دفع",
  },
  Detalles: {
    title: "تفاصيل الطلب",
    yigong: "كليا",
    dingdan: "أوامر",
    dangqian: "حاليا الثانية",
    yiwancheng: "تم اكتمال الطلب",
    dongjie: "طلب مجمد",
    dengdai: "في انتظار المشتري لتأكيد الطلب",
    dongjies: "مجمدة",
    zhuyao: "المخزن الرئيسي",
    guanfang: "ضمان رسمي",
    zongshuliang: "الكمية الإجمالية",
    daigou: "رسوم الشراء",
    shouyi: "ربح",
    fukuanzt: "حالة السداد",
    weizhifu: "غير مدفوعة",
    jiedong: "وقت الذوبان",
    zhifu: "دفع",
    yizhifu: "مدفوع",
    shuruzhifu: "الرجاء إدخال كلمة مرور الدفع",
    yanzheng: "تحقق من كلمة مرور الدفع",
  },
  Mex: "",
  Confirmar: "يتأكد",
  Conectando: "جار التحميل",
  Pofile: {
    title: "تفاصيل الحساب",
    yaoqingma: "شفرة الدعوة",
    fuzhi: "انسخ الرابط",
    Balance: "رأس المال",
    tixian: "انسحاب",
    chongzhi: "تعبئة رصيد",
    zongji: "المجموع",
    jinrong: "تمويل",
    xiangqing: "تفاصيل",
    dongjie: "مجمدة",
    geren: "شخصي",
    tuandui: "فريق",
    yaoqing: "دعوة",
    xiaoxi: "رسالة",
    dizhi: "تبوك",
    guanyu: "معلومات عنا",
    renwu: "دعوة مهمة",
    anquan: "حماية",
    fuwu: "مركز الخدمة",
  },
  alert: {
    title: "نصائح",
    quxiao: "يلغي",
    queding: "نعم",
    acuerdo: "يوافق على",
    fuzhi: "ينسخ",
    qianwang: "يذهب",
  },
  fuzhiOK: "تم النسخ على لوح اللصق",
  withdrawal: {
    title: "انسحاب",
    yue: "الرصيد الحالي",
    shouxufei: "رسوم السحب النقدي",
    dancitixian: "الحد الأدنى لرسوم المناولة لسحب واحد",
    shuru: "الرجاء إدخال المبلغ",
    dangqianjine: "مبلغ السحب من الحساب الجاري",
    suoyou: "استخراج كافة",
    zhanghuming: "أسم الحساب",
    zhanghu: "الحساب",
    dianhua: "رقم هاتف",
    tps: "نصائح",
    text: "1.يرجى ملء المعلومات المصرفية بدقة. لن نتحمل أي مسؤولية عن خسارة رأس المال الناتجة عن الخطأ المحتمل عند إدخال المعلومات ؛ قبل 2:24 في اليوم التالي ، يخضع وقت الوصول المحدد للبنك",
    queren: "تأكيد السحب",
    guanlian: "يرجى إقران بطاقتك المصرفية قبل السحب",
  },
  prsonal: {
    title: "شخصي",
    name: "الاسم الكامل",
    shouji: "رقم هاتف",
    yhk: "بطاقة مصرفية",
    tuichu: "تسجيل خروج",
    tianxie: "أملأ",
  },
  card: {
    title: "إعدادات البطاقة المصرفية",
    zhanghuxinxi: "معلومات الحساب",
    tps: "يرجى التأكد من صحة رقم حسابك. نحن لسنا مسؤولين عن أي خطأ。",
    yhmc: "اسم البنك",
    zhanghuming: "اسم المستخدم",
    zhanghu: "الحساب",
    shouji: "رقم هاتف",
    fenhang: "اسم فرع البنك",
    baocun: "الحفظ",
    jianyi: "عرض",
    text: "يرجى الاتصال بخدمة العملاء عبر الإنترنت لمعرفة طريقة ربط البطاقة المصرفية في حالة الحاجة إلى تعديلها!",
    qyh: "الرجاء تحديد أ ",
    qsryhm: "الرجاء إدخال اسم مستخدم البنك",
    qzh: "الرجاء إدخال حسابك",
    qdh: "الرجاء إدخال رقم الهاتف",
    qfhmc: "الرجاء إدخال اسم الفرع",
    qsrifcs: "الرجاء إدخال IFSC",
    youxiang: "البريد الإلكتروني",
    plyx: "الرجاء إدخال البريد الإلكتروني",
    jgbh: "رقم المنظمة",
    pljgbh: "الرجاء إدخال رقم المؤسسة",
    gjj: "CPF",
    plgjj: "تفضل ",
    isbp: "ISBP",
    plisbp: "الرجاء إدخال ISBP",
    pixtype: "النوع",
    plpixtype: "الرجاء إدخال نوع PIXType",
    pix: "PIX",
    plpix: "الرجاء إدخال PIX",
    evp: "EVP",
    plevp: "الرجاء إدخال EVP",
  },
  team: {
    title: "فريق",
    Directo: "مباشر",
    Secundario: "ثانوي",
    Terciario: "ثالث",
    Comisión: "لجنة اليوم",
    zongyongjin: "إجمالي العمولة",
    shuzi: "رقم",
    sudu: "سرعة",
    jinricz: "اشحن اليوم",
    tixian: "انسحاب كامل",
  },
  invitation: {
    title: "ادعو أصدقاء",
    notitle: "يمكنك القيام بعمل بدوام جزئي من خلال هاتفك المحمول",
    notitles: "ادعُ الأصدقاء لكسب المزيد من المال معًا",
    yqm: "شفرة الدعوة",
    fuzhi: "ينسخ",
    xzyq: "الآن دعوة",
  },
  address: {
    title: "إدارة العناوين",
    xm: "الاسم الكامل",
    dianhua: "هاتف",
    qtx: "من فضلك املأ",
    dizhixq: "تلقي معلومات العنوان",
    baocun: "الحفظ",
  },
  invitationTask: {
    title: "دعوة مهمة",
    zongren: "الرقم الإجمالي",
    gengxin: "مهمة التحديث: المستخدم المثقل هو مستخدم صالح",
    yq: "دعوة",
    jige: "أعضاء صالحون",
    qianwang: "GO",
    renwujiangli: "مكافأة المهمة ",
  },
  safe: {
    aqgl: "إدارة الأمن",
    denglu: "كلمة سر الدخول",
    zfmm: "كلمة مرور الدفع",
    bh: "يحمي",
  },
  password: {
    title: "كلمة سر الدخول",
    titles: "كلمة مرور الدفع",
    shouji: "رقم هاتف",
    yanzheng: "رمز التحقق",
    fasong: "إرسال",
    xinmima: "كلمة السر الجديدة",
    pxinmima: "أدخل كلمة مرور جديدة",
  },
  record: {
    title: "تفاصيل",
    quanbu: "جميع",
    chongzhi: "تعبئة رصيد",
    tixian: "انسحاب",
    dingdan: "ترتيب",
    tuandui: "فريق",
    zongsu: "المجموع",
    dangqian: "البيانات الحالية",
    kaishisj: "وقت البدء",
    jieshusj: "وقت النهاية",
  },
  login: {
    zhanghu: "الرجاء إدخال حسابك",
    mima: "الرجاء إدخال كلمة مرور",
    denglu: "تسجيل الدخول",
    zhuce: "سجل الان",
    wjmm: "لقد نسيت كلمة المرور",
  },
  regist: {
    touxiang: "رئيس صورة",
    yhm: "اسم المستخدم",
    mima: "كلمة المرور",
    zhifu: "كلمة مرور الدفع",
    yq: "دعوة",
    pyhm: "الرجاء إدخال اسم المستخدم",
    ptel: "يرجى ملء رقم الهاتف",
    shuru: "الرجاء إدخال كلمة مرور",
    shurupay: "أدخل أرقامًا مكونة من 6 أرقام",
    pyqm: "شفرة الدعوة",
    zhuce: "تسجيل",
    yijing: "لدي حساب بالفعل？",
    plyqm: "الرجاء إدخال رمز الدعوة",
  },
  tabbars: {
    shouye: "مسكن",
    chongzhi: "تعبئة رصيد",
    shouji: "يجمع",
    dingdan: "ترتيب",
    wode: "لي",
  },
  message: {
    title: "خدمة الزبائن",
    go: "اتصال",
  },
  zhaohuimima: "يرجى الاتصال بخدمة العملاء للمعالجة",
  yanzhengzhifu: "تحقق من كلمة مرور الدفع",
  huanying: "مرحبا بك في",
  yinhang: "بنك",
  yinhangbh: "عدد البنوك",
  beizhu: "ملاحظة",
  zhifufs:'طريقة الدفع او السداد',
  qxzzffs:'الرجاء تحديد طريقة الدفع',
};
export default ar;
