<template>
  <div class="flex flex-col">
    <header>
      <van-nav-bar :title="$t('RecargarAction.title')" class="bg-b-2 text-t-1">
        <template #left>
          <van-icon name="arrow-left" size="20" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>


    <div class="flex flex-col p-2">


      <div class="flex text-base bg-b-2  py-4 rounded-md shadow mb-4">
        <div class="text-p-2 w-full text-center space-x-1">
          <span> {{ $t("RecargarAction.Cantidad") }}:{{ $t("Mex") }}</span>
          <span>{{ monetary_unit
          }}{{ money }}</span>
        </div>
        <div class="tip"></div>
      </div>

      <div class="mb-2">{{ $t("RecargarAction.Información") }}</div>


      <!-- 银行卡收款信息 -->
      <van-cell-group v-if="log_id == 0" class="divide-y divide-dashed ">
        <van-cell :title="$t('prsonal.name')" @click="CopyBank(bankInfo.acc_name)" :value="bankInfo.acc_name"
          class="flex items-center py-1">
          <template #right-icon>
            <van-icon name="coupon-o" class="mx-2 text-t-2" />
          </template></van-cell>
        <van-cell :title="$t('prsonal.yhk')" @click="CopyBank(bankInfo.acc)" :value="bankInfo.acc"
          class="flex items-center py-1">
          <template #right-icon>
            <van-icon name="coupon-o" class="mx-2 text-t-2" />
          </template></van-cell>
        <van-cell :title="$t('yinhang')" @click="CopyBank(bankInfo.bank_name)" :value="bankInfo.bank_name"
          class="flex items-center py-1">
          <template #right-icon>
            <van-icon name="coupon-o" class="mx-2 text-t-2" />
          </template></van-cell>
        <van-cell :title="$t('yinhangbh')" @click="CopyBank(bankInfo.bank_code)" :value="bankInfo.bank_code"
          class="flex items-center py-1">
          <template #right-icon>
            <van-icon name="coupon-o" class="mx-2 text-t-2" />
          </template></van-cell>
        <van-cell :title="$t('beizhu')" @click="CopyBank(bankInfo.mark)" :label="bankInfo.mark" />
      </van-cell-group>


      <div class="bank"></div>
      <div class="bg-b-2  p-2 rounded-md shadow text-sm my-3">
        {{ $t("RecargarAction.tps") }}
      </div>
      <div class="flex flex-col">
        <div class="p-2">
          {{ $t("RecargarAction.jietu") }}
        </div>
        <div class="flex flex-col mb-3">
          <van-uploader v-if="!imageUrl" :after-read="afterRead" />
          <img :src="imageUrl" v-else alt="" @click="getImg(imageUrl)" class="max-h-20" />
        </div>
      </div>
      <div class="text-center bg-p-1 text-p-3 rounded-md py-2 mb-3" @click="save">
        {{ $t("RecargarAction.querenchongzhi") }}
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import homeApi from "@/api/home";
import { ImagePreview } from "vant";
export default {
  name: "RechargeAction",
  components: {},
  data() {
    return {
      money: "",
      imageUrl: "",
      log_id: "",
      UpUrl: "",
      bankInfo: "",
      bgColor: ''
    };
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
  },
  methods: {
    getImg(img) {
      ImagePreview({
        images: [img],
        closeable: true,
      });
    },
    // 图片上传
    async afterRead(file) {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      let formData = new FormData();
      console.log(file.file);
      formData.append("file", file.file);
      const uploadResult = await fetch(this.UpUrl, {
        method: "post",
        body: formData,
      });
      const result = await uploadResult.json();
      console.log(result, "result");
      if (result.result.variants[0]) {
        this.imageUrl = result.result.variants[0];
        this.$toast.clear();
      } else {
        this.$toast.clear();
        this.$toast.fail(result.errors);
      }
      // homeApi
      //   .fileStream(formData)
      //   .then((data) => {
      //     this.$toast.clear();
      //     console.log(data, "图片上传");
      //     this.imageUrl = data.data.avatar;
      //   })
      //   .catch((err) => {
      //     this.$toast.clear();
      //     this.$toast.fail(err.message);
      //   });
      //
    },
    save() {
      let that = this;
      that.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      console.log(that.log_id, "that.log_id");
      userApi
        .pay(that.money, that.imageUrl, that.log_id)
        .then((data) => {
          that.$toast.clear();
          console.log(data, "充值返回");
          that.$toast.success({
            message: data.message,
            forbidClick: true,
            duration: 500,
            onClose() {
              that.$router.go(-1);
            },
          });
        })
        .catch((err) => {
          that.$toast.clear();
          that.$toast.fail(err.message);
        });
    },
    JumpGo() {
      this.$router.go(-1);
    },
    UplodaUrl() {
      homeApi
        .getUploadUrl()
        .then((data) => {
          console.log(data.data, "上传地址");
          this.UpUrl = data.data.uploadUrl;
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    CopyBank(text) {
      let oInput = document.createElement("input");
      oInput.value = text
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      // this.$Message.success("复制成功！");
      this.$toast(this.$t("fuzhiOK"));
      oInput.remove();
    },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    this.money = this.$route.query.money;
    this.log_id = this.$route.query.log_id;
    this.bankInfo = JSON.parse(this.$route.query.bankInfo);
    this.UplodaUrl();
  },
};
</script>
<style lang="less" scoped>

:deep(.van-cell__title) {
  flex: none;
}

.rechargeAction {
  background: #f1f1f1;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main {}

.card {
  padding: 0.53rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
}

.error {
  font-size: 0.43rem;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #fb8a2f;
}

.tip {
  font-size: 0.37rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8abb6;
}

.titleo {
  margin-top: 0.27rem;
  padding-left: 0.27rem;
  height: 1.07rem;
  line-height: 1.07rem;
  background: #fff;
  font-size: 0.35rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23292f;
  text-align: left;
}

.tip-text {
  display: flex;
  padding-left: 0.53rem;
  background: #ff8c17;
  opacity: 0.6;
  margin-top: 0.27rem;
}

.tip-text div {
  opacity: 1;
  font-size: 0.35rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  text-align: left;
}

.image {
  // height: 3.41rem;
  padding-left: 0.53rem;
  padding-top: 0.37rem;
  background: #fff;
}

.image .title {
  height: 1.07rem;
  text-align: left;
}

.upload {
  width: 2rem;
  height: 2rem;
  background: #fefffe;
  border-radius: 4px;
  border: 1px solid #d3d7db;
  line-height: 2rem;
  text-align: center;
  font-size: 0.43rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.btn {
  height: 1.28rem;
  background: linear-gradient(145deg, #6dcab8, #3a78cb);
  border-radius: 4px;
  text-align: center;
  line-height: 1.28rem;
  color: #fff;
  margin: 0.8rem 0.53rem;
}
</style>