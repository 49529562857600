<template>
  <div>


    <header>
      <van-nav-bar left-arrow @click-left="JumpGo" class="bg-b-2 text-t-1">
        <template #left>
          <van-icon name="arrow-left" size="20" />
        </template>
        <template #title>
          <span>{{ $t("safe.aqgl") }}</span>
        </template>
      </van-nav-bar>
    </header>


    <div class="flex flex-col p-2 divide-y divide-dashed">
      <div class="flex justify-between py-2" @click="JumpPassword(0)">
        <div class="">{{ $t('safe.denglu') }}</div>
        <!-- <div class="input-item">{{ $t('safe.bh') }}</div> -->
        <div><van-icon name="arrow" size="16" color="#939bab" /></div>
      </div>
      <div class="flex justify-between py-2" @click="JumpPassword(1)">
        <div class="">{{ $t('safe.zfmm') }}</div>
        <!-- <div class="input-item">{{ $t('safe.bh') }}</div> -->
        <div><van-icon name="arrow" size="16" color="#939bab" /></div>
      </div>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src

import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Safe",
  components: { Tabbar },
  data() {
    return {
      active: 0,
      bgColor: ''
    };
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    JumpPassword(e) {
      console.log(e);
      this.$router.push({
        path: "/password",
        query: {
          type: e,
        },
      });
    },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
  },
};
</script>
<style lang="less" scoped>
.safe {
  background: #fff;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.main {
  box-sizing: border-box;
  padding: 0 0.4rem 0.4rem;
}

.items {
  border-radius: 10px;
  padding: 0.4rem 0.13rem 1px 0.4rem;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.6rem;
  line-height: 1.6rem;
  margin-bottom: 0.27rem;
  white-space: nowrap;
  box-shadow: 0 0 0 0 #eeeff2;
  border-bottom: 1px solid #eeeff2;
}

.label {
  width: 6rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #334360;
}

.input-item {
  width: calc(100% - 1.6rem - 0.53rem);
  text-align: right;
  padding-right: 0.27rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffb702;
}
</style>