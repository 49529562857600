const in_en = {
  home: {
    title: "Buyer order mall",
    chongzhi: "Recharge",
    tixian: "Withdrawal",
    yaoqing: "Invitation",
    Aviso: "Tips",
    Balance: "Capital",
    Hoy: "Today",
    Financiero: "Finance",
    Congelar: "Frozen",
    MiembroNoticias: "Member information",
    Socio: "Cooperation",
    About: "About Us",
    aboutwithdrawal: "About withdrawal",
  },
  about: {
    title: "About Us",
    guanyutixian: "About withdrawal",
  },
  Recargar: {
    title: "Recharge",
    Introducir: "Please enter the recharge amount",
    tps: "Minimum recharge amount ",
    Selección: "Quick select amount",
    text: "Tip: the bank platform account changes at any time. For each deposit, please go to the page where you fill in the information and indicate the latest account number. If you deposit into an expired account, we are not responsible!",
  },
  RecargarAction: {
    title: "Submit recharge",
    Cantidad: "Recharge amount",
    Información: "Payee information",
    tps: "After the transfer is completed, please be sure to upload the transfer screenshot first, and then click the submit button!",
    jietu: "Upload transfer screenshot",
    querenchongzhi: "Confirm recharge",
  },
  Recolectar: {
    title: "Collect orders",
    Pedidos: "Order Commission",
    yongjin: "commission",
    jinrishouru: "Income today",
    gerenshouru: "Personal income",
    yiwancheng: "Completed order",
    dongjie: "Freeze order",
    weiwancheng: "Outstanding orders",
    dongjiejine: "Frozen amount",
    dangqianyue: "Current balance",
    kaishi: "Start-collection",
    tingzhi: "Stop-collection",
    tishi: "Tips",
    Segundo: "Second",
    text: "After the user completes the order, the platform will send the order information to the seller at the same time. If the user does not pay the order within a certain period of time, the order will be frozen to avoid the supervision of the online shopping platform. Once the order is frozen, the money in the order is also frozen. You must wait for the system to thaw them in a few hours. Remember this.",
  },
  Registro: {
    title: "Record",
    daizhifu: "To be paid",
    yijiesuan: "Settled",
    chulizhong: "Processing",
    yidongjie: "Frozen",
    meiyoujilu: "No record",
    xianzaishouji: "Now collect",
    jiage: "Price",
    yongjin: "Commission",
    shouyi: "Profit",
    shengyutime: "Remaining Time",
    chakan: "View order",
    weizhifu: "Unpaid",
    fukuan: "Payment",
  },
  Detalles: {
    title: "Order details",
    yigong: " Altogether",
    dingdan: " Orders",
    dangqian: "Currently the second",
    yiwancheng: "Order completed",
    dongjie: "Order frozen",
    dengdai: "Waiting for the buyer to confirm the order",
    dongjies: "frozen",
    zhuyao: "Main store",
    guanfang: "Official guarantee",
    zongshuliang: "Total quantity",
    daigou: "Purchasing fee",
    shouyi: "Profit",
    fukuanzt: "Payment status",
    weizhifu: "Unpaid",
    jiedong: "Thawing time",
    zhifu: "Payment",
    yizhifu: "Paid",
    shuruzhifu: "Please enter the payment password",
    yanzheng: "Verify payment password",
  },
  Mex: "",
  Confirmar: "Confirm",
  Conectando: "Loading",
  Pofile: {
    title: "account details",
    yaoqingma: "Invitation code",
    fuzhi: "Copy link",
    Balance: "Capital",
    tixian: "Withdrawal",
    chongzhi: "Recharge",
    zongji: "Total",
    jinrong: "Finance",
    xiangqing: "Details",
    dongjie: "Frozen",
    geren: "Personal",
    tuandui: "Team",
    yaoqing: "Invitation",
    xiaoxi: "Message",
    dizhi: "Address",
    guanyu: "About us",
    renwu: "Invite task",
    anquan: "Security",
    fuwu: "Service Centre",
  },
  alert: {
    title: "Tips",
    quxiao: "Cancel",
    queding: "OK",
    acuerdo: "Agree",
    fuzhi: "Copy",
    qianwang: "GO",
  },
  fuzhiOK: "Copied to pasteboard",
  withdrawal: {
    title: "Withdrawal",
    yue: "Current balance",
    shouxufei: "Cash withdrawal fee",
    dancitixian: "Minimum handling charge for single withdrawal",
    shuru: "Please enter the amount",
    dangqianjine: "Withdrawal amount of current account",
    suoyou: "Extract all",
    zhanghuming: "Account name",
    zhanghu: "Account",
    dianhua: "Telephone number",
    tps: "Tips",
    text: "1.Please fill in the bank information accurately. We will not bear any responsibility for the capital loss caused by the possible error when you enter the information; Before 2:24 the next day, the specific arrival time shall be subject to the bank",
    queren: "Confirm withdrawal",
    guanlian: "Please associate your bank card before withdrawal",
  },
  prsonal: {
    title: "Personal",
    name: "Full name",
    shouji: "Telephone number",
    yhk: "Bank card",
    tuichu: "Log out",
    tianxie: "Fill in",
  },
  card: {
    title: "Bank card settings",
    zhanghuxinxi: "Account information",
    tps: "Please make sure your account number is correct. We are not responsible for any error。",
    yhmc: "Bank name",
    zhanghuming: "User name",
    zhanghu: "Account",
    shouji: "Telephone number",
    fenhang: "Name of Bank branch",
    baocun: "Preservation",
    jianyi: "Proposal",
    text: "Please contact the online customer service for the bank card binding method in case it needs to be modified!",
    qyh: "Please select a ",
    qsryhm: "Please enter the bank user name",
    qzh: "Please enter your account",
    qdh: "Please enter the phone number",
    qfhmc: "Please enter the branch name",
    qsrifcs: "Please enter IFSC",
    youxiang: "Email",
    plyx: "Please enter email",
    jgbh: "Organization number",
    pljgbh: "Please enter organization number",
    gjj: "CPF",
    plgjj: "Please enter ",
    isbp: "ISBP",
    plisbp: "Please enter ISBP",
    pixtype: "PIXType",
    plpixtype: "Please enter PIXType",
    pix: "PIX",
    plpix: "Please enter PIX",
    evp: "EVP",
    plevp: "Please enter EVP",
  },
  team: {
    title: "Team",
    Directo: "Direct",
    Secundario: "Secondary",
    Terciario: "Third",
    Comisión: "Today's Committee",
    zongyongjin: "Total Commission",
    shuzi: "Number",
    sudu: "Speed",
    jinricz: "Recharge today",
    tixian: "Full withdrawal",
  },
  invitation: {
    title: "Invite friends",
    notitle: "You can do part-time work through your mobile phone",
    notitles: "Invite friends to make more money together",
    yqm: "Invitation code",
    fuzhi: "Copy",
    xzyq: "Now invite",
  },
  address: {
    title: "Address management",
    xm: "Full name",
    dianhua: "Telephone",
    qtx: "Please fill in",
    dizhixq: "Receiving address information",
    baocun: "Preservation",
  },
  invitationTask: {
    title: "Invite task",
    zongren: "Total number",
    gengxin: "Update task: the overloaded user is a valid user",
    yq: "invitation",
    jige: "Valid members",
    qianwang: "GO",
    renwujiangli: "Task reward ",
  },
  safe: {
    aqgl: "security management",
    denglu: "Login password",
    zfmm: "Payment password",
    bh: "Protect",
  },
  password: {
    title: "Login password",
    titles: "Payment password",
    shouji: "Telephone number",
    yanzheng: "Verification Code",
    fasong: "Send",
    xinmima: "New password",
    pxinmima: "Enter new password",
  },
  record: {
    title: "Details",
    quanbu: "Whole",
    chongzhi: "Recharge",
    tixian: "Withdrawal",
    dingdan: "Order",
    tuandui: "Team",
    zongsu: "Total",
    dangqian: "Current data",
    kaishisj: "Start time",
    jieshusj: "End time",
  },
  login: {
    zhanghu: "Please enter your account",
    mima: "Please input a password",
    denglu: "Sign in",
    zhuce: "Register now",
    wjmm: "I forgot my password",
  },
  regist: {
    touxiang: "head portrait",
    yhm: "User name",
    mima: "Password",
    zhifu: "Payment Password",
    yq: "invitation",
    pyhm: "Please fill in a user name",
    ptel: "Please fill in the telephone number",
    shuru: "Please input a password",
    shurupay: "Enter 6 digit numbers",
    pyqm: "Invitation code",
    zhuce: "register",
    yijing: "I already have an account？",
    plyqm: "Please enter the invitation code",
  },
  tabbars: {
    shouye: "Home",
    chongzhi: "Recharge",
    shouji: "Collect",
    dingdan: "Order",
    wode: "My",
  },
  message: {
    title: "customer service",
    go: "Contact",
  },
  zhaohuimima: "Please contact customer service for processing",
  yanzhengzhifu: "Verify payment password",
  huanying: "Welcome",
  yinhang: "Bank",
  yinhangbh: "Bank number",
  beizhu: "Remark",
  zhifufs:'Payment method',
  qxzzffs:'Please select payment method',
};
export default in_en;
