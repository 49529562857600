<template>
  <div class="forget">
    <div class="main">
      <div class="title">{{$t('login.wjmm')}}</div>
      <div class="evan-form-container items">
        <div class="item">
          <div class="evan-form-item-container evan-form-item-container--left">
            <div class="evan-form-item-container__main">
              <div class="inputType">{{ mobile_prefix }}</div>
              <div class="form-input">
                <input
                  type="number"
                  :placeholder="$t('regist.ptel')"
                  v-model="mobile"
                />
              </div>
              <div class="getCode" @click="SmsSend">{{$t('password.fasong')}}</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="evan-form-item-container evan-form-item-container--left">
            <div class="evan-form-item-container__main">
              <div class="form-input">
                <input
                  type="number"
                  :placeholder="$t('password.yanzheng')"
                  v-model="code"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="evan-form-item-container evan-form-item-container--left">
            <div class="evan-form-item-container__main">
              <van-field
                right-icon="eye-o"
                 :placeholder="$t('password.pxinmima')"
                v-model="pass"
                :type="ConType"
                @click-right-icon="ChangfeConType"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="btn" @click="ChangePass">{{$t('Confirmar')}}</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
export default {
  name: "Forget",
  components: {},
  data() {
    return {
      mobile: "",
      code: "",
      pass: "",
      mobile_prefix: "",
      ConType: "password",
    };
  },
  methods: {
    ChangfeConType() {
      if (this.ConType == "password") {
        this.ConType = "text";
      } else {
        this.ConType = "password";
      }
    },
    //   zhaohuimima
    ChangePass() {
      if (this.mobile && this.code && this.pass) {
        this.$toast.loading({
                 message: this.$t('Conectando'),
          forbidClick: true,
          duration: 0,
        });
        userApi
          .forGet(this.mobile, this.code, this.pass)
          .then((data) => {
            console.log(data.data, "找回密码短信");
            this.$toast.clear();
            this.$router.go(-1);
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.message);
          });
      }
    },
    // 发送短信
    SmsSend() {
      if (this.mobile) {
        this.$toast.loading({
                 message: this.$t('Conectando'),
          forbidClick: true,
          duration: 0,
        });
        userApi
          .sendSms(this.mobile)
          .then((data) => {
            console.log(data.data, "发送短信");
            this.code = data.data[0];
            this.$toast.clear();
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.message);
          });
      }
    },
  },
  created() {
    this.mobile_prefix = this.$route.query.mobile;
  },
};
</script>
<style lang="less" scoped>
.main {
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  font-size: 0.37rem;
  width: 100%;
  height: 100vh !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
}
.title {
  font-size: 0.64rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  text-align: center;
  padding: 2.13rem 0.85rem 0.8rem;
}
.items {
  padding: 0 0.85rem;
}
.item {
  height: 1.33rem;
  line-height: 1.33rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.items .evan-form-item-container {
  border-bottom: 1px solid #eeeff2;
}
.evan-form-item-container--left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.evan-form-item-container__main {
  flex: 1;
  min-height: 1.2rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.inputType {
  width: 2.4rem;
  text-align: center;
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
}
.form-input {
  width: 100%;
  height: 100%;
  line-height: 1.4em;
  color: #fff;
  input {
    caret-color: #017aff;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
}
.getCode {
  color: #000;
  font-size: 0.37rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  width: 1.07rem;
  height: 90%;
  display: block;
  background: #ddd;
  text-align: center;
}
.van-cell {
  padding: 0;
  background: none;
}
:deep(input::placeholder) {
  opacity: 0.5;
  color: #fff;
}
.btn {
  height: 1.28rem;
  line-height: 1.28rem;
  text-align: center;
  background: #56b3f8;
  font-size: 0.43rem;
  color: #fff;
  border-radius: 0.53rem;
  border: 1px solid #56b3f8;
  margin: 0.8rem 0.85rem 0;
}
</style>