<template>
  <div class="p-3">
    <!-- Header -->
    <div class="mb-5 flex justify-between items-center">
      <div class="flex space-x-5">
        <div
          class="rounded-full bg-b-2 w-11 h-11 flex justify-center items-center drop-shadow-md"
        >
          <span class="material-symbols-outlined text-p-1"> face_6 </span>
        </div>
        <div class="text-sm flex flex-col items-left justify-center">
          <div class="font-bold">{{ UserInfo.nickname }}</div>
          <div class="text-xs text-t-2">ID: {{ UserInfo.id }}</div>
        </div>
      </div>
      <div>
        <div
          class="p-1 bg-p-1 text-p-3 rounded flex justify-between items-center text-xs space-x-1"
        >
          <span class="material-symbols-outlined text-xs">
            shopping_basket
          </span>
          <span
            >{{ $t("Recolectar.yongjin") }}
            {{ InfoList.day_max_commission_rate || 0 }}%</span
          >
        </div>
      </div>
    </div>

    <!-- 订单信息 -->
    <div class="mb-5">
      <!-- <div class="flex justify-between items-center mb-3">
        <div>
          <div class="text-sm "> {{ $t("Recolectar.dangqianyue") }}</div>
          <div class="text-3xl ">
            <span class="text-xl">{{ $t("Mex") }}{{ monetary_unit }}</span>
            <span class="">{{ InfoList.money || 0 }}</span>
          </div>
        </div>
        <div class="p-1 bg-p-1 text-p-3 rounded flex justify-between items-center text-xs space-x-1">
          <span class="material-symbols-outlined  text-xs">
            shopping_basket
          </span>
          <span>{{ $t("Recolectar.yongjin") }} {{ InfoList.day_max_commission_rate || 0 }}%</span>
        </div>
      </div> -->
      <div class="grid grid-cols-2 gap-1 text-sm" @click="jumpTo('/orders')">
        <div class="p-2 bg-b-2 rounded">
          <p class="font-light space-x-1">
            <span>{{ $t("Recolectar.jinrishouru") }}</span
            ><span class="text-xs">({{ $t("Mex") }}{{ monetary_unit }})</span>
          </p>
          <p class="font-bold">{{ InfoList.dayProfit || 0 }}</p>
        </div>
        <div class="p-2 bg-b-2 rounded">
          <p class="font-light">{{ $t("Recolectar.yiwancheng") }}</p>
          <p class="font-bold">{{ InfoList.counted }}</p>
        </div>
        <div class="p-2 bg-b-2 rounded">
          <p class="font-light">{{ $t("Recolectar.dongjie") }}</p>
          <p class="font-bold">{{ InfoList.freeze || 0 }}</p>
        </div>
        <div class="p-2 bg-b-2 rounded">
          <p class="font-light">{{ $t("Recolectar.weiwancheng") }}</p>
          <p class="font-bold">{{ InfoList.unfinished || 0 }}</p>
        </div>
        <div class="p-2 bg-b-2 rounded">
          <p class="font-light space-x-1">
            <span>{{ $t("Recolectar.dongjiejine") }}</span
            ><span class="text-xs">({{ $t("Mex") }}{{ monetary_unit }})</span>
          </p>
          <p class="font-bold">{{ InfoList.freezeMoney || 0 }}</p>
        </div>
        <div class="p-2 bg-b-2 rounded">
          <p class="font-light space-x-1">
            <span>{{ $t("Recolectar.dangqianyue") }}</span
            ><span class="text-xs">({{ $t("Mex") }}{{ monetary_unit }})</span>
          </p>
          <p class="font-bold">{{ InfoList.money || 0 }}</p>
        </div>
      </div>
    </div>

    <!-- 滚动展示 -->

    <!-- 开始抢单 -->
    <div class="mb-5">
      <div
        class="flex py-2 bg-p-1 text-p-3 rounded-md justify-center items-center text-center space-x-1"
      >
        <span class="material-symbols-outlined text-base font-bold">
          add_shopping_cart
        </span>
        <span class="text-sm" @click="JumpOrder">{{
          $t("Recolectar.kaishi")
        }}</span>
      </div>
    </div>

    <div class="p-3 space-y-1">
      <p class="text-sm font-normal flex space-x-1">
        <span class="text-sm material-symbols-outlined">
          notifications_active
        </span>
        <span>{{ $t("Recolectar.tishi") }}</span>
      </p>
      <p class="text-xs font-light leading-normal text-t2">
        {{ $t("Recolectar.text") }}
      </p>
    </div>

    <tabbar />

    <!-- 选择器 -->
    <van-popup
      v-model:show="show"
      @click="show = false"
      round
      position="bottom"
    >
      <van-picker
        show-toolbar
        :columns="selectList"
        @confirm="onConfirm"
        @cancel="onCancel"
        :confirm-button-text="$t('alert.acuerdo')"
        :cancel-button-text="$t('alert.quxiao')"
      />
    </van-popup>
    <!-- 错误提示 -->
    <van-dialog
      v-model:show="ShowInvitation"
      :title="$t('alert.title')"
      show-cancel-button
      :confirmButtonText="$t('alert.queding')"
      :cancelButtonText="$t('alert.quxiao')"
      cancelButtonColor="#000000"
      confirmButtonColor="#007aff"
    >
      <div class="p-2 text-sm font-light">{{ TpsTxt }}</div>
    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import orderApi from "@/api/order";
import storeAction from "@/store/typed-actions";

// import vueSeamlessScroll from "vue-seamless-scroll/src";
import Tabbar from "@/components/tabbar/tabbar.vue";
import { jumpTo } from "@/utils/routerUtils";

export default {
  name: "Recolectar",
  components: {
    Tabbar,
    // vueSeamlessScroll,
  },
  data() {
    return {
      columns: ["Falabella"],
      show: false,
      InfoList: "",
      // selectList: [],
      // selectListActive: "",
      // selectListActiveID: "",
      ShowInvitation: false,
      TpsTxt: "",
      isOrder: true,
      // selectListID: [],
      Info: "",
      timeOder: null,
      bgColor: "",
    };
  },
  computed: {
    UserInfo() {
      console.log(this.$store.state.currentUser);
      return JSON.parse(this.$store.state.currentUser);
    },
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    memberInfo() {
      console.log(this.$store.state.memberInfo);
      return this.$store.state.memberInfo;
    },
    meberApishow() {
      console.log(this.$store.state.meberApishow);
      return this.$store.state.meberApishow;
    },
    selectListActive() {
      console.log(this.$store.state.ShopTilte);
      return this.$store.state.ShopTilte;
    },
    selectListActiveID() {
      return this.$store.state.selectListActiveID;
    },
    selectList() {
      return this.$store.state.ShopList;
    },
    selectListID() {
      return this.$store.state.ShopListID;
    },
    ShowApiSns() {
      return this.$store.state.ShowApiSns;
    },
  },
  methods: {
    handleJumpTo(path) {
      jumpTo(path);
    },

    JumpGo() {
      this.$router.push({
        path: "/",
      });
    },
    // 收集订单
    JumpOrder() {
      let that = this;
      that.isOrder = true;
      console.log(that.timeOder);
      if (that.timeOder) {
        return;
      }
      const toast = that.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: false,
        message: "3" + this.$t("Recolectar.Segundo"),
      });
      let second = 3;
      that.timeOder = setInterval(() => {
        second--;
        if (second) {
          toast.message = `${second}${that.$t("Recolectar.Segundo")}`;
        } else {
          clearInterval(that.timeOder);
          // 手动清除 Toast
          that.$toast.clear();
          that.timeOder = null;
          // 调用接口
          if (that.isOrder) {
            that.snatchList();
          }
        }
      }, 1000);
      // this.$router.push({
      //   path: "/order",
      // });
    },
    Canlce() {
      this.isOrder = false;
      this.$toast.clear();
      clearInterval(this.timeOder);
      this.timeOder = null;
    },
    // 收集订单接口
    snatchList() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      orderApi
        .snatch(this.selectListActiveID)
        .then((data) => {
          // console.log(data.data);
          let goods = data.data.order_goods;
          if (
            data.data.order.total_goods_count != null &&
            data.data.order.total_goods_count != undefined
          ) {
            goods["goodsNum"] = data.data.order.total_goods_count;
          }
          if (
            data.data.order.address_name != null &&
            data.data.order.address_name != undefined
          ) {
            goods["address"] = {
              address_mark: data.data.order.address_mark,
              address_name: data.data.order.address_name,
              address_mobile: data.data.order.address_mobile,
            };
          } else {
            goods["address"] = "";
          }
          this.$router.push({
            path: "/orderDetail",
            query: {
              goods: JSON.stringify(goods),
            },
          });
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.TpsTxt = err.message;
          // this.$toast.fail(err.message);
          this.ShowInvitation = true;
        });
    },
    onConfirm(value, index) {
      console.log(index, this.selectListID);
      // this.selectListActiveID = this.selectListID[index];
      storeAction.setsessionStorage(
        "selectListActiveID",
        this.selectListID[index]
      );
      storeAction.setCurrentselectListActiveID(this.selectListID[index]);
      storeAction.updateShopTilte(value);
      // this.selectListActive = value;
    },
    // 上半部信息
    infoList() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .userInfo()
        .then((data) => {
          this.$toast.clear();
          this.InfoList = data.data;
          console.log(data.data, "个人信息");
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    snatchInfo() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      orderApi
        .snatchInfo()
        .then((data) => {
          storeAction.setsessionStorage("selectListActiveID", data.data[0].id);
          storeAction.setCurrentselectListActiveID(data.data[0].id);
          storeAction.updateShopTilte(data.data[0].title);
          let selectList = [],
            selectListID = [];
          for (let i = 0; i < data.data.length; i++) {
            selectList.push(data.data[i].title);
            selectListID.push(data.data[i].id);
          }
          storeAction.updateShop(selectList);
          storeAction.updateShopListID(selectListID);
          storeAction.updateShowApiSns(false);
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.TpsTxt = err.message;
          this.ShowInvitation = true;
        });
    },
    info() {
      userApi
        .heart()
        .then((data) => {
          console.log(data.data);
          for (let i = 0; i < data.data.profitNoticeList.length; i++) {
            data.data.profitNoticeList[i]["auth"] = data.data.profitNoticeList[
              i
            ].time.charAt(data.data.profitNoticeList[i].time.length - 1);
          }
          storeAction.updateMemberInfo(data.data.profitNoticeList);
          storeAction.updateMemberApishow(false);
        })
        .catch((err) => {
          this.$toast.fail(err.message);
        });
    },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    this.infoList();
    if (this.ShowApiSns) {
      this.snatchInfo();
    }
    if (this.meberApishow) {
      this.info();
    }
  },
};
</script>
<style lang="less" scoped>
.recolectar {
  background: #f9f8f8;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

// .lineDiv {
//   width: 100%;
//   height: 0.03rem;
//   background: #fff;
// }

.statisticalbg {
  color: #000;
  // background: linear-gradient(90deg, #6dcab8, #3a78cb);
  padding-bottom: 0.27rem;
  padding-top: 0.27rem;
}

.statistical {
  height: 7.36rem;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgb(19 20 21 / 15%);
  border-radius: 0.32rem;
  margin: 0 0.27rem;
}

.classification {
  display: flex;
  align-items: center;
}

.main-title {
  padding: 0.4rem 0.53rem;
  font-size: 0.37rem;
  position: relative;
  font-family: PingFangSC-Medium, PingFang SC;
  text-align: left;
  font-weight: 500;
  color: #334360;
  display: flex;
  width: 53%;
}

.main-title::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-left: 5px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  right: 0;
  width: 5px;
  height: 5px;
  border: 2px solid transparent;
  border-top-color: #000;
  border-right-color: #000;
}

.maginconTP {
  background: #d6b075;
  border-radius: 4px;
  padding: 0 5px;
  text-align: center;
  line-height: 0.48rem;
  margin-left: 0.27rem;

  span {
    font-size: 0.27rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.item {
  border-bottom: 1px solid #eeeff2;
  display: flex;
  align-items: center;
  margin: 0 0.53rem;
}

.item-left {
  width: 50%;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #23292e;
    text-align: left;
  }

  .value {
    color: #000;
    font-size: 0.4rem;
    display: flex;
    align-items: center;
    padding: 0 0 0.13rem;
  }

  .number {
    font-size: 0.64rem;
    font-family: DINPro-Medium, DINPro;
    font-weight: 500;
    color: #23292e;
  }
}

.item .item-left ~ .item-left {
  margin-left: 0.4rem;
}

.item:last-child {
  border: none;
}

.notice {
  padding: 0 0.4rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconfont {
  margin-right: 0.27rem;
  width: 0.61rem;
  height: 0.67rem;
}

.noticeList {
  height: 1.28rem;
  width: 100%;
}

.btn {
  margin: 0.37rem 0.4rem 0;
  color: #fafafa;
  height: 0.91rem;
  line-height: 0.91rem;
  text-align: center;
  border-radius: 0.53rem;
  box-sizing: border-box;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  font-size: 0.35rem;
}

.stop {
  color: #1e7eee;
  background: none;
  border: 1px solid #1e7eee;
}

.card {
  background: #fff;
  margin-top: 0.27rem;

  .title {
    height: 0.99rem;
    line-height: 0.99rem;
    border-bottom: 1px solid #eee;
    margin: 0 0.4rem;
    text-align: left;
  }

  .title span {
    color: #1e7eee;
    font-size: 0.29rem;
  }

  .item {
    padding: 0.4rem;
    color: #8799a3;
    font-size: 0.35rem;
    line-height: 160%;
    text-align: left;
    padding-bottom: 0.8rem;
  }
}

.bdbd_item {
  padding: 0 0.16rem;
  line-height: 1.07rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  font-size: 0.37rem;
}

.bdbd_item div {
  font-size: 0.32rem;
}

.bdbd_item div:nth-of-type(2) {
  color: #7c61fd;
}

.vux-marquee {
  width: 100%;
  overflow: hidden;
  height: 1.28rem;
}
</style>