

<template>
  <!-- component -->
  <div class="min-h-screen bg-b-2 flex justify-center items-center">
    <div class="absolute w-60 h-60 rounded-xl bg-purple-300 -top-5 -left-16 z-0 transform rotate-45 hidden md:block">
    </div>
    <div class="absolute w-48 h-48 rounded-xl bg-purple-300 -bottom-6 -right-10 transform rotate-12 hidden md:block">
    </div>
    <div class="py-12 px-12 bg-white rounded-2xl shadow-xl z-20">
      <div>
        <h1 class="text-3xl font-bold text-center mb-4 cursor-pointer">{{ $t('huanying') }}</h1>
        <!-- <p class="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer">Create an
                  account to enjoy all the services without any ads for free!</p> -->
      </div>
      <div class="space-y-4">
        <input type="text" v-model="nickname" :placeholder="$t('login.zhanghu')"
          class="block text-sm py-3 px-4 rounded-lg w-full border outline-none" />
        <input type="password" v-model="pass" :placeholder="$t('login.mima')"
          class="block text-sm py-3 px-4 rounded-lg w-full border outline-none" />
      </div>
      <div class="text-center mt-6">
        <button class="py-3 w-64 text-xl text-white bg-p-1 rounded-2xl" @click="ChangeLogin">{{ $t("login.denglu")
        }}</button>
        <!-- <p class="mt-4 text-sm"><span class="underline cursor-pointer" @click="JumpRegist">{{ $t("login.zhuce") }}</span>
        </p> -->
      </div>
    </div>
    <div class="w-40 h-40 absolute bg-purple-300 rounded-full top-0 right-12 hidden md:block"></div>
    <div class="w-20 h-40 absolute bg-purple-300 rounded-full bottom-20 left-10 transform rotate-45 hidden md:block">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import storeAction from "@/store/typed-actions";
import homeApi from "@/api/home";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      nickname: "",
      pass: "",
      Title: "",
      mobile_prefix: "",
    };
  },
  methods: {
    JumpForget() {
      let a = 1;
      if (a == 1) {
        this.$toast({
          message: this.$t("zhaohuimima"),
          forbidClick: true,
          duration: 2000,
        });
        return;
      }
      this.$router.push({
        path: "/forget",
        query: {
          mobile: this.mobile_prefix,
        },
      });
    },
    JumpRegist() {
      this.$router.push({
        path: "/regist",
        query: {
          mobile: this.mobile_prefix,
        },
      });
    },
    ChangeLogin() {
      if (this.nickname && this.pass) {
        this.$toast.loading({
          message: this.$t("Conectando"),
          forbidClick: true,
          duration: 0,
        });
        userApi
          .login(this.nickname, this.pass)
          .then((data) => {
            this.$toast.clear();
            console.log(data.data, "登录成功");
            storeAction.loginDone(this, data.data);
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.message);
          });
      }
    },
  },
  created() {
    this.$toast.loading({
      message: this.$t("Conectando"),
      forbidClick: true,
      duration: 0,
    });
    homeApi
      .siteInfo()
      .then((data) => {
        this.$toast.clear();
        console.log(data.data, "基础数据");
        storeAction.setCurrentMonetary_unit(data.data.monetary_unit);
        document.title = data.data.site_title
        storeAction.setsessionStorage("monetary_unit", data.data.monetary_unit);
        storeAction.setsessionStorage("site_title", data.data.site_title);
        this.mobile_prefix = data.data.mobile_prefix;
        this.Title = data.data.site_title;
      })
      .catch((err) => {
        this.$toast.clear();
        this.$toast.fail(err.message);
      });
  },
};
</script>
