<template>
  <div class="address">
    <header>
      <van-nav-bar :title="$t('address.title')" :style="bgColor">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>
    <div class="main">
      <div class="evan-form-container items">
        <div class="item">
          <div class="evan-form-item-container evan-form-item-container--left">
            <div
              class="
                evan-form-item-container__label
                showAsteriskRect
                isRequired
              "
            >
              {{ $t("address.xm") }}:
            </div>
            <div class="evan-form-item-container__main">
              <input
                type="text"
                class="Input"
                :placeholder="$t('address.qtx')"
                v-model="name"
              />
            </div>
          </div>
        </div>

        <div class="item">
          <div class="evan-form-item-container evan-form-item-container--left">
            <div
              class="
                evan-form-item-container__label
                showAsteriskRect
                isRequired
              "
            >
              {{ $t("address.dianhua") }}:
            </div>
            <div class="evan-form-item-container__main">
              <input
                type="text"
                class="Input"
                v-model="mobile"
               :placeholder="$t('address.qtx')"
              />
            </div>
          </div>
        </div>

        <div class="item">
          <div class="evan-form-item-container evan-form-item-container--left">
            <!-- <div class="evan-form-item-container__label showAsteriskRect isRequired">Teléfono:</div> -->
            <div class="evan-form-item-container__main">
              <div class="textarea-wrapper">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="Input textInput"
                  v-model="mark"
                  :placeholder="$t('address.dizhixq')"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="save" :style="bgColor" @click="save">{{ $t("address.baocun") }}</div>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Address",
  components: {
    Tabbar,
  },
  data() {
    return {
      info: null,
      name: "",
      mobile: "",
      mark: "",
      bgColor:''
    };
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    addres() {
      userApi
        .address()
        .then((data) => {
          // this.info = data.data;
          this.name = data.data.name;
          (this.mobile = data.data.mobile), (this.mark = data.data.mark);
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    save() {
      this.$toast.loading({
          message: this.$t('Conectando'),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .updateAddress(this.name, this.mobile, this.mark)
        .then((data) => {
          console.log(data.data);
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
  created() {
     this.bgColor = localStorage.getItem("bgColor");
    this.$toast.loading({
         message: this.$t('Conectando'),
      forbidClick: true,
      duration: 0,
    });
    this.addres();
  },
};
</script>
<style lang="less" scoped>
.address {
  background: #f7f7f7;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
.main {
  padding: 0 0.4rem 0.4rem;
  box-sizing: border-box;
}
.items {
  background: #fff;
  border-radius: 5px;
  padding: 0.4rem 0.4rem 0.03rem 0.4rem;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.07rem;
  line-height: 1.07rem;
  margin-bottom: 0.27rem;
  position: relative;
}
.evan-form-item-container {
  width: 100%;
  height: 100%;
  border-bottom: 0.5px solid #eee;
}
.items .item:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  position: absolute;
  bottom: 0;
  left: 0;
}
.evan-form-item-container__label {
  font-size: 0.37rem;
  color: #666;
  line-height: 0.53rem;
  padding: 0.32rem 0;
  display: inline-block;
}
.evan-form-item-container__label.showAsteriskRect::before {
  content: "*";
  color: #f56c6c;
  width: 0.27rem;
  display: inline-block;
}
.evan-form-item-container__main {
  flex: 1;
  min-height: 1.2rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  input {
    padding-left: 0.27rem;
  }
}
.Input {
  width: 100%;
  height: 100%;
  caret-color: #017aff;
}
.Input::placeholder {
  color: grey;
}
.evan-form-item-container--left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.textarea-wrapper {
  width: 100%;
  height: 100%;
  .textInput {
    line-height: normal;
    -webkit-user-select: auto;
  }
}

.items .item:last-child {
  height: 5.33rem;
}
.save {
  color: #fff;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  border-radius: 0.53rem;
  text-align: center;
  padding: 0.27rem 0;
  margin: 0.8rem 0;
}
</style>