<template>
  <div class="team">
    <header>
      <van-nav-bar :title="$t('team.title')" :style="bgColor">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>
    <div class="main">
      <div class="record-tabbar">
        <div
          class="list"
          :class="action == 0 ? 'action' : ''"
          @click="actionChange(0)"
        >
          {{ $t("team.Directo") }}
        </div>
        <div
          class="list"
          :class="action == 1 ? 'action' : ''"
          @click="actionChange(1)"
        >
          {{ $t("team.Secundario") }}
        </div>
        <div
          class="list"
          :class="action == 2 ? 'action' : ''"
          @click="actionChange(2)"
        >
          {{ $t("team.Terciario") }}
        </div>
      </div>
      <div class="statisticalbg">
        <div class="statistical">
          <div class="item flex-row">
            <div class="item-left">
              <div class="title">{{ $t("team.Comisión") }}</div>
              <div class="value">{{ monetary_unit }}{{ list.profit }}</div>
            </div>
            <div class="item-left">
              <div class="title">{{ $t("team.zongyongjin") }}</div>
              <div class="value">{{ monetary_unit }}{{ list.profitAll }}</div>
            </div>
          </div>
          <div class="item flex-row">
            <div class="item-left">
              <div class="title">{{ $t("team.shuzi") }}</div>
              <div class="value">{{ list.rushOrder }}</div>
            </div>
            <div class="item-left">
              <div class="title">{{ $t("team.sudu") }}</div>
              <div class="value">{{ list.rushOrderALL }}</div>
            </div>
          </div>
          <div class="item flex-row">
            <div class="item-left">
              <div class="title">{{ $t("team.jinricz") }}</div>
              <div class="value">{{ list.payQuantity }}</div>
            </div>
            <div class="item-left">
              <div class="title">{{ $t("team.tixian") }}</div>
              <div class="value">{{ list.payQuantityAll }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Team",
  components: {
    Tabbar,
  },
  data() {
    return {
      action: 0,
      classs: 1,
      list: "",
      bgColor:''
    };
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    actionChange(e) {
      this.action = e;
      console.log(e);
      if (e == 0) {
        this.classs = 1;
      } else if (e == 1) {
        this.classs = 2;
      } else if (e == 2) {
        this.classs = 3;
      }
      this.info();
    },
    info() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      userApi
        .team(this.classs)
        .then((data) => {
          console.log(data.data);
          this.list = data.data;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
  created() {
      this.bgColor = localStorage.getItem("bgColor");
    this.info();
  },
};
</script>
<style lang="less" scoped>
.team {
  background: #fff;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
.record-tabbar {
  height: 1.33rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.list {
  padding: 0.11rem 0.4rem;
  font-size: 0.37rem;
  text-align: center;
}
.action {
  color: #fff;
  font-weight: 700;
  border-radius: 20px;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
}
.statisticalbg {
  color: #fff;
  margin: 0.4rem;
  padding: 0.4rem;
  border-radius: 5px;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
}
.item {
  margin-bottom: 0.27rem;
  border-bottom: 1px solid #b79efe;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-left {
  width: 50%;
}
.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.value {
  color: #fff;
  font-size: 0.37rem;
  padding: 0 0 0.27rem;
  align-items: flex-start;
  display: flex;
  font-weight: 700;
}
.statisticalbg .statistical .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
</style>