<template>
    <div>
        <header>
            <van-nav-bar left-arrow @click-left="JumpGo" class="bg-b-2 text-t-1">
                <template #left>
                    <van-icon name="arrow-left" size="20" />
                </template>
                <template #title>
                    <span>{{ $t("selectChannel.title") }}</span>
                </template>
            </van-nav-bar>
        </header>

        <div class="m-2">
            <div @click="to('/recharge')" class="flex bg-a-2 py-3 my-2 items-center justify-center shadow-md rounded-md">
                <div class="text-base">{{ $t("selectChannel.cashChannel") }}</div>
            </div>
            <div v-if="payUsdt" @click="to('/usdtRecharge')" class=" bg-a-2 py-3 my-2 shadow-md rounded-md">
                <div class="flex items-center justify-center space-x-2 "> <img src="@/assets/image/tether-usdt.png" alt=""
                        class="w-3.5 h-3.5" />
                    <div class="text-base">{{ $t("selectChannel.usdtChannel") }}</div>
                </div>
                <div class="text-base text-red-500 font-bold text-center">{{ $t("selectChannel.rewards") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bgColor: '',
            payUsdt: '',
        }
    },
    created() {
        this.bgColor = localStorage.getItem("bgColor");
        this.payUsdt = localStorage.getItem('pay_usdt') != '' ? localStorage.getItem('pay_usdt') : false;
    },
    methods: {
        to(path) {
            console.log(path);
            this.$router.push({
                path: path,
            });
        },
        JumpGo() {
            this.$router.push({
                path: "/",
            });
        },
    },
}
</script>

<!-- <style  scoped>
.van-nav-bar {
    background: #000;
}
</style> -->