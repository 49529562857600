<template>
  <van-tabbar v-model="active" :placeholder="true" route fixed>
    <van-tabbar-item v-for="(item, index) in menu" :key="index" :to="item.to">
      <span> {{ $t(item.name) }}</span>
      <template #icon><span class="material-symbols-outlined">{{ item.iconText }}</span></template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { ref, reactive } from 'vue';

export default {
  setup() {
    const active = ref(0);

    const menu = reactive([
      {
        name: 'tabbars.shouye',
        iconText: 'home',
        to: { name: 'Home' },
      },
      {
        name: 'tabbars.shouji',
        iconText: 'task_alt',
        to: { name: 'Recolectar' },
      },
      {
        name: 'tabbars.dingdan',
        iconText: 'list_alt',
        to: { name: 'Registro' },
      },
      {
        name: 'tabbars.wode',
        iconText: 'person',
        to: { name: 'Pofile' },
      },
    ]);
    return {
      active,
      menu,
    };
  },
};



</script>
<style scoped></style>



<!-- <template>
  <van-tabbar
    v-model="active"
    placeholder="true"
    @change="onChange"
    active-color="#4e4e4e"
    inactive-color="#4e4e4e"
  >
    <van-tabbar-item to="/" replace>
      <span>{{$t('tabbars.shouye')}}</span>
      <template #icon="props">
        <img
          :src="props.active ? icon[0].active : icon[0].inactive"
          class="iconT"
        />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/recharge">
      <span>{{$t('tabbars.chongzhi')}}</span>
      <template #icon="props">
        <img
          :src="props.active ? icon[1].active : icon[1].inactive"
          class="iconT"
        />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/recolectar" class="recoletarBar">
      <span>{{$t('tabbars.shouji')}}</span>
      <template #icon="props">
        <img
          :src="props.active ? icon[2].active : icon[2].inactive"
          class="iconT"
        />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/registro">
      <span>{{$t('tabbars.dingdan')}}</span>
      <template #icon="props">
        <img
          :src="props.active ? icon[3].active : icon[3].inactive"
          class="iconT"
        />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/pofile">
      <span>{{$t('tabbars.wode')}}</span>
      <template #icon="props">
        <img
          :src="props.active ? icon[4].active : icon[4].inactive"
          class="iconT"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      icon: [
        {
          active: require("@/assets/image/icon1a.png"),
          inactive: require("@/assets/image/icon1a.png"),
        },
        {
          active: require("@/assets/image/icon2a.png"),
          inactive: require("@/assets/image/icon2a.png"),
        },
        {
          active: require("@/assets/image/icon3a.png"),
          inactive: require("@/assets/image/icon3a.png"),
        },
        {
          active: require("@/assets/image/icon4a.png"),
          inactive: require("@/assets/image/icon4a.png"),
        },
        {
          active: require("@/assets/image/icon5a.png"),
          inactive: require("@/assets/image/icon5a.png"),
        },
      ],
    };
  },
  computed: {},
  methods: {},
  mounted() {
    switch (this.$route.path) {
      case "/pofile":
        this.active = 4;
        break;
      case "/registro":
        this.active = 3;
        break;
      case "/recolectar":
        this.active = 2;
        break;
      case "/recharge":
        this.active = 1;
        break;
      case "/":
        this.active = 0;
        break;
      default:
        this.active = "";
        break;
    }
  },
};
</script>
<style lang="less" >
.van-tabbar {
  filter: drop-shadow(0 0 4px #bbb);
}
.recoletarBar::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -30%;
  background-color: #fff;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: 499;
  width: 50px;
  padding: 20px;
  z-index: 0;
  height: 50px;
}
.van-tabbar-item__text {
  z-index: 1;
}
.iconT {
  width: 0.64rem;
  height: 0.64rem !important;
}
</style> -->