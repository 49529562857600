import request from "@/utils/request";
export default {
  sign(
    nickname,
    pass,
    pass_code,
    mobile,
    mobile_prefix,
    inviter_id,
    headimgurl
  ) {
    return request.post("/user/auth/sign", {
      nickname: nickname,
      pass: pass,
      pass_code: pass_code,
      mobile: mobile,
      mobile_code: mobile_prefix,
      inviter_id: inviter_id,
      headimgurl: headimgurl,
    });
  },
  login(nickname, pass) {
    return request.post("/user/auth/login", {
      nickname: nickname,
      pass: pass,
    });
  },
  userInfo() {
    return request.post("/user/user/userInfo", {});
  },
  address() {
    return request.post("/user/user/address", {});
  },
  updateAddress(name, mobile, mark) {
    return request.post("/user/user/updateAddress", {
      name: name,
      mobile: mobile,
      mark: mark,
    });
  },
  // 银行列表
  bankList() {
    return request.post("/user/home/bankList", {});
  },
  // 银行信息
  bank() {
    return request.post("/user/user/bank", {});
  },
  // 银行信息
  updateBank(bank_id, bank_name, mobile, name, account, email, custom) {
    return request.post("/user/user/updateBank", {
      bank_id: bank_id,
      bank_name: bank_name,
      mobile: mobile,
      name: name,
      account: account,
      email: email,
      custom: custom,
    });
  },
  // 修改密码
  newPass(new_pass, pass) {
    return request.post("/user/user/newPass", {
      new_pass: new_pass,
      pass: pass,
    });
  },
  // 修改安全码
  newPassCode(new_pass, pass) {
    return request.post("/user/user/newPassCode", {
      new_pass: new_pass,
      pass: pass,
    });
  },
  // 提现
  withdraw(quantity, mark, pass_code) {
    return request.post("/user/user/withdraw", {
      quantity: quantity,
      mark: mark,
      pass_code: pass_code,
    });
  },
  // 充值信息接口
  payMode(quantity,mode_id,custom) {
    return request.post("/user/user/payMode", {
      quantity: quantity,
      mode_id:mode_id,
      custom:custom
    });
  },
  // 充值
  pay(quantity, img, log_id) {
    return request.post("/user/user/pay", {
      quantity: quantity,
      img: img,
      log_id: log_id,
    });
  },
  // 积分日志
  quantityLog(page, limit, type, begin_date, end_date) {
    return request.post("/user/user/quantityLog", {
      page: page,
      limit: limit,
      type: type,
      begin_date: begin_date,
      end_date: end_date,
    });
  },
  // 订单详情
  orderInfo(order_id, order_goods_id) {
    return request.post("/user/order/orderInfo", {
      order_id: order_id,
      order_goods_id: order_goods_id,
    });
  },
  // 轮播
  heart() {
    return request.post("/user/user/heart", {});
  },
  // 邀请团队
  team(classs) {
    return request.post("/user/invite/team", {
      class: classs,
    });
  },
  // 获取验证码
  sendSms(mobile) {
    return request.post("/user/auth/sendSms", {
      mobile: mobile,
    });
  },
  // 找回密码
  forGet(mobile, code, pass) {
    return request.post("/user/auth/forGet", {
      mobile: mobile,
      code: code,
      pass: pass,
    });
  },
  // 客服列表
  chatList() {
    return request.post("/user/user/chatList", {});
  },
  // 充值方式
  modelSmilInfo() {
    return request.post("/user/pay/modelSmilInfo", {});
  },
  // 充值方式自定义参数
  payModeCustom(mode_id) {
    return request.post("/user/pay/payModeCustom", {mode_id:mode_id});
  },
};
