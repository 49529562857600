<template>
  <!-- component -->
  <div class="min-h-screen bg-b-2 flex justify-center items-center">
    <div class="absolute w-60 h-60 rounded-xl bg-purple-300 -top-5 -left-16 z-0 transform rotate-45 hidden md:block">
    </div>
    <div class="absolute w-48 h-48 rounded-xl bg-purple-300 -bottom-6 -right-10 transform rotate-12 hidden md:block">
    </div>
    <div class="py-12 px-12 bg-white rounded-2xl shadow-xl z-20">
      <!-- <div>
              <h1 class="text-3xl font-bold text-center mb-4 cursor-pointer">欢迎回来</h1>
              <p class="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer">Create an
                  account to enjoy all the services without any ads for free!</p>
          </div> -->
      <div class="space-y-4">
        <!-- <input type="text" v-model="nickname" :placeholder="$t('regist.pyhm')"
          class="block text-sm py-3 px-4 rounded-lg w-full border outline-none" />
        <input type="text" v-model="mobile" :placeholder="$t('regist.ptel')"
          class="block text-sm py-3 px-4 rounded-lg w-full border outline-none" />
        <input type="text" :placeholder="$t('regist.shuru')" v-model="pass" @click-right-icon="ChangfeConType"
          class="block text-sm py-3 px-4 rounded-lg w-full border outline-none" />
        <input type="text" :placeholder="$t('regist.shurupay')" v-model="pass_code" @click="ShowPassCode = true"
          class="block text-sm py-3 px-4 rounded-lg w-full border outline-none" /> -->
        <van-field :label="$t('regist.yhm')" v-model="nickname" :placeholder="$t('regist.pyhm')" class="flex flex-col" />
        <van-field :label="'+' + mobile_prefix" v-model="mobile" :placeholder="$t('regist.ptel')" class="flex flex-col" />
        <van-field :label="$t('regist.mima')" right-icon="eye-o" :placeholder="$t('regist.shuru')" :type="ConType"
          v-model="pass" @click-right-icon="ChangfeConType" class="flex flex-col" />
        <van-field :label="$t('regist.zhifu')" right-icon="eye-o" :type="PagoType" :placeholder="$t('regist.shurupay')"
          readonly v-model="pass_code" @click-input="ShowPassCode = true" @click-right-icon="ChangfePagoType"
          class="flex flex-col" />
        <van-field :label="$t('regist.yq')" v-model="inviter_id" :placeholder="$t('regist.pyqm')" class="flex flex-col"
          readonly />
      </div>
      <div class="text-center mt-6">
        <button class="py-3 w-64 text-xl text-white bg-p-1 rounded-2xl" @click="UserSing">{{ $t('regist.zhuce')
        }}</button>
        <p class="mt-4 text-sm"><span class="underline cursor-pointer" @click="JumpLogin">{{ $t('regist.yijing') }}</span>
        </p>
      </div>
    </div>
    <div class="w-40 h-40 absolute bg-purple-300 rounded-full top-0 right-12 hidden md:block"></div>
    <div class="w-20 h-40 absolute bg-purple-300 rounded-full bottom-20 left-10 transform rotate-45 hidden md:block">
    </div>
    <!-- 二级密码遮罩层 -->
    <van-popup v-model:show="ShowPassCode" position="bottom" :style="{ height: '9.47rem' }" round>
      <div class="digital-keyboard-modal digital-keyboard-modal-1">
        <div class="dk-swiper">
          <div class="dk-title">{{ $t('Detalles.shuruzhifu') }}</div>
          <div class="dk-subtitle">{{ $t('Detalles.shuruzhifu') }}</div>
          <div class="pwd-box clearfix">
            <!-- <div class="pwd-text active"></div>
            <div class="pwd-text active"></div>
            <div class="pwd-text active"></div>
            <div class="pwd-text active"></div>
            <div class="pwd-text active"></div>
            <div class="pwd-text active"></div> -->
            <van-password-input :value="pass_code" :focused="showKeyboard" />
          </div>
        </div>
        <div class="pwd-forget"></div>
        <div class="digital-keyboard digital-keyboard-show">
          <van-number-keyboard v-model="pass_code" :extra-key="[]" :show="showKeyboard" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
// import Auth from "@/components/regist/auth.vue";
import storeAction from "@/store/typed-actions";
export default {
  name: "Regist",
  // components: { Auth },
  data() {
    return {

      ShowPassCode: false,
      nickname: "",
      pass: "",
      pass_code: "",
      mobile: "",
      inviter_id: "",
      showKeyboard: true,
      mobile_code: '',
      // headimgurl: "",
      ShowSelectAuth: false,
      AuthList: [
        require("@/assets/image/auth1.jpg"),
        require("@/assets/image/auth2.jpeg"),
        require("@/assets/image/auth3.jpeg"),
        require("@/assets/image/auth4.jpeg"),
        require("@/assets/image/auth5.jpeg"),
        require("@/assets/image/auth6.jpeg"),
        "",
        require("@/assets/image/auth.jpeg"),
        require("@/assets/image/auth7.jpeg"),
      ],
      showAuthNumber: 0,
      PagoType: "password",
      ConType: "password",
    };
  },
  watch: {
    pass_code(value) {
      let that = this;
      if (value.length === 6) {
        this.$toast.loading({
          message: this.$t('yanzhengzhifu'),
          forbidClick: true,
          duration: 1000,
          onClose() {
            that.ShowPassCode = false;
          },
        });
      }
    },
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.mobile_prefix);
      return this.$store.state.mobile_prefix;
    },
  },
  methods: {
    AuthNumber(e) {
      console.log(e);
      this.showAuthNumber = e;
    },
    ChangfePagoType() {
      if (this.PagoType == "password") {
        this.PagoType = "text";
      } else {
        this.PagoType = "password";
      }
    },
    ChangfeConType() {
      if (this.ConType == "password") {
        this.ConType = "text";
      } else {
        this.ConType = "password";
      }
    },
    JumpLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    // 注册
    UserSing() {
      if (!this.nickname) {
        this.$toast(this.$t('regist.pyhm'));
      } else if (!this.mobile) {
        this.$toast(this.$t('regist.ptel'));
      } else if (!this.pass) {
        this.$toast(this.$t('regist.shuru'));
      } else if (!this.pass_code) {
        this.$toast(this.$t('regist.shurupay'));
      } else {
        // 开始注册
        this.$toast.loading({
          message: this.$t('Conectando'),
          forbidClick: true,
          duration: 0,
        });
        userApi
          .sign(
            this.nickname,
            this.pass,
            this.pass_code,
            this.mobile,
            this.mobile_prefix,
            this.inviter_id,
            String(this.showAuthNumber)
          )
          .then((data) => {
            this.$toast.clear();
            console.log(data.data, "注册成功");
            storeAction.loginDone(this, data.data);
            // storeAction.loginDone(data.data);
          })
          .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.message);
          });
      }
    },
  },
  created() {
    this.mobile_prefix = localStorage.getItem('mobile_prefix');
    this.inviter_id = this.$route.query.invitation_id
  },
};
</script>
<style lang="less" scoped>
.main {
  // background: linear-gradient(90deg, #6dcab8, #3a78cb);
  // font-size: 0.37rem;
  width: 100%;
  height: 100% !important;
  min-height: 100vh;
  // padding: 0 0.4rem;
  // display: flex;
  // -webkit-box-orient: vertical;
  // -webkit-box-direction: normal;
  // -ms-flex-direction: column;
  // flex-direction: column;
  // justify-content: center;
  // box-sizing: border-box;
}

.title {
  font-size: 0.64rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  padding: 0.8rem 0.85rem;
  text-align: left;
}

.warp {
  padding: 0.27rem 0.53rem;
}

// .item {
//   box-sizing: border-box;
//   position: relative;
//   padding-top: 0.27rem;
// }
.evan-form-item-container__main {
  flex: 1;
  min-height: 1.2rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.avatarImg {
  height: 2.4rem;
  width: 2.4rem;
  border: 2px solid #999;
  text-align: center;
  line-height: 2.4rem;
  border-radius: 50%;
  position: absolute;
  left: 35%;
  top: -0.8rem;

  img {
    border-radius: 50%;
  }
}

// .items .evan-form-item-container {
//   border-bottom: 1px solid #eeeff2;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
// .items .item:first-child .evan-form-item-container {
//   border: none;
// }
// .evan-form-item-container--left {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
// }
// .evan-form-item-container__main {
//   flex: 1;
//   min-height: 1.2rem;
//   display: flex;
//   align-items: center;
//   overflow: hidden;
// }
// .evan-form-item-container__main {
//   height: 100%;
//   width: 100%;
// }
// .evan-form-item-container__label {
//   font-size: 0.37rem;
//   color: #666;
//   line-height: 0.53rem;
//   padding: 0.32rem 0;
//   display: inline-block;
// }
input {
  // caret-color: #017aff;
  // color: #fff;
  width: 100%;
  height: 100%;
}

.van-cell {
  padding: 0;
  background: none;
}

// /deep/ input::placeholder {
//   opacity: 0.5;
//   color: #fff;
// }

.btn {
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: #56b3f8;
  font-size: 16px;
  margin-top: 20px;
  color: #fff;
  border-radius: 20px;
  border: 1px solid #56b3f8;
}

.download {
  background: #d2d6dc;
  border: 1px solid #d2d6dc;
}

.dk-swiper {
  height: 3.47rem;
}

.digital-keyboard-modal.digital-keyboard-modal-1,
.digital-keyboard-modal.digital-keyboard-modal-4 {
  height: auto;
  background: #fff url(../assets/image/input.png) top/100% auto no-repeat;
}

.dk-title {
  font-size: 0.4rem;
  font-weight: 800;
  color: #fff;
  line-height: 1.2rem;
  text-align: center;
}

.dk-subtitle {
  font-size: 0.32rem;
  color: #989894;
  line-height: 3;
  text-align: center;
}

.pwd-box {
  padding-left: 0.13rem;
  position: relative;
  text-align: center;
}

.pwd-forget {
  font-size: 0.32rem;
  color: #26c6b3;
  padding: 0.27rem 0 0.4rem;
  text-align: center;
  line-height: 0.43rem;
}

.pwd-text.active:after {
  animation: twinkle-data-v-0b1f9cd8 1s infinite;
  height: 70%;
  width: 1px;
  content: "";
  position: absolute;
  top: 15%;
  left: 50%;
  margin-left: -1px;
  background-color: #4fa5e1;
}
</style>