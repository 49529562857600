import request from "@/utils/request";
export default {
  //订单列表
  list(page, limit, status) {
    return request.post("/user/order/list", {
      page: page,
      limit: limit,
      status: status,
    });
  },
  //   选择列表
  snatchInfo() {
    return request.post("/user/order/snatchInfo", {});
  },
  //   收集订单
  snatch(group_id) {
    return request.post("/user/order/snatch", {
      group_id: group_id,
    });
  },
  //   支付订单
  pay(pass_code, order_id, order_goods_id) {
    return request.post("/user/order/pay", {
      pass_code: pass_code,
      order_id: order_id,
      order_goods_id: order_goods_id,
    });
  },
  // 冻结订单
  freezeList() {
    return request.post("/user/order/freezeList", {});
  },
  // 任务信息
  Info() {
    return request.post("/user/invite/Info", {});
  },
  // 自动领取
  getTask(id) {
    return request.post("/user/invite/getTask", {
      task_id: id,
    });
  },
  // 自动领取
  TimeCur() {
    return request.post("/user/home/ServerDate", {});
  },
};
