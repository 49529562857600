const tl = {
  home: {
    title: "Buyer Order Mall",
    chongzhi: "Mag-recharge",
    tixian: "bawiin",
    yaoqing: "mag-anyaya",
    Aviso: "balaan",
    Balance: "pondo",
    Hoy: "ngayon",
    Financiero: "pinansya",
    Congelar: "mag-freeze",
    MiembroNoticias: "Impormasyon ng Miyembro",
    Socio: "Kasosyo",
    About: "tungkol sa atin",
    aboutwithdrawal: "Tungkol sa Pag-withdraw",
  },
  about: {
    title: "tungkol sa atin",
    guanyutixian: "Tungkol sa Pag-withdraw",
  },
  Recargar: {
    title: "muling magkarga",
    Introducir: "Pakilagay ang halaga ng recharge",
    tps: "Minimum na halaga ng recharge",
    Selección: "Mabilis na pagpili ng halaga",
    text: "Paalala: Maaaring baguhin ang bank platform account anumang oras. Para sa bawat deposito, mangyaring pumunta sa pahina kung saan mo pinunan ang impormasyon at ipahiwatig ang pinakabagong numero ng account. Wala kaming pananagutan kung magdeposito ka sa isang account na nag-expire na!",
  },
  RecargarAction: {
    title: "Magsumite ng top up",
    Cantidad: "Recharge na halaga",
    Información: "Impormasyon ng Nagbabayad",
    tps: "Pagkatapos makumpleto ang paglipat, siguraduhing mag-upload muna ng screenshot ng paglilipat, at pagkatapos ay i-click ang button na isumite!",
    jietu: "Mag-upload ng screenshot ng paglilipat",
    querenchongzhi: "Kumpirmahin ang recharge",
  },
  Recolectar: {
    title: "mangolekta ng order",
    Pedidos: "Komisyon ng order",
    yongjin: "komisyon",
    jinrishouru: "kita ngayon",
    gerenshouru: "Personal na kita",
    yiwancheng: "Nakumpleto ang order",
    dongjie: "i-freeze ang order",
    weiwancheng: "Hindi kumpletong mga order",
    dongjiejine: "frozen na halaga",
    dangqianyue: "kasalukuyang balanse",
    kaishi: "simulan ang pagkolekta",
    tingzhi: "itigil ang pagkolekta",
    tishi: "pahiwatig",
    Segundo: "pangalawa",
    text: "Pagkatapos makumpleto ng user ang order, ipapadala ng platform ang impormasyon ng order sa nagbebenta sa parehong oras. Kung ang user ay hindi magbabayad para sa order sa loob ng isang tiyak na tagal ng panahon, ang order ay mapi-freeze upang maiwasan ang pangangasiwa ng online shopping platform. Kapag ang isang order ay na-freeze, ang pera sa order ay na-freeze din. Kailangan mong maghintay ng ilang oras para ma-unfreeze ng system ang mga ito. Mangyaring tandaan ito.",
  },
  Registro: {
    title: "Itala",
    daizhifu: "Para mabayaran",
    yijiesuan: "Naayos na",
    chulizhong: "Pinoproseso",
    yidongjie: "nagyelo",
    meiyoujilu: "Walang naitala",
    xianzaishouji: "kolektahin ngayon",
    jiage: "presyo",
    yongjin: "komisyon",
    shouyi: "kita",
    shengyutime: "natitirang oras",
    chakan: "suriin ang order",
    weizhifu: "walang bayad",
    fukuan: "Pagbabayad",
  },
  Mex: "",
  Confirmar: "kumpirmahin",
  Conectando: "Naglo-load",
  fuzhiOK: "kinopya sa karton",
  Detalles: {
    title: "mga detalye ng order",
    yigong: "Isang kabuuan ng ",
    dingdan: " mga orde",
    dangqian: "Sa kasalukuyan ang una",
    yiwancheng: "natapos ang order",
    dongjie: "Naka-freeze ang order",
    dengdai: "Hinihintay na makumpirma ng mamimili ang order",
    dongjies: "mag-freeze",
    zhuyao: "pangunahing tindahan",
    guanfang: "opisyal na garantiya",
    zongshuliang: "Ang kabuuang bilang",
    daigou: "Bayad sa pagbili",
    shouyi: "kita",
    fukuanzt: "katayuan ng pagbabayad",
    weizhifu: "walang bayad",
    yizhifu: "Binayaran",
    jiedong: "Oras ng lasaw",
    zhifu: "magbayad",
    shuruzhifu: "Mangyaring ipasok ang password sa pagbabayad",
    yanzheng: "I-verify ang password sa pagbabayad",
  },
  Pofile: {
    title: "Mga Detalye ng Account",
    yaoqingma: "Code ng imbitasyon",
    fuzhi: "kopyahin ang Link",
    Balance: "pondo",
    tixian: "bawiin",
    chongzhi: "muling magkarga",
    zongji: "kabuuan",
    jinrong: "pananalapi",
    xiangqing: "Mga Detalye",
    dongjie: "mag-freeze",
    geren: "personal",
    tuandui: "pangkat",
    yaoqing: "mag-anyaya",
    xiaoxi: "serbisyo sa customer",
    dizhi: "tirahan",
    guanyu: "tungkol sa atin",
    renwu: "Mag-imbita ng mga gawain",
    anquan: "Kaligtasan",
    fuwu: "Sentro ng Serbisyo",
  },
  alert: {
    title: "pahiwatig",
    quxiao: "Kanselahin",
    queding: "Oo naman",
    acuerdo: "sumang-ayon",
    fuzhi: "kopya",
    qianwang: "pumunta sa",
  },
  withdrawal: {
    title: "bawiin",
    yue: "kasalukuyang balanse",
    shouxufei: "Withdrawal Fee",
    dancitixian: "Minimum na bayad sa paghawak para sa isang pag-withdraw",
    shuru: "Ipasok ang halaga",
    dangqianjine: "Kasalukuyang halaga ng withdrawal ng account",
    suoyou: "i-extract lahat",
    zhanghuming: "pangalan ng account",
    zhanghu: "account",
    dianhua: "numero ng telepono",
    tps: "pahiwatig",
    text: "1. Mangyaring punan ang impormasyon ng bangko nang tumpak, hindi kami mananagot para sa pagkawala ng mga pondo na dulot ng mga pagkakamali na maaari mong gawin sa paglalagay ng impormasyon; bago ang 2:24 sa susunod na araw, ang tiyak na oras ng pagdating ay napapailalim sa bangko",
    queren: "Kumpirmahin ang pag-withdraw",
    guanlian: "Paki-link ang iyong bank card bago mag-withdraw ng pera",
  },
  prsonal: {
    title: "personal",
    name: "Pangalan",
    shouji: "numero ng telepono",
    yhk: "Bank card",
    tuichu: "mag-sign out",
    tianxie: "punan",
  },
  card: {
    title: "Mga setting ng bank card",
    zhanghuxinxi: "Impormasyon ng Account",
    tps: "Pakitiyak na tama ang iyong account, hindi kami mananagot para sa anumang mga error.",
    yhmc: "Pangalan ng bangko",
    zhanghuming: "username",
    zhanghu: "account",
    shouji: "numero ng telepono",
    fenhang: "Pangalan ng sangay ng bangko",
    baocun: "iligtas",
    jianyi: "Magmungkahi",
    text: "Mangyaring makipag-ugnayan sa online customer service para sa bank card binding method, kung sakaling kailanganin mong baguhin ito!",
    qyh: "mangyaring pumili",
    qsryhm: "Mangyaring ipasok ang username ng bangko",
    qzh: "Pakipasok ang account",
    qdh: "Mangyaring ipasok ang telepono",
    qfhmc: "Pakilagay ang pangalan ng sangay",
    qsrifcs: "Mangyaring ipasok ang IFSC",
    youxiang: "Mail",
    plyx: "mangyaring ipasok ang iyong email",
    jgbh: "Numero ng institusyon",
    pljgbh: "Mangyaring ipasok ang numero ng institusyon",
    gjj: "Provident fund",
    plgjj: "pasok",
    isbp: "ISBP",
    plisbp: "Mangyaring ipasok ang ISBP",
    pixtype: "PIXtype",
    plpixtype: "Pakipasok ang PIXtype",
    pix: "PIX",
    plpix: "Pakilagay ang PIX",
    evp: "EVP",
    plevp: "Pakipasok ang EVP",
  },
  team: {
    title: "pangkat",
    Directo: "direkta",
    Secundario: "gitnang paaralan",
    Terciario: "pangatlo",
    Comisión: "Ang bayad sa pagbili ngayon",
    zongyongjin: "kabuuang komisyon",
    shuzi: "numero",
    sudu: "bilis",
    jinricz: "Mag-recharge ngayon",
    tixian: "buong withdrawal",
  },
  invitation: {
    title: "Mag-imbita ng mga kaibigan",
    notitle: "Maaari kang gumawa ng mga part-time na trabaho mula sa iyong telepono",
    notitles: "Anyayahan ang mga kaibigan na kumita ng mas maraming pera",
    yqm: "Code ng imbitasyon",
    fuzhi: "kopya",
    xzyq: "mag-imbita ngayon",
  },
  message: {
    title: "serbisyo sa customer",
    go: "kumonekta",
  },
  address: {
    title: "pamamahala ng address",
    xm: "Pangalan",
    dianhua: "Telepono",
    qtx: "Mangyaring punan",
    dizhixq: "Impormasyon sa address ng pagpapadala",
    baocun: "iligtas",
  },
  invitationTask: {
    title: "Mag-imbita ng mga gawain",
    zongren: "kabuuang tao",
    gengxin: "I-update ang gawain: ang na-reload na user ay isang wastong user",
    yq: "mag-anyaya",
    jige: "mag-anyaya",
    qianwang: "pumunta sa",
    renwujiangli: "mga gantimpala sa misyon",
  },
  safe: {
    aqgl: "pamamahala sa kaligtasan",
    denglu: "password sa pag-login",
    zfmm: "password sa pagbabayad",
    bh: "Protektahan",
  },
  password: {
    title: "password sa pag-login",
    titles: "password sa pagbabayad",
    shouji: "numero ng telepono",
    yanzheng: "verification code",
    fasong: "ipadala",
    xinmima: "bagong password",
    pxinmima: "Maglagay ng bagong password",
  },
  record: {
    title: "Mga Detalye",
    quanbu: "lahat",
    chongzhi: "muling magkarga",
    tixian: "bawiin",
    dingdan: "Umorder",
    tuandui: "pangkat",
    zongsu: "kabuuan",
    dangqian: "kasalukuyang data",
    kaishisj: "Oras ng pagsisimula",
    jieshusj: "Oras ng Pagtatapos",
  },
  login: {
    zhanghu: "Pakipasok ang account",
    mima: "Mangyaring ipasok ang password",
    denglu: "Mag log in",
    zhuce: "Mag-rehistro na ngayon",
    wjmm: "Nakalimutan ko ang aking password",
  },
  regist: {
    touxiang: "avatar",
    yhm: "username",
    mima: "password",
    zhifu: "password sa pagbabayad",
    yq: "mag-anyaya",
    pyhm: "Maglagay ng username",
    ptel: "Mangyaring ipasok ang numero ng telepono",
    shuru: "Pakipasok ang password",
    shurupay: "Maglagay ng 6 na digit na security code",
    pyqm: "Code ng imbitasyon",
    plyqm: "Pakipasok ang code ng imbitasyon",
    zhuce: "magparehistro",
    yijing: "Mayroon nang account?",
  },
  tabbars: {
    shouye: "unang pahina",
    chongzhi: "muling magkarga",
    shouji: "mangolekta",
    dingdan: "Umorder",
    wode: "akin",
  },
  zhaohuimima: "Mangyaring makipag-ugnayan sa customer service para sa pagproseso",
  yanzhengzhifu: "I-verify ang password sa pagbabayad",
  huanying: "maligayang pagdating",
  yinhang: "bangko",
  yinhangbh: "numero ng bangko",
  beizhu: "Puna",
  zhifufs: "paraan ng Pagbayad",
  qxzzffs: "Mangyaring piliin ang paraan ng pagbabayad",
};
export default tl;
