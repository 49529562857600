import store from "@/utils/store";
export default {
  token: store.get("token") ? store.get("token") : null, //用户token
  currentUserId: store.get("userid") ? store.get("userid") : null, //用户id
  currentUser: store.get("userinfo") ? store.get("userinfo") : null, //用户信息
  nickname: store.get("nickname") ? store.get("nickname") : null, //用户昵称
  monetary_unit: store.get("monetary_unit") ? store.get("monetary_unit") : null, //货币单位
  selectListActiveID: store.get("selectListActiveID")
    ? store.get("selectListActiveID")
    : null, //收集订单的ID
  out_time_pay: store.get("out_time_pay") ? store.get("out_time_pay") : null, //订单支付的时间
  action_time: store.get("action_time") ? store.get("action_time") : null, //订单结算时间
  mobile_prefix: store.get("mobile_prefix") ? store.get("mobile_prefix") : null,
  memberInfo: "", //滚动数据
  meberApishow: true, //滚动数据接口是否可以调用
  bannerImg: [], //轮播图片
  aboutTxt: "", //关于我们
  about_withdraw: "", //关于提现
  homeTitle:'',//首页标题
  partner:'',//合作伙伴图片
  ShopTilte:'',//抢单选择店铺的title
  ShopList:[],//抢单店铺列表
  ShopListID:[],//抢单店铺列表ID
  ShowApiSns:true,//抢单店铺列表接口是否可以请求
};
