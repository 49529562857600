const ara = {
    home: {
        title: "متجر طلبات المشتري",
        chongzhi: "إعادة الشحن",
        tixian: "السحب",
        yaoqing: "الدعوة",
        Aviso: "نصائح",
        Balance: "رصيد",
        Hoy: "اليوم",
        Financiero: "المالية",
        Congelar: "مجمد",
        MiembroNoticias: "معلومات العضو",
        Socio: "التعاون",
        About: "معلومات عنا",
        aboutwithdrawal: "معلومات عن السحب",
    },
    about: {
        title: "معلومات عنا",
        guanyutixian: "معلومات عن السحب",
    },
    Recargar: {
        title: "إعادة الشحن",
        Introducir: "الرجاء إدخال مبلغ الإعادة",
        tps: "الحد الأدنى لمبلغ الإعادة",
        Selección: "اختيار سريع للمبلغ",
        text: "تلميح: يتغير حساب منصة البنك في أي وقت. لكل إيداع ، يرجى الذهاب إلى الصفحة التي تقوم بتعبئة المعلومات فيها وتحديد أحدث رقم حساب. إذا قمت بالإيداع في حساب منتهي الصلاحية ، فلسنا مسؤولين!",
    },
    RecargarAction: {
        title: "إرسال إعادة الشحن",
        Cantidad: "مبلغ الإعادة",
        Información: "معلومات المستلم",
        tps: "بعد إتمام التحويل ، يرجى التأكد من تحميل صورة للتحويل أولاً ، ثم النقر على زر الإرسال!",
        jietu: "تحميل صورة للتحويل",
        querenchongzhi: "تأكيد إعادة الشحن",
    },
    Recolectar: {
        title: "جمع الطلبات",
        Pedidos: "عمولة الطلب",
        yongjin: "عمولة",
        jinrishouru: "الدخل اليومي",
        gerenshouru: "الدخل الشخصي",
        yiwancheng: "الطلبات المكتملة",
        dongjie: "الطلبات المجمدة",
        weiwancheng: "الطلبات المعلقة",
        dongjiejine: "المبلغ المجمد",
        dangqianyue: "الرصيد الحالي",
        kaishi: "بدء الجمع",
        tingzhi: "توقف عن الجمع",
        tishi: "تلميح",
        Segundo: "ثانية",
        text: "بعد إتمام المستخدم للطلب ، سيقوم المنصة بإرسال معلومات الطلب إلى البائع في نفس الوقت. إذا لم يقم المستخدم بدفع الطلب خلال فترة زمنية معينة ، فسيتم تجميد الطلب لتجنب الرصد من قبل منصة التسوق عبر الإنترنت. بمجرد تجميد الطلب ، يتم تجميد المال في الطلب أيضًا. يجب عليك الانتظار حتى يقوم النظام بفك التجميد في غضون ساعات قليلة. تذكر ذلك.",
    },
    Registro: {
        title: "السجل",
        daizhifu: "قيد الدفع",
        yijiesuan: "تم التسوية",
        chulizhong: "تجهيز",
        yidongjie: "مجمد",
        meiyoujilu: "لا يوجد سجل",
        xianzaishouji: "تحصيل الآن",
        jiage: "السعر",
        yongjin: "العمولة",
        shouyi: "الأرباح",
        shengyutime: "الوقت المتبقي",
        chakan: "عرض الطلب",
        weizhifu: "غير مدفوع",
        fukuan: "الدفع",
    },
    Mex: "",
    Confirmar: "تأكيد",
    Conectando: "جار التحميل",
    fuzhiOK: "تم النسخ إلى الحافظة",
    Detalles: {
        title: "تفاصيل الطلب",
        yigong: "الإجمالي",
        dingdan: "الطلبات",
        dangqian: "الثانية الحالية",
        yiwancheng: "الطلبات المكتملة",
        dongjie: "الطلبات المجمدة",
        dengdai: "انتظار تأكيد المشتري للطلب",
        dongjies: "مجمد",
        zhuyao: "المتجر الرئيسي",
        guanfang: "ضمان رسمي",
        zongshuliang: "الكمية الإجمالية",
        daigou: "رسوم الشراء",
        shouyi: "الأرباح",
        fukuanzt: "حالة الدفع",
        weizhifu: "غير مدفوع",
        jiedong: "وقت الإزالة التدريجي",
        zhifu: "الدفع",
        yizhifu: "تم الدفع",
        shuruzhifu: "يرجى إدخال كلمة المرور الخاصة بالدفع",
        yanzheng: "التحقق من كلمة مرور الدفع",
    },
    Pofile: {
        title: "تفاصيل الحساب",
        yaoqingma: "رمز الدعوة",
        fuzhi: "نسخ الرابط",
        Balance: "رأس المال",
        tixian: "سحب",
        chongzhi: "إعادة شحن",
        zongji: "الإجمالي",
        jinrong: "التمويل",
        xiangqing: "التفاصيل",
        dongjie: "مجمد",
        geren: "الشخصية",
        tuandui: "الفريق",
        yaoqing: "الدعوة",
        xiaoxi: "الرسائل",
        dizhi: "العنوان",
        guanyu: "حولنا",
        renwu: "مهمة الدعوة",
        anquan: "الأمن",
        fuwu: "مركز الخدمة",
    },
    alert: {
        title: "تنبيهات",
        quxiao: "إلغاء",
        queding: "موافق",
        acuerdo: "موافق",
        fuzhi: "نسخ",
        qianwang: "انتقل",
    },
    withdrawal: {
        title: "سحب",
        yue: "الرصيد الحالي",
        shouxufei: "رسوم السحب النقدي",
        dancitixian: "الحد الأدنى للرسوم الإدارية للسحب الفردي",
        shuru: "الرجاء إدخال المبلغ",
        dangqianjine: "مبلغ السحب الحالي للحساب",
        suoyou: "استخراج الكل",
        zhanghuming: "اسم الحساب",
        zhanghu: "الحساب",
        dianhua: "رقم الهاتف",
        tps: "نصائح",
        text: "1. يرجى تعبئة معلومات البنك بدقة. لن نتحمل أي مسؤولية عن الخسارة المالية الناجمة عن أخطاء محتملة عند إدخال المعلومات. 2. قبل الساعة 2:24 من اليوم التالي ، يجب أن يكون وقت الوصول محددًا من قبل البنك",
        queren: "تأكيد السحب",
        guanlian: "يرجى ربط بطاقة البنك الخاصة بك قبل السحب",
    },
    prsonal: {
        title: "الشخصية",
        name: "الاسم الكامل",
        shouji: "رقم الهاتف",
        yhk: "البطاقة البنكية",
        tuichu: "تسجيل الخروج",
        tianxie: "ملء",
    },
    card: {
        title: "إعدادات البطاقة البنكية",
        zhanghuxinxi: "معلومات الحساب",
        tps: "يرجى التأكد من صحة رقم حسابك. نحن لسنا مسؤولين عن أي خطأ.",
        yhmc: "اسم البنك",
        zhanghuming: "اسم المستخدم",
        zhanghu: "الحساب",
        shouji: "رقم الهاتف",
        fenhang: "اسم فرع البنك",
        baocun: "حفظ",
        jianyi: "اقتراح",
        text: "يرجى الاتصال بخدمة العملاء عبر الإنترنت للحصول على طريقة ربط البطاقة البنكية في حالة الحاجة إلى التعديل!",
        qyh: "يرجى اختيار",
        qsryhm: "يرجى إدخال اسم مستخدم البنك",
        qzh: "يرجى إدخال حسابك",
        qdh: "يرجى إدخال رقم الهاتف",
        qfhmc: "يرجى إدخال اسم فرع البنك",
        qsrifcs: "يرجى إدخال IFSC",
        youxiang: "البريد الإلكتروني",
        plyx: "يرجى إدخال البريد الإلكتروني",
        jgbh: "رقم المؤسسة",
        pljgbh: "يرجى إدخال رقم المؤسسة",
        gjj: "CPF",
        plgjj: "يرجى إدخال CPF",
        isbp: "ISBP",
        plisbp: "يرجى إدخال ISBP",
        pixtype: "PIXType",
        plpixtype: "يرجى إدخال PIXType",
        pix: "PIX",
        plpix: "يرجى إدخال PIX",
        evp: "EVP",
        plevp: "يرجى إدخال EVP",
    },
    team: {
        title: "الفريق",
        Directo: "مباشر",
        Secundario: "ثانوي",
        Terciario: "ثالث",
        Comisión: "لجنة اليوم",
        zongyongjin: "إجمالي العمولة",
        shuzi: "الرقم",
        sudu: "السرعة",
        jinricz: "إعادة الشحن اليوم",
        tixian: "سحب كامل",
    },
    invitation: {
        title: "دعوة الأصدقاء",
        notitle: "يمكنك القيام بعمل جزئي من خلال هاتفك المحمول",
        notitles: "ادعو أصدقاء لكي تكسبوا المزيد من المال معًا",
        yqm: "رمز الدعوة",
        fuzhi: "نسخ",
        xzyq: "دعوة الآن",
    },
    message: {
        title: "خدمة العملاء",
        go: "اتصل",
    },
    address: {
        title: "إدارة العنوان",
        xm: "الاسم الكامل",
        dianhua: "رقم الهاتف",
        qtx: "يرجى التعبئة",
        dizhixq: "معلومات عنوان الاستلام",
        baocun: "الحفظ",
    },
    invitationTask: {
        title: "مهمة الدعوة",
        zongren: "إجمالي العدد",
        gengxin: "تحديث المهمة: المستخدم المحمل هو مستخدم صالح",
        yq: "دعوة",
        jige: "أعضاء صالحون",
        qianwang: "اذهب",
        renwujiangli: "مكافأة المهمة",
    },
    safe: {
        aqgl: "إدارة الأمان",
        denglu: "كلمة مرور تسجيل الدخول",
        zfmm: "كلمة المرور للدفع",
        bh: "حماية",
    },
    password: {
        title: "كلمة المرور لتسجيل الدخول",
        titles: "كلمة مرور للدفع",
        shouji: "رقم الهاتف",
        yanzheng: "رمز التحقق",
        fasong: "إرسال",
        xinmima: "كلمة المرور الجديدة",
        pxinmima: "أدخل كلمة مرور جديدة",
    },
    record: {
        title: "تفاصيل",
        quanbu: "الكل",
        chongzhi: "إعادة شحن",
        tixian: "سحب كامل",
        dingdan: "الطلب",
        tuandui: "الفريق",
        zongsu: "الإجمالي",
        dangqian: "البيانات الحالية",
        kaishisj: "وقت البدء",
        jieshusj: "وقت الانتهاء",
    },
    login: {
        zhanghu: "يرجى إدخال حسابك",
        mima: "يرجى إدخال كلمة المرور",
        denglu: "تسجيل الدخول",
        zhuce: "سجل الآن",
        wjmm: "لقد نسيت كلمة المرور؟",
    },
    regist: {
        touxiang: "الصورة الرمزية",
        yhm: "اسم المستخدم",
        mima: "كلمة المرور",
        zhifu: "كلمة مرور الدفع",
        yq: "الدعوة",
        pyhm: "يرجى ملء اسم المستخدم",
        ptel: "يرجى ملء رقم الهاتف",
        shuru: "يرجى إدخال كلمة المرور",
        shurupay: "أدخل 6 أرقام",
        pyqm: "رمز الدعوة",
        zhuce: "تسجيل",
        yijing: "لدي حساب بالفعل؟",
        plyqm: "الرجاء إدخال رمز الدعوة",
    },
    tabbars: {
        shouye: "الصفحة الرئيسية",
        chongzhi: "إعادة الشحن",
        shouji: "جمع",
        dingdan: "الطلب",
        wode: "أنا",
    },
    zhaohuimima: "يرجى الاتصال بخدمة العملاء للمعالجة",
    yanzhengzhifu: "التحقق من كلمة مرور الدفع",
    huanying: "مرحبًا بك في",
    yinhang: "البنك",
    yinhangbh: "رقم البنك",
    beizhu: "ملاحظة",
    zhifufs: 'طريقة الدفع',
    qxzzffs: 'يرجى اختيار طريقة الدفع',
    usdt: {
        title: "قم بتحميل USDTile",
        tips1: "يرجى اختيار المبلغ المطلوب للتحميل بالعملة الفيات المرادة",
        rates: "أسعار الصرف",
        cashTips: "نقدًا يجب دفعه",
        usdtTips: "يجب دفع USDT (TRC20)",
        customAmount: "المبلغ الذي ترغب في الإيداع",
        importantTips: {
            one: "1. يرجى القيام بعملية الإيداع بمبلغ USDT المختار ، وإلا لن يتم تحويل الرصيد",
            two: "2. يرجى إكمال عملية الإيداع بـ USDT في غضون خمس دقائق.",
            three:
                "3. إذا واجهت أي مشكلة ، يرجى الاتصال بخدمة العملاء للمساعدة",
        },
    },
    selectChannel: {
        title: "يرجى اختيار قناة الإيداع",
        cashChannel: "قنوات إيداع العملة القانونية",
        usdtChannel: "قناة إيداع USDT (TRC20)",
        rewards: "مكافأة إضافية 5%",
    },
};
export default ara;
