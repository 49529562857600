import { createRouter, createWebHashHistory } from "vue-router";
import storeToken from "@/utils/store";
import { Toast } from "vant";
import Home from "../views/Home.vue";
import Recharge from "../views/Recharge.vue";
import Recolectar from "../views/Recolectar.vue";
import Registro from "../views/Registro.vue";
import Pofile from "../views/Pofile.vue";
import Login from "../views/Login.vue";
import Regist from "../views/Regist.vue";
import Withdrawal from "../views/Withdrawal.vue";
import InvitationTask from "../views/InvitationTask.vue";
import Invitation from "../views/Invitation.vue";
import RechargeAction from "../views/RechargeAction.vue";
import Personal from "../views/Personal.vue";
import Card from "../views/Card.vue";
import Team from "../views/Team.vue";
import Message from "../views/Message.vue";
import Address from "../views/Address.vue";
import Mine from "../views/Mine.vue";
import Order from "../views/Order.vue";
import Safe from "../views/Safe.vue";
import Password from "../views/Password.vue";
import OrderDetail from "../views/OrderDetail.vue";
import Frozen from "../views/Frozen.vue";
import Record from "../views/Record.vue";
import Forget from "../views/Forget.vue";
import SelectChannel from "../views/SelectChannel.vue";
import UsdtRecharge from "../views/UsdtRecharge.vue"
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requireAuth: true },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/recharge",
    name: "Recharge",
    component: Recharge,
    meta: { requireAuth: true },
  },
  {
    path: "/recolectar",
    name: "Recolectar",
    component: Recolectar,
    meta: { requireAuth: true },
  },
  {
    path: "/registro",
    name: "Registro",
    component: Registro,
    meta: { requireAuth: true },
  },
  {
    path: "/pofile",
    name: "Pofile",
    component: Pofile,
    meta: { requireAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/regist",
    name: "Regist",
    component: Regist,
  },
  {
    path: "/withdrawal",
    name: "Withdrawal",
    component: Withdrawal,
    meta: { requireAuth: true },
  },
  {
    path: "/invitationTask",
    name: "InvitationTask",
    component: InvitationTask,
    meta: { requireAuth: true },
  },
  {
    path: "/invitation",
    name: "Invitation",
    component: Invitation,
    meta: { requireAuth: true },
  },
  {
    path: "/rechargeAction",
    name: "RechargeAction",
    component: RechargeAction,
    meta: { requireAuth: true },
  },
  {
    path: "/personal",
    name: "Personal",
    component: Personal,
    meta: { requireAuth: true },
  },
  {
    path: "/card",
    name: "Card",
    component: Card,
    meta: { requireAuth: true },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: { requireAuth: true },
  },
  {
    path: "/message",
    name: "Message",
    component: Message,
    meta: { requireAuth: true },
  },
  {
    path: "/address",
    name: "Address",
    component: Address,
    meta: { requireAuth: true },
  },
  {
    path: "/mine",
    name: "Mine",
    component: Mine,
    meta: { requireAuth: true },
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
    meta: { requireAuth: true },
  },
  {
    path: "/safe",
    name: "Safe",
    component: Safe,
    meta: { requireAuth: true },
  },
  {
    path: "/password",
    name: "Password",
    component: Password,
    meta: { requireAuth: true },
  },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    component: OrderDetail,
    meta: { requireAuth: true },
  },
  {
    path: "/frozen",
    name: "Frozen",
    component: Frozen,
    meta: { requireAuth: true },
  },
  {
    path: "/record",
    name: "Record",
    component: Record,
    meta: { requireAuth: true },
  },
  {
    path: "/forget",
    name: "Forget",
    component: Forget,
    meta: {},
  },
  {
    path: "/selectChannel",
    name: "SelectChannel",
    component: SelectChannel,
    meta: { requireAuth: true },
  },
  {
    path: "/usdtRecharge",
    name: "UsdtRecharge",
    component: UsdtRecharge,
    meta: { requireAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = storeToken.get("site_title") || "";
  if (to.meta.requireAuth) {
    //如果需要跳转 ，往下走（1）
    if (storeToken.get("token")) {
      //判断是否登录过，如果有登陆过，说明有token,或者token未过期，可以跳过登录（2）
      if (to.path === "/login") {
        //判断下一个路由是否为要验证的路由（3）
        next(); // 如果是直接跳到首页，
      } else {
        next();
      }
    } else {
      console.log("没有"); //如果没有登陆过，或者token 过期， 那么跳转到登录页
      Toast.loading({
        message: "Conectando...",
        forbidClick: true,
        duration: 500,
        onClose() {
          next("/login");
        },
      });
    }
  } else {
    //不需要跳转，直接往下走
    next();
  }
});
export default router;
