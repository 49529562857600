<template>
  <div class="p-3">
    <div class="mb-5 flex justify-between items-center">
      <div class="flex space-x-5">
        <div class="rounded-full bg-b-2 w-11 h-11 flex justify-center items-center drop-shadow-md">
          <span class="material-symbols-outlined text-p-1"> face_6 </span>
        </div>
        <div class="text-sm flex flex-col items-left justify-center">
          <div class="font-bold">{{ UserInfo.nickname }}</div>
          <div class="text-xs text-t-2">ID: {{ UserInfo.id }}</div>
        </div>
      </div>
      <div>
        <div class="w-10 h-10 rounded-full bg-b-2 flex justify-center items-center shadow" @click="CodyLink">
          <span class="material-symbols-outlined text-t-2">
            share
          </span>
        </div>
      </div>
    </div>

    <div class="mb-5 p-3 flex justify-between rounded-md bg-b-2 shadow">
      <div class="flex flex-col justify-between">
        <div class="text-sm">{{ $t("Pofile.Balance") }}</div>
        <div class="text-3xl space-x-1">
          <span class="text-xl">{{ $t("Mex") }}{{ monetary_unit }}</span><span class="">{{ UserInfo.money }}</span>
        </div>
        <!-- <div class="text-t-2">account</div> -->
      </div>
    </div>

    <div class="w-full mb-5 flex items-center justify-between text-center space-x-2">
      <div @click="handleJumpTo('/selectChannel')" class="w-1/2 text-xs p-3 text-p-3 bg-p-1 rounded-md shadow">
        {{ $t("Pofile.chongzhi") }}
      </div>
      <div @click="handleJumpTo('/withdrawal')" class="w-1/2 text-xs p-3 text-p-3 bg-p-2 rounded-md shadow">
        {{ $t("Pofile.tixian") }}
      </div>
    </div>

    <div class="mb-5 grid grid-cols-2 gap-1 text-sm">
      <div class="p-2 bg-b-2 rounded border-l-4 border-p-1">
        <p class="font-light">{{ $t("Recolectar.jinrishouru") }}</p>
        <p class="font-bold">{{ UserInfo.dayProfit || 0 }}</p>
      </div>
      <div class="p-2 bg-b-2 rounded border-l-4 border-l-t-2">
        <p class="font-light">{{ $t("Recolectar.yiwancheng") }}</p>
        <p class="font-bold">{{ UserInfo.counted }}</p>
      </div>
      <div class="p-2 bg-b-2 rounded border-l-4 border-a-2">
        <p class="font-light">{{ $t("Recolectar.dongjie") }}</p>
        <p class="font-bold">{{ UserInfo.freeze || 0 }}</p>
      </div>
      <div class="p-2 bg-b-2 rounded border-l-4 border-p-2">
        <p class="font-light">{{ $t("Recolectar.weiwancheng") }}</p>
        <p class="font-bold">{{ UserInfo.unfinished || 0 }}</p>
      </div>
    </div>

    <div>
      <ul class="space-y-1  divide-y divide-b-2 px-2">
        <li class="flex py-2 justify-between " @click="handleJumpTo('/personal')">
          <div class="space-x-2 flex ">
            <span class="material-symbols-outlined">
              settings_account_box
            </span>
            <div>{{ $t('Pofile.geren') }}</div>
          </div>
          <span class="material-symbols-outlined">
            chevron_right
          </span>
        </li>
        <li class="flex py-2 justify-between" @click="handleJumpTo('/safe')">
          <div class="space-x-2 flex ">
            <span class="material-symbols-outlined">
              pin
            </span>
            <div>{{ $t('Pofile.anquan') }}</div>
          </div>
          <span class="material-symbols-outlined">
            chevron_right
          </span>
        </li>
        <!-- <li class="flex py-2 justify-between" @click="handleJumpTo('/invitation')">
          <div class="space-x-2 flex ">
            <span class="material-symbols-outlined">
              support_agent
            </span>
            <div>联系客服</div>
          </div>
          <span class="material-symbols-outlined">
            chevron_right
          </span>
        </li> -->
        <!-- <li class="flex py-2 justify-between" @click="handleJumpTo('/invitation')">
          <div class="space-x-2 flex ">
            <span class="material-symbols-outlined">
              crowdsource
            </span>
            <div>{{ $t('Pofile.guanyu') }}</div>
          </div>
          <span class="material-symbols-outlined">
            chevron_right
          </span>
        </li> -->
        <li class="flex py-2 justify-between" @click="outLogin">
          <div class="space-x-2 flex ">
            <span class="material-symbols-outlined">
              logout
            </span>
            <div>{{ $t('prsonal.tuichu') }}</div>
          </div>
          <span class="material-symbols-outlined">
            chevron_right
          </span>
        </li>
      </ul>
    </div>
    <tabbar />
    <!-- 弹出框 -->
    <van-dialog v-model:show="ShowInvitation" :title="$t('Pofile.fuzhi')" show-cancel-button
      :confirmButtonText="$t('alert.fuzhi')" :cancelButtonText="$t('alert.quxiao')" cancelButtonColor="#000000"
      confirmButtonColor="#007aff" @confirm="confirmShare" @cancel="cancel">
      <div class="ShowInvitationDiv">{{ url }}</div>
    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import Clipboard from "clipboard";
import userApi from "@/api/user";
import storeAction from "@/store/typed-actions";
// import homeApi from "@/api/home";
import Tabbar from "@/components/tabbar/tabbar.vue";
import { jumpTo } from "@/utils/routerUtils";
export default {
  name: "Pofile",
  components: {
    Tabbar,
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
    UserInfo() {
      console.log(this.$store.state.currentUser);
      return JSON.parse(this.$store.state.currentUser);
    },
  },
  data() {
    return {
      bgColor: "",
      aboutTxt: "",
      about_withdraw: "",
      url: "",
      ShowInvitation: false,
      // UserInfo: null,
      NumberCopy: 0,
      AuthList: [
        require("@/assets/image/auth1.jpg"),
        require("@/assets/image/auth2.jpeg"),
        require("@/assets/image/auth3.jpeg"),
        require("@/assets/image/auth4.jpeg"),
        require("@/assets/image/auth5.jpeg"),
        require("@/assets/image/auth6.jpeg"),
        "",
        require("@/assets/image/auth.jpeg"),
        require("@/assets/image/auth7.jpeg"),
      ],
      InfoMoney: '',
    };
  },
  methods: {
    handleJumpTo(path) {
      jumpTo(path);
    },


    JumpRecharge() {
      this.$router.push({
        path: "/selectChannel",
      });
    },
    cancel() { },
    confirmShare() {
      let transfer = document.createElement("input");
      document.body.appendChild(transfer);
      transfer.value = this.url; // 这里表示想要复制的内容
      transfer.focus();
      transfer.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      transfer.blur();
      this.$toast(this.$t("fuzhiOK"));
      document.body.removeChild(transfer);
    },
    JumpPersonal() {
      this.$router.push({
        path: "/personal",
      });
    },
    JumpTeam() {
      this.$router.push({
        path: "/team",
      });
    },
    JumpRecord() {
      this.$router.push({
        path: "/record",
      });
    },
    Jumpinvitation() {
      this.$router.push({
        path: "/invitation",
      });
    },
    JumpMessage() {
      this.$router.push({
        path: "/message",
      });
    },
    JumpAddress() {
      this.$router.push({
        path: "/address",
      });
    },
    JumpMine() {
      this.$router.push({
        path: "/about",
        query: {
          type: 0,
          contant: JSON.stringify(this.aboutTxt),
        },
      });
      // this.$router.push({
      //   path: "/mine",
      // });
    },
    JumpFrozen() {
      this.$router.push({
        path: "/frozen",
      });
    },
    JumpSafe() {
      this.$router.push({
        path: "/safe",
      });
    },
    JumpInvitationTask() {
      this.$router.push({
        path: "/invitationTask",
      });
    },
    JumpWithdrawal() {
      this.$router.push({
        path: "/withdrawal",
      });
    },
    CodyLink() {
      this.ShowInvitation = true;
      this.url =
        this.UserInfo.site_host + "/#/regist?invitation_id=" + this.UserInfo.id;
    },
    outLogin() {
      let that = this;

      storeAction.delsessionStorage("token");
      storeAction.delsessionStorage("userid");
      storeAction.delsessionStorage("nickname");
      storeAction.delsessionStorage("userinfo");
      that.$toast.loading({
        message: this.$t('Conectando'),
        forbidClick: true,
        duration: 500,
        onClose() {
          that.$router.push({
            path: "/login",
          });
        },
      });
    },
    userinfo() {
      userApi
        .userInfo()
        .then((data) => {
          // this.UserInfo = data.data;
          // this.InfoMoney=data.data.money
          storeAction.setCurrentUser(JSON.stringify(data.data));
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    // this.$toast.loading({
    //   message: this.$t("Conectando"),
    //   forbidClick: true,
    //   duration: 0,
    // });
    // homeApi
    //   .siteInfo()
    //   .then((data) => {
    //     console.log(data.data, "基础数据");
    //     this.aboutTxt = data.data.about;
    //     this.about_withdraw = data.data.about_withdraw;
    //   })
    //   .catch((err) => {
    //     this.$toast.clear();
    //     this.$toast.fail(err.message);
    //   });
    this.aboutTxt = localStorage.getItem("aboutTxt");
    this.userinfo();
  },
};
</script>
<style lang="less" scoped>
.pofile {
  background: #f7f8fc;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.info {
  height: 1.71rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0.27rem 0.4rem;
  border-bottom: 1px solid #eeeff2;
  position: relative;
}

.top-left {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.userAuth {
  width: 1.6rem;
  height: 1.6rem;
  border: 2px solid #fff;

  img {
    border-radius: 100%;
  }
}

.mine-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 1.33rem;
  margin-left: 0.59rem;
}

.telphoneandvip {
  font-size: 0.5rem;
}

.code {
  margin-top: 0.13rem;
  white-space: nowrap;
}

.top-right {
  position: absolute;
  top: 0.27rem;
  right: 0.27rem;
}

.copy {
  height: 0.69rem;
  background: #fff;
  border-radius: 0.69rem;
  border: 1px solid #eee;
  text-align: center;
  line-height: 0.69rem;
  padding: 0 0.19rem;
}

.account {
  height: 2.45rem;
  background: #fff;
  padding-top: 0.27rem;
  padding-bottom: 0.27rem;
}

.account .pos {
  box-sizing: border-box;
  justify-content: space-between;
  background: linear-gradient(315deg, #a78e6f, #d5bd9d);
  border-radius: 0.21rem;
  height: 2.45rem;
  margin: 0 0.27rem;
  display: flex;
  padding: 0 0.53rem;
}

.balance {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.label {
  font-size: 0.4rem;
  font-weight: 400;
  color: #fff;
}

.opertion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount {
  font-size: 0.64rem;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #fff;
  margin-top: 0.13rem;
}

.withdrawal {
  height: 0.69rem;
  border-radius: 13px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 0.69rem;
  font-size: 0.35rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  margin-right: 0.32rem;
  padding: 0 0.13rem;
}

.recharge {
  height: 0.69rem;
  border-radius: 0.35rem;
  background: #fff;
  border: 1px solid #fff;
  text-align: center;
  line-height: 0.69rem;
  font-size: 0.35rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ae9576;
  padding: 0 0.13rem;
}

.card {
  background: #fff;
  margin: 0.16rem 0.27rem;
  border-radius: 0.27rem;
}

.card .itemlist {
  padding: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-content: flex-start;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-width: 20%;
  position: relative;
}

.picon {
  width: 0.93rem;
  height: 0.93rem;
}

.name {
  font-weight: 400;
  font-size: 0.32rem;
  margin-top: 0.08rem;
}

.value {
  font-size: 0.4rem;
  font-weight: 500;
  color: #23292e;
  margin-top: 0.08rem;
}

.person {
  background: #fff;
  margin-top: 0.16rem;

  .title {
    padding: 0.27rem 0.27rem 0.27rem 0.53rem;
    font-size: 0.37rem;
    font-weight: 700;
    border-bottom: 1px solid #eeeff2;
    text-align: left;
  }

  .itemlist {
    padding: 0.16rem 0.27rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}

.icon-div {
  width: 0.93rem;
  height: 0.93rem;
  justify-content: center;
  border-radius: 50%;
  display: flex;
  align-items: center;

  img {
    width: 0.53rem;
    height: 0.53rem;
  }
}

.b1 {
  background: linear-gradient(90deg, #eb645a, #e75147);
}

.b2 {
  background: linear-gradient(90deg, #83b7f4, #153ed7);
}

.b3 {
  background: linear-gradient(90deg, #92deb7, #4cbf8e);
}

.b4 {
  background: linear-gradient(90deg, #d576f7, #8d38f4);
}

.b5 {
  background: linear-gradient(90deg, #f8d78b, #f0a430);
}

.b6 {
  background: linear-gradient(90deg, #f0a35b, #ea473b);
}

.b7 {
  background: linear-gradient(90deg, #eb645a, #e75147);
}

.b8 {
  background: linear-gradient(90deg, #6fbef8, #388ff4);
}

.ShowInvitationDiv {
  padding: 0.3rem 0.64rem;
  padding-top: 0.6rem;
  min-height: 40px;
  font-size: 15px;
  line-height: 1.4;
  color: #999;
  max-height: 400px;
  overflow-x: hidden;
  text-align: center;
  overflow-y: auto;
}
</style>