const pe = {
  home: {
    title: "Loja Amazônia",
    chongzhi: "Recarga",
    tixian: "Retirada",
    yaoqing: "Tarefa de convite",
    Aviso: "Aviso",
    Balance: "Balanço",
    Hoy: "Receitas",
    Financiero: "Financeira",
    Congelar: "Congelar",
    MiembroNoticias: "Honor Roll",
    Socio: "parceiro",
    About: "About Us",
    aboutwithdrawal: "About withdrawal",
  },
  about: {
    title: "About Us",
    guanyutixian: "About withdrawal",
  },
  Recargar: {
    title: "Recarga",
    Introducir: "Digitar o valor",
    tps: "Quantidade mínima de recarga",
    Selección: "selecionar valor rápido",
    text: " Dica: Mudança da conta bancária da plataforma a qualquer momento, a cada depósito, vá para a página de recarga para obter o número da conta mais recente, não há responsabilidade para nós se você depositar em uma conta expirada",
  },
  RecargarAction: {
    title: "Enviar recarga",
    Cantidad: "Cantidad recargada",
    Información: "Información del beneficiario",
    tps: "¡Una vez completada la transferencia, asegúrese de subir la captura de pantalla de la transferencia, y luego haga clic en el botón enviar!",
    jietu: "Captura de pantalla de transferencia de carga",
    querenchongzhi: "Confirmar recarga",
  },
  Recolectar: {
    title: "Capturar",
    Pedidos: "pedidos coletados",
    yongjin: "comissão",
    jinrishouru: "Comissão hoje",
    gerenshouru: "comissão personal",
    yiwancheng: "Completar o pedido ",
    dongjie: "Produtos congelados",
    weiwancheng: "Ordens remanescentes",
    dongjiejine: "Quantidade congelada",
    dangqianyue: "saldo atual",
    kaishi: "auto-captura",
    tingzhi: "para de capturar",
    tishi: "Notas",
    Segundo: "segundo",
    text: "Nota: Quando um usuário corresponde a um pedido, a plataforma envia as informações do pedido ao comerciante em sincronia. Se um usuário não pagar um pedido dentro de  60 minutos, o pedido será congelado para contornar a plataforma de compras online. Quando uma ordem é congelada, os fundos para a ordem também são congelados.  ",
  },
  Registro: {
    title: "registro",
    daizhifu: "Pendente",
    yijiesuan: "Finalizado",
    chulizhong: "Assentamento",
    yidongjie: "Congelado",
    meiyoujilu: "sem mais dados",
    xianzaishouji: "vá capturar",
    jiage: "Preço",
    yongjin: "taxa comissão",
    shouyi: "Estimada",
    shengyutime: "Tempo restante",
    chakan: "Ordem de visualização",
    weizhifu: "Não pago",
    fukuan: "Pagamento",
  },
  Mex: "",
  Confirmar: "Confirmar a recarga",
  Conectando: "Carregadndo",
  fuzhiOK: "Copiado para a pasta",
  Detalles: {
    title: "detalhes de pedido",
    yigong: " No total",
    dingdan: " Ordens",
    dangqian: "Actualmente o segundo",
    yiwancheng: "o pedido está terminado",
    dongjie: "As ordens estão congeladas",
    dengdai: "Esperando que o comprador confirme a ordem",
    dongjies: "congelado",
    zhuyao: "Loja principal do comerciante",
    guanfang: "Garantias oficiais",
    zongshuliang: "montante total",
    daigou: "taxa de comissão",
    shouyi: "comissão estimado",
    fukuanzt: "Status do pagamento",
    weizhifu: "Não pago",
    yizhifu: "Pagado",
    jiedong: "Hora de derreter",
    zhifu: "pago",
    shuruzhifu: "Por favor, introduz a senha do pagamento",
    yanzheng: "Verificar a senha do pagamento",
  },
  Pofile: {
    title: "Meu",
    yaoqingma: "Código de Convite",
    fuzhi: "copiar link",
    Balance: "saldo",
    tixian: "saque",
    chongzhi: "recarregar",
    zongji: "lucro total",
    jinrong: "tesouro",
    xiangqing: "Detalhes",
    dongjie: "Congelar",
    geren: "Personal",
    tuandui: "Diretamente",
    yaoqing: "Convidar amigo",
    xiaoxi: "Mensagem",
    dizhi: "Endereço",
    guanyu: "sobre nós",
    renwu: "tarefa de convite",
    anquan: "segurança",
    fuwu: "Centro de serviço",
  },
  alert: {
    title: "Dicas",
    quxiao: "Cancelar",
    queding: "Determinar",
    acuerdo: "confirmar",
    fuzhi: "copiar",
    qianwang: "vincular agora",
  },
  withdrawal: {
    title: "saque",
    yue: "saldo atual",
    shouxufei: "taxa de saque",
    dancitixian: "Taxa mínima para um único saque",
    shuru: "digite o valor",
    dangqianjine: "montante real por conta",
    suoyou: "todas saques",
    zhanghuming: "Nome do banco",
    zhanghu: "Número de conta",
    dianhua: "Número de telefone",
    tps: "dicas",
    text: "1.Preencha as informações de sua conta bancária com precisão e não seremos responsáveis pela perda de fundos devido a informações incorretas sobre você;2.O horário de chegada é antes das 24 horas do dia seguinte, a hora exata de chegada está sujeita ao banco.",
    queren: "confirmar a saque",
    guanlian: "Por favor vincular seu cartão de banco antes de saque",
  },
  prsonal: {
    title: "perfil",
    name: "Nome",
    shouji: "Telefone",
    yhk: "cartão  banco",
    tuichu: "logout",
    tianxie: "Por favor vincular",
  },
  card: {
    title: " vincule seu cartão de banco",
    zhanghuxinxi: "Informações Da conta",
    tps: "Por favor, confirme que o SEU número de conta está correcto, não assumimos qualquer responsabilidade causada pelo input errado.",
    yhmc: "Nome do Banco de depósito",
    zhanghuming: "Nome do utilizador ",
    zhanghu: "Contas",
    shouji: "Número de telemóvel",
    fenhang: "银行分行名称",
    baocun: "salvar",
    jianyi: "dicas：",
    text: "Se você precisar modificar as informações da sua conta de coleta, entre em contato com o Serviço de Atendimento ao Cliente Online",
    qyh: "Por favor, preencha ",
    qsryhm: "Por favor, preencha o Nome do usuário",
    qzh: "Por favor, preencha o número Da conta",
    qdh: "Por favor, insira o número de telefone móvel",
    qfhmc: "请输入分行名称",
    qsrifcs: "Por favor IFSC",
    youxiang: "Caixa postal",
    plyx: "Por favor insira e- mail",
    jgbh: "Número da agência",
    pljgbh: "Por favor, Digite BankBranchName",
    gjj: "CPF",
    plgjj: "Por favor, Digite ",
    isbp: "ISBP",
    plisbp: "Por favor, Digite ISPB",
    pixtype: "PIXType",
    plpixtype: "Por favor, Digite PIXType",
    pix: "PIX",
    plpix: "Por favor, Digite PIX",
    evp: "EVP",
    plevp: "Por favor, Digite EVP",
  },
  team: {
    title: "Diretamente Subordinados",
    Directo: "primeira",
    Secundario: "segunda ",
    Terciario: "terceira ",
    Comisión: "comissão promocional de hoje",
    zongyongjin: "comissão promocional total",
    shuzi: "Número de pessoas",
    sudu: "Avaliar",
    jinricz: "Today Recarregue",
    tixian: "Today Retire",
  },
  invitation: {
    title: "convidar amigo",
    notitle: "convidar amigos para ganhar dinheiro junto",
    notitles: "Você pode trabalhar em tempo parcial por telefone",
    yqm: " Código de Convite：",
    fuzhi: "Copy",
    xzyq: "Clique para copiar link",
  },
  message: {
    title: "mensagem",
    go: "contacto",
  },
  address: {
    title: "gestão de endereço",
    xm: "Recebedor",
    dianhua: "telefone",
    qtx: "Por favor preencha",
    dizhixq: "Endereço de detalhe",
    baocun: "salvar",
  },
  invitationTask: {
    title: "tarefa de convite",
    zongren: "Quantidade total de pessoas convidadas",
    gengxin:
      " Tarefa de atualização: O usuário que foi recarregado é um usuário válido",
    yq: "convite",
    jige: "Membros válidos",
    qianwang: "ir para",
    renwujiangli: "Recompensa da tarefa",
  },
  safe: {
    aqgl: "Gestão de segurança",
    denglu: "senha de login",
    zfmm: "senha de pagamento",
    bh: "protegendo",
  },
  password: {
    title: "Senha de autenticação",
    titles: "Senha de pagamento",
    shouji: "Telefone",
    yanzheng: "Código ",
    fasong: "Obtivermos",
    xinmima: "Nova Senha",
    pxinmima: "Indique a nova senha",
  },
  record: {
    title: "detalhe da conta",
    quanbu: "todos registros",
    chongzhi: "recarregar",
    tixian: "saque",
    dingdan: "captura",
    tuandui: "comissão",
    zongsu: " número total",
    dangqian: "número atual",
    kaishisj: "hora de início",
    jieshusj: "hora de fim",
  },
  login: {
    zhanghu: "número de telefone/conta",
    mima: "por favor insira a senha",
    denglu: "Conecte-se",
    zhuce: "Sem conta, registe-se agora",
    wjmm: " Esqueça a senha",
  },
  regist: {
    touxiang: "Bem vindo ao inscrever-se",
    yhm: "usuário",
    mima: "senha",
    zhifu: "Senha de pagamento",
    yq: "convite",
    pyhm: "por favor digite o nome do utilizador",
    ptel: "por favor digite o seu número de telefone",
    shuru: "por favor a digite a senha de login",
    shurupay: "por favor a digite a senha de pagamento",
    pyqm: " Por favor digite o código de convite",
    plyqm: "Por favor digite o código de convite",
    zhuce: " Registrar",
    yijing: "Já tem uma conta?",
  },
  tabbars: {
    shouye: "Inicial",
    chongzhi: "Recarga",
    shouji: "Capturar",
    dingdan: "Pedido",
    wode: "Minha",
  },
  zhaohuimima:
    "Entre em contato com o serviço de atendimento ao cliente para manuseio",
  yanzhengzhifu: "Verificar a senha do pagamento",
  huanying: "Bem-vindo ao login",
  yinhang: "Banco",
  yinhangbh: "Número de banco",
  beizhu: "Observación",
  zhifufs:'Método de pagamento',
  qxzzffs:'Por favor seleccione o método de pagamento',
};
export default pe;
