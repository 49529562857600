<template>
  <div class="mine">
    <!-- <header>
      <van-nav-bar title="Registro de equipo">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" />
        </template>
      </van-nav-bar>
    </header> -->
    <div class="main">
      <p>Buyer order-mall</p>
      <p><br /></p>
      <p><br /></p>
      <p>
        Esta plataforma es una plataforma de emparejamiento inteligente de
        terceros. La plataforma coopera con los comerciantes en las plataformas
        de compras existentes (como Falabella, MercadoPago, eBay, Segundamano,
        Linio, ticketmaster y otros comerciantes de plataformas de compras en
        línea) y utiliza sistemas inteligentes para hacer coincidir los pedidos
        de los comerciantes con los usuarios de la plataforma para aumentar los
        datos de ventas. Usuarios de la plataforma. Oficina central. plataforma.
      </p>
      <p><br /></p>
      <p><br /></p>
      <p><br /></p>
      <p>
        Con el fin de crear las mejores condiciones de descuento para nuestros
        usuarios, hemos creado un sistema y reglas de recompensa más efectivos,
        y permitimos que los consumidores participen en todo el proceso de
        compra. Se ha demostrado que nuestro alto retorno de la inversión único
        aumenta las ventas y las visitas de productos.
      </p>
      <p><br /></p>
      <p><br /></p>
      <p>
        El usuario solo necesita proporcionar la dirección de entrega real, el
        número de teléfono y la información de compra real en la plataforma, y
        el sistema inteligente puede pasar el pedido al servidor en la nube del
        centro comercial para que coincida con el pedido apropiado. Nota: La
        plataforma no cobrará tarifas adicionales a los miembros de ninguna
        manera. La plataforma mantiene toda su información altamente
        confidencial.
      </p>
      <p><br /></p>
      <p><br /></p>
      <p>¡Unirse al Buyer Order-Mall es su elección más inteligente! ! !</p>
    </div>
    <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Mine",
  components: {
    Tabbar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.mine {
  background: #fff;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.main {
  padding: 0.32rem;
  box-sizing: border-box;
}
</style>