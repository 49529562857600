<template>
  <div class=" h-screen static p-3">
    <!-- <header>
      <van-nav-bar :title="$t('Detalles.title')" :style="bgColor">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header> -->
    <div class="flex flex-col">
      <!-- <div class="address flex-column" v-if="goods.address.address_mark &&
        goods.address.address_markaddress_mobile &&
        goods.address.address_name
        ">
        <div class="location">
          <img src="@/assets/image/location.png" alt="" />
        </div>
        <div class="top">{{ goods.address.address_mark }}</div>
        <div class="bottom">
          <div class="name">{{ goods.address.address_name }}</div>
          <div class="phone">{{ goods.address.address_mobile }}</div>
        </div>
        <div class="arrow">
          <div class="icon-bianji">
            <img src="@/assets/image/bianji.png" alt="" />
          </div>
        </div>
      </div> -->
      <div v-if="goods.status == 0" class=" bg-b-2 p-2 rounded-md  text-t-2 text-sm mb-3">
        <div>{{ goods.goodsNum }}
          {{ $t("Detalles.dangqian")
          }}{{ " " + goods.present_num + " " }}
        </div>
      </div>

      <div class="flex flex-col bg-a-2 text-sm p-2 rounded-md text-t-2 mb-2">
        <div class="" v-if="goods.status == 1 || goods.status == 2">
          {{ $t("Detalles.yiwancheng") }}
        </div>
        <div class="" v-else-if="goods.status == 3">
          {{ $t("Detalles.dongjie") }}
        </div>

        <div class="" v-else>
          <span> {{ $t("Detalles.dengdai") }}</span>
          <van-count-down :time="time" />
          <span>{{ $t("Detalles.dongjies") }}.</span>
        </div>
      </div>

      <div class="flex p-2 bg-b-2 rounded-md items-center justify-between space-x-2">
        <div class="w-1/3">
          <img class="h-20 " :src="goods.goods_pic" alt="" />
        </div>
        <div class="flex-2/3">
          <div class="text-xs  font-light">{{ goods.goods_name }}</div>
          <div class="flex font-bold justify-between text-t-2">
            <div>{{ $t("Mex") }}{{ monetary_unit }}{{ goods.quantity }}</div>
            <div>x1</div>
          </div>
          <!-- <span class="bg-p-2 p-1 rounded-md text-p-3 text-xs">{{ $t("Detalles.guanfang") }}</span> -->
        </div>
      </div>

      <div class="flex flex-col p-2 font-light text-sm text-t-2">
        <div class="flex justify-between">
          <div>{{ $t("Detalles.zongshuliang") }}</div>
          <div>{{ $t("Mex") }}{{ monetary_unit }}{{ goods.quantity }}</div>
        </div>
        <div class="flex justify-between">
          <div>{{ $t("Detalles.daigou") }}.</div>
          <div>{{ goods.profit_percent }}%</div>
        </div>
        <div class="flex justify-between">
          <div>{{ $t("Detalles.shouyi") }}</div>
          <div v-if="goods.status != 3">{{ goods.profit }}</div>
        </div>
        <div class="flex justify-between">
          <div>{{ $t("Detalles.fukuanzt") }}</div>
          <div class="color3a78cb" v-if="goods.status == 0">
            {{ $t("Detalles.weizhifu") }}
          </div>
          <div class="color3a78cb" v-else>{{ $t("Detalles.yizhifu") }}</div>
        </div>
        <div class="flex justify-between" v-if="goods.status == 3">
          <div>{{ $t("Detalles.jiedong") }}</div>
          <div><van-count-down :time="time" /></div>
        </div>
      </div>


      <div class="flex w-full p-2 items-center justify-between  bg-b-2 rounded-md" v-if="goods.status == 0">
        <div class="flex-auto">
          {{ $t("Mex") }}{{ monetary_unit }}{{ goods.quantity }}
        </div>
        <div class="flex-1 bg-p-1 text-p-3 text-center rounded-md" @click="ChangePay">
          {{ $t("Detalles.zhifu") }}</div>
      </div>


    </div>
    <tabbar />
    <!-- 二级密码遮罩层 -->
    <van-popup v-model:show="ShowPassCode" position="bottom" :style="{ height: '22rem' }" round>
      <div class="flex flex-col">
        <div class="flex flex-col space-y-1">
          <div class="py-2 text-center">{{ $t("Detalles.shuruzhifu") }}</div>
          <div class="items-center">
            <van-password-input :gutter="10" :value="passCode" :focused="showKeyboard" />
          </div>
        </div>
        <div class="pwd-forget"></div>
        <div class="digital-keyboard digital-keyboard-show">
          <van-number-keyboard v-model="passCode" :extra-key="[]" :show="showKeyboard" />
        </div>
      </div>
    </van-popup>
    <!-- 错误提示 -->
    <van-dialog v-model:show="ShowInvitation" :title="$t('alert.title')" show-cancel-button
      :confirmButtonText="$t('alert.queding')" :cancelButtonText="$t('alert.quxiao')" cancelButtonColor="#000000"
      confirmButtonColor="#007aff" @confirm="showPayGo">
      <div class="ShowInvitationDiv">{{ TpsTxt }}</div>
    </van-dialog>
    <!-- 广告弹窗 -->
    <van-dialog v-model:show="showPay" :title="$t('alert.title')" :confirmButtonText="$t('alert.queding')"
      confirmButtonColor="#007aff" @confirm="showPaycosel">
      <img :src="payImg" />
    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import times from "@/utils/time";
import orderApi from "@/api/order";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "OrderDetail",
  components: { Tabbar },
  data() {
    return {
      goods: "",
      passCode: "",
      ShowPassCode: false,
      showKeyboard: true,
      // selectListActiveID: "1",
      TpsTxt: "",
      ShowInvitation: false,
      time: 60 * 1000,
      showpayTitle: "",
      showPay: false,
      payImg: "",
      go: "",
      TimeCurStr: "",
      bgColor: "",
    };
  },
  watch: {
    passCode(value) {
      let that = this;
      if (value.length === 6) {
        this.$toast.loading({
          message: this.$t("Detalles.yanzheng"),
          forbidClick: true,
          duration: 1000,
          onClose() {
            // that.ShowPassCode = false;
            that.Pay();
          },
        });
      }
    },
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
    selectListActiveID() {
      console.log(this.$store.state.selectListActiveID);
      return this.$store.state.selectListActiveID;
    },
    out_time_pay() {
      console.log(this.$store.state.out_time_pay);
      return this.$store.state.out_time_pay;
    },
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    // 打开支付键盘
    ChangePay() {
      this.ShowPassCode = true;
    },
    showPaycosel() {
      this.showPay = false;
      this.passCode = "";
      if (this.goods.goodsNum != this.goods.present_num) {
        this.JumpOrder();
      } else {
        // 前往订单列表
        this.$router.push({
          path: "/registro",
          query: {
            type: 2,
          },
        });
      }
    },
    // 支付接口
    Pay() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      orderApi
        .pay(this.passCode, this.goods.order_id, this.goods.id)
        .then((data) => {
          this.ShowPassCode = false;
          this.$toast.clear();
          console.log(
            this.goods.goodsNum,
            this.goods.present_num,
            "订单第几个"
          );
          console.log(data.data.popup, "支付弹窗返回");
          // 支付完成调用收集
          if (data.data.popup != null) {
            // 广告弹窗
            this.payImg = data.data.popup.url;
            this.showPay = true;
          } else {
            this.passCode = "";
            if (data.data.order.total_goods_count != this.goods.present_num) {
              this.JumpOrder();
            } else {
              // 前往订单列表
              this.$router.push({
                path: "/registro",
                query: {
                  type: 2,
                },
              });
            }
          }
        })
        .catch((err) => {
          this.ShowPassCode = false;
          this.$toast.clear();
          // this.$toast.fail(err.message);
          if (err.code == "1001") {
            this.go = true;
          } else {
            this.go = false;
          }
          this.TpsTxt = err.message;
          this.ShowInvitation = true;
        });
    },
    // 收集接口
    JumpOrder() {
      this.$toast.loading({
        message: this.$t("Conectando"),
        forbidClick: true,
        duration: 0,
      });
      orderApi
        .snatch(this.selectListActiveID)
        .then((data) => {
          let goods = data.data.order_goods;
          if (
            data.data.order.total_goods_count != null &&
            data.data.order.total_goods_count != undefined
          ) {
            goods["goodsNum"] = data.data.order.total_goods_count;
          }
          if (data.data.order.address_name) {
            goods["address"] = {
              address_mark: data.data.order.address_mark,
              address_name: data.data.order.address_name,
              address_mobile: data.data.order.address_mobile,
            };
          } else {
            goods["address"] = "";
          }
          let timeData = times.formatDate(data.data.order_goods.created_at);
          // 最终时间
          console.log(
            data.data.order_goods.created_at,
            "data.data.order_goods.created_at"
          );
          console.log(timeData, "timeData");
          timeData = this.time_to_sec(timeData) + this.out_time_pay * 60;
          console.log(timeData);
          // 当前时间
          // let timeData_dang = times.formatDate(new Date());
          let timeData_dang = data.message;
          console.log(data.message, "当前时间");
          console.log(timeData_dang, "timeData_dang");
          timeData_dang = this.time_to_sec(timeData_dang);
          console.log(timeData - timeData_dang);
          //剩余时间
          let shengyu = timeData - timeData_dang;
          // let dangqianTime=new Date()
          // 后台单位是已分钟给的-自己是用秒计算
          //  time: 60 * 1000,
          this.time = shengyu * 1000;
          this.goods = goods;
          console.log(data.data);
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.TpsTxt = err.message;
          this.ShowInvitation = true;
        });
    },
    time_to_sec(time) {
      if (time !== null) {
        let s = "";
        let hour = time.split(":")[0];
        let min = time.split(":")[1];
        let sec = time.split(":")[2];
        s = Number(hour * 3600) + Number(min * 60) + Number(sec);
        return s;
      }
    },
    showPayGo() {
      if (this.go) {
        this.$router.push({
          path: "/recharge",
        });
      }
    },
    TimeCurFun() { },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    this.goods = JSON.parse(this.$route.query.goods);

    orderApi.TimeCur().then((data) => {
      console.log(data.data.DateTime, "时间");
      this.TimeCurStr = data.data.DateTime;
      console.log(this.goods, "this.goods");
      // let data= new Date(this.goods.created_at)
      let timeData = times.formatDate(this.goods.created_at);
      let timeData_dang;
      // 最终时间
      if (this.goods.status == 3) {
        // timeData = this.time_to_sec(timeData) + 142560 * 60;
        timeData = times.dongjie(this.goods.created_at);
        timeData = new Date(timeData).getTime() / 1000;
        let dangtime = new Date(data.data.date.toString().replace(/-/g, "/")).getTime() / 1000;
        timeData_dang = dangtime;
      } else {
        timeData = this.time_to_sec(timeData) + this.out_time_pay * 60;
        timeData_dang = this.TimeCurStr;
        timeData_dang = this.time_to_sec(timeData_dang);
      }
      // 当前时间
      // let timeData_dang = times.formatDate(new Date());

      //剩余时间
      let shengyu = timeData - timeData_dang;
      // let dangqianTime=new Date()
      // 后台单位是已分钟给的-自己是用秒计算
      //  time: 60 * 1000,
      this.time = shengyu * 1000;
      // console.log(parseInt(data.getTime()/1000+180),parseInt(dangqianTime.getTime()/1000));
      // this.time=parseInt(data.getTime()/1000+180),parseInt(dangqianTime.getTime()/1000)
    });
  },
};
</script>
<style lang="less" scoped>
.orderDetail {
  background: #f7f8fc;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.card {
  padding: 0.4rem;
  background: #fff;
  margin-top: 0.27rem;
  border-radius: 0.27rem;
}

.txt {
  font-weight: 700;
  margin-right: 0.27rem;
  color: #666;
}

.status {
  font-size: 0.35rem;
  background: #fbf8d9;
  padding: 0.4rem;
}

.goods .top {
  margin-bottom: 0.13rem;
  justify-content: flex-start;
  font-size: 0.4rem;
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-shangdian {
  width: 0.4rem;
  height: 0.4rem;
  margin: 0 0.27rem;
  margin-bottom: 0.07rem;
}

.bottom {}

.shopingImg {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
  }
}

.bottom .right {
  width: calc(100% - 2.67rem);
}

.right .title {
  font-size: 0.37rem;
  margin-bottom: 0.13rem;
  height: 1.07rem;
  overflow: hidden;
}

.number div:first-child {
  font-size: 0.43rem;
}

.number div:last-child {
  color: #666;
}

.tag {
  color: #fff;
  font-size: 0.32rem;
  padding: 0.05rem 0.13rem;
  border-radius: 0.13rem;
  background: #4caf50;
  display: inline-block;
  margin-top: 0.08rem;
}

.count {
  color: #999;
  font-size: 0.37rem;
}

.count div:first-child {
  margin-bottom: 0.13rem;
}

.color3a78cb {
  color: #3a78cb;
  font-weight: 700;
}

.fix {
  width: 100%;
  height: 1.33rem;
  padding: 0 0.4rem;
  background: #fff;
  border-top: 0.05rem solid #f2f2f2;
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 999;
}

.saveMoney {
  color: #000;
  font-size: 14px;
  width: calc(100% - 3.87rem);
}

.save {
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 1.87rem;
  height: 0.8rem;
  font-size: 0.35rem;
  border-radius: 5px;
}

.save::before {
  content: " ";
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  box-sizing: border-box;
  border-radius: 10px;
}

.dk-swiper {
  height: 3.47rem;
}

.digital-keyboard-modal.digital-keyboard-modal-1,
.digital-keyboard-modal.digital-keyboard-modal-4 {
  height: auto;
  background: #fff url(../assets/image/input.png) top/100% auto no-repeat;
}

.dk-title {
  font-size: 0.4rem;
  font-weight: 800;
  color: #fff;
  line-height: 1.2rem;
  text-align: center;
}

.dk-subtitle {
  font-size: 0.32rem;
  color: #989894;
  line-height: 3;
  text-align: center;
}

.pwd-box {
  padding-left: 0.13rem;
  position: relative;
  text-align: center;
}

.pwd-forget {
  font-size: 0.32rem;
  color: #26c6b3;
  padding: 0.27rem 0 0.4rem;
  text-align: center;
  line-height: 0.43rem;
}

.pwd-text.active:after {
  animation: twinkle-data-v-0b1f9cd8 1s infinite;
  height: 70%;
  width: 1px;
  content: "";
  position: absolute;
  top: 15%;
  left: 50%;
  margin-left: -1px;
  background-color: #4fa5e1;
}

.ShowInvitationDiv {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.address {
  min-height: 2.13rem;
  background: #fff;
  padding: 0 0.4rem 0 1.6rem;
  border-bottom-left-radius: 0.27rem;
  border-bottom-right-radius: 0.27rem;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 2px dashed #1e7eee;
  position: relative;
}

.location {
  width: 0.8rem;
  height: 0.8rem;
  // line-height: 0.8rem;
  text-align: center;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
  border-radius: 50%;
  position: absolute;
  left: 0.4rem;

  img {
    width: 0.53rem;
    height: 0.53rem;
    margin-top: 0.13rem;
  }
}

.top {
  font-size: 0.43rem;
  width: 280px;
}

.address .bottom {
  font-size: 0.35rem;
  margin-top: 0.08rem;
  display: flex;
}

.address .bottom .name {
  margin-right: 0.27rem;
}

.arrow {
  position: absolute;
  right: 15px;
}

.icon-bianji {
  font-size: 0.67rem;
  color: #8e9297;
}

.van-count-down {
  display: inline;
}
</style>
