const de = {
  home: {
    title: "Käufer Bestellzentrum",
    chongzhi: "Aufladen",
    tixian: "Abheben",
    yaoqing: "Einladung",
    Aviso: "Hinweise",
    Balance: "Kapital",
    Hoy: "Heute",
    Financiero: "Finanzen",
    Congelar: "Einfrieren",
    MiembroNoticias: "Mitgliederinformationen",
    Socio: "Kooperation",
    About: "Über uns",
    aboutwithdrawal: "Über Abheben",
  },
  about: {
    title: "Über uns",
    guanyutixian: "Über Abheben",
  },
  Recargar: {
    title: "Aufladen",
    Introducir: "Bitte geben Sie den Aufladebetrag ein",
    tps: "Mindestaufladebetrag",
    Selección: "Betrag schnell auswählen",
    text: "Hinweis: Das Konto der Bankplattform ändert sich jederzeit. Für jede Einzahlung gehen Sie bitte auf die Seite, auf der Sie die Informationen ausfüllen, und geben die neueste Kontonummer an. Wenn Sie auf ein abgelaufenes Konto einzahlen, sind wir nicht verantwortlich!",
  },
  RecargarAction: {
    title: "Aufladen absenden",
    Cantidad: "Aufladebetrag",
    Información: "Zahlungsinformationen",
    tps: "Nach Abschluss der Überweisung laden Sie bitte unbedingt zuerst den Überweisungsscreenshot hoch und klicken dann auf die Schaltfläche Absenden!",
    jietu: "Überweisungsscreenshot hochladen",
    querenchongzhi: "Aufladen bestätigen",
  },
  Recolectar: {
    title: "Bestellungen sammeln",
    Pedidos: "Bestellprovision",
    yongjin: "Provision",
    jinrishouru: "Heutiges Einkommen",
    gerenshouru: "Persönliches Einkommen",
    yiwancheng: "Abgeschlossene Bestellung",
    dongjie: "Einfrieren der Bestellung",
    weiwancheng: "Ausstehende Bestellungen",
    dongjiejine: "Eingefrorener Betrag",
    dangqianyue: "Aktuelles Guthaben",
    kaishi: "Sammeln starten",
    tingzhi: "Sammeln stoppen",
    tishi: "Hinweise",
    Segundo: "Zweite",
    text: "Nachdem der Benutzer die Bestellung abgeschlossen hat, sendet die Plattform die Bestellinformationen gleichzeitig an den Verkäufer. Wenn der Benutzer die Bestellung innerhalb einer bestimmten Zeit nicht bezahlt, wird die Bestellung eingefroren, um die Überwachung der Online-Einkaufsplattform zu vermeiden. Sobald die Bestellung eingefroren ist, ist auch das Geld in der Bestellung eingefroren. Sie müssen warten, bis das System sie in einigen Stunden auftaut. Denken Sie daran.",
  },
  Registro: {
    title: "Aufzeichnung",
    daizhifu: "Zu zahlen",
    yijiesuan: "Abgerechnet",
    chulizhong: "In Bearbeitung",
    yidongjie: "Eingefroren",
    meiyoujilu: "Keine Aufzeichnung",
    xianzaishouji: "Jetzt sammeln",
    jiage: "Preis",
    yongjin: "Provision",
    shouyi: "Profit",
    shengyutime: "Verbleibende Zeit",
    chakan: "Bestellung ansehen",
    weizhifu: "Nicht bezahlt",
    fukuan: "Zahlung",
  },
  Mex: "",
  Confirmar: "Bestätigen",
  Conectando: "Laden",
  fuzhiOK: "In die Zwischenablage kopiert",
  Detalles: {
    title: "Bestelldetails",
    yigong: "Insgesamt",
    dingdan: "Bestellungen",
    dangqian: "Momentan die zweite",
    yiwancheng: "Bestellung abgeschlossen",
    dongjie: "Bestellung eingefroren",
    dengdai: "Warten auf die Bestätigung der Bestellung durch den Käufer",
    dongjies: "eingefroren",
    zhuyao: "Hauptgeschäft",
    guanfang: "Offizielle Garantie",
    zongshuliang: "Gesamtmenge",
    daigou: "Einkaufsgebühr",
    shouyi: "Profit",
    fukuanzt: "Zahlungsstatus",
    weizhifu: "Nicht bezahlt",
    jiedong: "Auftauzeit",
    zhifu: "Zahlung",
    yizhifu: "Bezahlt",
    shuruzhifu: "Bitte geben Sie das Zahlungspasswort ein",
    yanzheng: "Zahlungspasswort überprüfen",
  },
  Pofile: {
    title: "Kontodetails",
    yaoqingma: "Einladungscode",
    fuzhi: "Link kopieren",
    Balance: "Kapital",
    tixian: "Abheben",
    chongzhi: "Aufladen",
    zongji: "Gesamt",
    jinrong: "Finanzen",
    xiangqing: "Details",
    dongjie: "Einfrieren",
    geren: "Persönlich",
    tuandui: "Team",
    yaoqing: "Einladung",
    xiaoxi: "Nachricht",
    dizhi: "Adresse",
    guanyu: "Über uns",
    renwu: "Einladungsaufgabe",
    anquan: "Sicherheit",
    fuwu: "Servicezentrum",
  },
  alert: {
    title: "Hinweise",
    quxiao: "Abbrechen",
    queding: "OK",
    acuerdo: "Zustimmen",
    fuzhi: "Kopieren",
    qianwang: "GEHEN",
  },
  withdrawal: {
    title: "Abheben",
    yue: "Aktuelles Guthaben",
    shouxufei: "Abhebegebühr",

    dancitixian: "Mindestbearbeitungsgebühr für einzelne Auszahlungen",
    shuru: "Bitte geben Sie den Betrag ein",
    dangqianjine: "Auszahlungsbetrag des aktuellen Kontos",
    suoyou: "Alles abheben",
    zhanghuming: "Kontoname",
    zhanghu: "Konto",
    dianhua: "Telefonnummer",
    tps: "Tipps",
    text: "1. Bitte geben Sie die Bankinformationen genau ein. Wir übernehmen keine Verantwortung für den Kapitalverlust, der durch einen möglichen Fehler bei der Eingabe der Informationen entstehen könnte; Vor 2:24 Uhr am nächsten Tag, die genaue Ankunftszeit richtet sich nach der Bank",
    queren: "Auszahlung bestätigen",
    guanlian: "Bitte verknüpfen Sie Ihre Bankkarte vor der Auszahlung",
  },
  prsonal: {
    title: "Persönlich",
    name: "Vollständiger Name",
    shouji: "Telefonnummer",
    yhk: "Bankkarte",
    tuichu: "Ausloggen",
    tianxie: "Ausfüllen",
  },
  card: {
    title: "Bankkarteneinstellungen",
    zhanghuxinxi: "Kontoinformationen",
    tps: "Bitte stellen Sie sicher, dass Ihre Kontonummer korrekt ist. Wir sind nicht verantwortlich für Fehler.",
    yhmc: "Bankname",
    zhanghuming: "Nutzername",
    zhanghu: "Konto",
    shouji: "Telefonnummer",
    fenhang: "Name der Bankfiliale",
    baocun: "Speichern",
    jianyi: "Vorschlag",
    text: "Bitte kontaktieren Sie den Online-Kundenservice für die Methode zur Bindung der Bankkarte, falls diese geändert werden muss!",
    qyh: "Bitte wählen Sie ein ",
    qsryhm: "Bitte geben Sie den Banknutzernamen ein",
    qzh: "Bitte geben Sie Ihr Konto ein",
    qdh: "Bitte geben Sie die Telefonnummer ein",
    qfhmc: "Bitte geben Sie den Namen der Filiale ein",
    qsrifcs: "Bitte geben Sie IFSC ein",
    youxiang: "Email",
    plyx: "Bitte geben Sie eine E-Mail ein",
    jgbh: "Organisationsnummer",
    pljgbh: "Bitte geben Sie die Organisationsnummer ein",
    gjj: "CPF",
    plgjj: "Bitte geben Sie ein ",
    isbp: "ISBP",
    plisbp: "Bitte geben Sie ISBP ein",
    pixtype: "PIXType",
    plpixtype: "Bitte geben Sie PIXType ein",
    pix: "PIX",
    plpix: "Bitte geben Sie PIX ein",
    evp: "EVP",
    plevp: "Bitte geben Sie EVP ein",
  },
  team: {
    title: "Team",
    Directo: "Direkt",
    Secundario: "Sekundär",
    Terciario: "Tertiär",
    Comisión: "Heutiger Ausschuss",
    zongyongjin: "Gesamtkommission",
    shuzi: "Nummer",
    sudu: "Geschwindigkeit",
    jinricz: "Heute aufladen",
    tixian: "Gesamtauszahlung",
  },
  invitation: {
    title: "Freunde einladen",
    notitle: "Sie können über Ihr Handy Teilzeitarbeiten ausführen",
    notitles: "Laden Sie Freunde ein, gemeinsam mehr Geld zu verdienen",
    yqm: "Einladungscode",
    fuzhi: "Kopieren",
    xzyq: "Jetzt einladen",
  },
  message: {
    title: "Kundenservice",
    go: "Kontaktieren",
  },
  address: {
    title: "Adressverwaltung",
    xm: "Vollständiger Name",
    dianhua: "Telefon",
    qtx: "Bitte ausfüllen",
    dizhixq: "Informationen zur Lieferadresse",
    baocun: "Speichern",
  },
  invitationTask: {
    title: "Einladungsaufgabe",
    zongren: "Gesamtzahl",
    gengxin:
      "Aufgabe aktualisieren: Der überlastete Nutzer ist ein gültiger Nutzer",
    yq: "Einladung",
    jige: "Gültige Mitglieder",
    qianwang: "GEHEN",
    renwujiangli: "Aufgabenbelohnung ",
  },
  safe: {
    aqgl: "Sicherheitsmanagement",
    denglu: "Anmeldepasswort",
    zfmm: "Zahlungspasswort",
    bh: "Schützen",
  },
  password: {
    title: "Anmeldepasswort",
    titles: "Zahlungspasswort",
    shouji: "Telefonnummer",
    yanzheng: "Verifizierungscode",
    fasong: "Senden",
    xinmima: "Neues Passwort",
    pxinmima: "Neues Passwort eingeben",
  },
  record: {
    title: "Details",
    quanbu: "Gesamt",
    chongzhi: "Aufladen",
    tixian: "Auszahlung",
    dingdan: "Bestellung",
    tuandui: "Team",
    zongsu: "Gesamt",
    dangqian: "Aktuelle Daten",
    kaishisj: "Startzeit",
    jieshusj: "Endzeit",
  },
  login: {
    zhanghu: "Bitte geben Sie Ihr Konto ein",
    mima: "Bitte Passwort eingeben",
    denglu: "Anmelden",
    zhuce: "Jetzt registrieren",
    wjmm: "Ich habe mein Passwort vergessen",
  },
  regist: {
    touxiang: "Porträt",
    yhm: "Benutzername",
    mima: "Passwort",
    zhifu: "Zahlungspasswort",
    yq: "Einladung",
    pyhm: "Bitte geben Sie einen Benutzernamen ein",
    ptel: "Bitte geben Sie die Telefonnummer ein",
    shuru: "Bitte Passwort eingeben",
    shurupay: "Geben Sie 6 Ziffern ein",
    pyqm: "Einladungscode",
    zhuce: "Registrieren",
    yijing: "Ich habe bereits ein Konto？",
    plyqm: "Bitte geben Sie den Einladungscode ein",
  },
  tabbars: {
    shouye: "Startseite",
    chongzhi: "Aufladen",
    shouji: "Sammeln",
    dingdan: "Bestellung",
    wode: "Mein",
  },

  zhaohuimima: "Bitte wenden Sie sich an den Kundenservice zur Bearbeitung",
  yanzhengzhifu: "Zahlungspasswort verifizieren",
  huanying: "Willkommen bei",
  yinhang: "Bank",
  yinhangbh: "Banknummer",
  beizhu: "Bemerkung",
  zhifufs: "Zahlungsmethode",
  qxzzffs: "Bitte wählen Sie die Zahlungsmethode",
  usdt: {
    title: "USDT (TRC20) Aufladekanal",
    tips1: "Wählen Sie den Aufladebetrag",
    rates: "Wechselkurse",
    cashTips: "Sie haben einen Aufladebetrag von ausgewählt",
    usdtTips: "Sie müssen USDT (TRC20) zur Bezahlung verwenden",
    customAmount: "Sie können den Betrag direkt eingeben",
    importantTips: {
      one: "1. Bitte überweisen Sie den oben genannten Betrag für USDT",
      two: "2. Bitte schließen Sie die Überweisung innerhalb von 5 Minuten ab, sonst läuft die Zeit ab und es wird fehlschlagen.",
      three:
        "3. Wenn Sie Probleme haben, kontaktieren Sie bitte unsere Kundendienstmitarbeiter, um Ihnen bei der Lösung zu helfen.",
    },
  },
  selectChannel: {
    title: "Wählen Sie eine Zahlungsmethode",
    cashChannel: "Bargeldzahlungskanal",
    usdtChannel: "USDT-Zahlungskanal (TRC20)",
    rewards: "Zusätzlicher 5% Cash-Bonus",
  },
};
export default de;
