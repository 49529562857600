<template>
  <div class="invitation">
    <header>
      <van-nav-bar :title="$t('invitation.title')" :style="bgColor">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>
    <div class="main">
      <div class="invitationMain">
        <div class="invitation-title">
          <div class="title1">{{ $t("invitation.notitles") }}</div>
          <div class="inivte-phone">
            {{ $t("invitation.notitle") }}
          </div>
        </div>
        <!-- code -->
        <div class="invitation-code">
          <div class="code-title">{{ $t("invitation.yqm") }}</div>
          <div class="code">{{ currentUserId }}</div>
          <div class="copy" @click="ShowInvitation = true">
            {{ $t("invitation.fuzhi") }}
          </div>
        </div>
        <!-- 二维码 -->
        <div class="erwei-code">
          <!-- <img src="@/assets/image/erweima.png" alt="" /> -->
          <div class="codewarp">
          <div ref="qrcode" class="qrcode"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-link" @click="ShowInvitation = true">
      {{ $t("invitation.xzyq") }}
    </div>
    <tabbar />
    <!-- 弹出框 -->

    <van-dialog
      v-model:show="ShowInvitation"
      :title="$t('Pofile.fuzhi')"
      show-cancel-button
      :confirmButtonText="$t('alert.fuzhi')"
      :cancelButtonText="$t('alert.quxiao')"
      cancelButtonColor="#000000"
      confirmButtonColor="#007aff"
      @confirm="confirmShare"
    >
      <div class="ShowInvitationDiv">code={{ currentUserId }}</div>
    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import QRCode from "qrcodejs2";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Invitation",
  components: { Tabbar },
  data() {
    return {
      ShowInvitation: false,
      bgColor:'',
    };
  },
  methods: {
    confirmShare() {
      let transfer = document.createElement("input");
      document.body.appendChild(transfer);
      // let url =
      //   window.location.host + "/regist?invitation_id=" + this.currentUserId;
      let url = "code=" + this.currentUserId;
      transfer.value = url; // 这里表示想要复制的内容
      transfer.focus();
      transfer.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      transfer.blur();
      this.$toast(this.$t("fuzhiOK"));
      document.body.removeChild(transfer);
    },
    JumpGo() {
      this.$router.go(-1);
    },
  },
  computed: {
    currentUserId() {
      console.log(this.$store.state.currentUserId);
      return this.$store.state.currentUserId;
    },
  },
  created() {
         this.bgColor = localStorage.getItem("bgColor");
    this.$nextTick(() => {
      new QRCode(this.$refs.qrcode, {
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        text:
          window.location.host + "/regist?invitation_id=" + this.currentUserId,
      });
    });
  },
};
</script>
<style lang="less" scoped>
.invitation {
  background: url(../assets/image/bgy.png) no-repeat;
  background-size: cover;
  overflow-y: auto;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
.main {
  padding-bottom: 0.27rem;
}
.invitation-title {
  padding: 1.6rem 0.43rem 0;
  position: relative;
  height: 5.81rem;
  box-sizing: border-box;
}
.title1 {
  font-size: 0.64rem;
  color: #fee8b9;
  text-align: center;
  font-family: HFVoyager-Regular;
  line-height: 0.99rem;
}
.inivte-phone {
  font-size: 0.35rem;
  font-family: Rubik-Regular, Rubik;
  font-weight: 400;
  height: 0.85rem;
  line-height: 0.85rem;
  color: #b9193c;
  position: absolute;
  bottom: 0.96rem;
  left: 50%;
  text-align: center;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
  background-color: #ffd4ba;
}
.invitation-code {
  margin: 0 0.43rem;
  height: 1.49rem;
  line-height: 56px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.43rem;
  position: relative;
}
.code-title {
  height: 1.49rem;
  line-height: 1.49rem;
  flex: 2;
  font-size: 0.37rem;
  font-family: Rubik-Regular, Rubik;
  font-weight: 400;
  text-align: center;
  position: relative;
}
.code-title::after {
  height: 50%;
  width: 1px;
  background-color: #ededed;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.code {
  flex: 2;
  font-size: 15px;
  font-family: Rubik-Medium, Rubik;
  font-weight: 500;
  color: #000;
  padding-left: 0.43rem;
  box-sizing: border-box;
}
.copy {
  position: absolute;
  right: 0.43rem;
  width: 1.49rem;
  height: 0.64rem;
  line-height: 0.64rem;
  background: #e73329;
  border-radius: 16px;
  text-align: center;
  font-size: 0.32rem;
  font-family: Rubik-Medium, Rubik;
  font-weight: 500;
  color: #feead1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.erwei-code {
  margin: 0 0.43rem 0.99rem;
  height: 8.32rem;
  background: #fff;
  border-radius: 0.43rem;
  border: 1px solid #979797;
  position: relative;
  .qrcode {
    width: 4.67rem;
    height: 4.67rem;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // -webkit-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    // border: 1px dashed #979797;
  }
}
.copy-link {
  background: #fb0;
  width: 100%;
  font-size: 0.43rem;
  font-family: Rubik-Medium, Rubik;
  font-weight: 500;
  color: #fff;
  position: fixed;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 12;
  text-align: center;
  border-radius: 5px;
  height: 0.99rem;
  line-height: 0.99rem;
}
.ShowInvitationDiv {
  padding: 0.3rem 0.64rem;
  padding-top: 0.6rem;
  min-height: 40px;
  font-size: 15px;
  line-height: 1.4;
  color: #999;
  max-height: 400px;
  overflow-x: hidden;
  text-align: center;
  overflow-y: auto;
}
.codewarp{
      display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
</style>